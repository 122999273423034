import { PlayButton } from '@capturi/ui-components'
import {
  Box,
  Button,
  HStack,
  IconButton,
  Text,
  useToast,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import SeekBar from 'components/ConversationDetails/Audio/components/SeekBar'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { MdForward10, MdReplay10 } from 'react-icons/md'
import { useSlider } from 'react-use'

const formatCurrentTime = (currentTime: number) => {
  const minutes = Math.floor(currentTime / 60)
  const seconds = Math.floor(currentTime % 60)
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
}

const formatDuration = (duration: number) => {
  const minutes = Math.floor(duration / 60)
  const seconds = Math.floor(duration % 60)
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
}

export const AudioPlayer: FC<{ conversationUid: string }> = ({
  conversationUid,
}) => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const progressRef = useRef<HTMLDivElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [progress, setProgress] = useState(0)
  const [audioSrc, setAudioSrc] = useState('')
  const toast = useToast()
  const [playbackRate, setPlaybackRate] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const { isSliding, value: sliderValue } = useSlider(progressRef)

  const seekerValue = useMemo(() => {
    if (isSliding) {
      return sliderValue
    }

    return progress / (audioRef.current?.duration || 1) || 0
  }, [isSliding, sliderValue, progress])

  useEffect(() => {
    const newSrc =
      process.env.NODE_ENV === 'development'
        ? 'https://cdn.freesound.org/previews/717/717965_1648170-lq.mp3'
        : `https://api.capturi.ai/storage/archive/${conversationUid}/preview`
    setAudioSrc(newSrc)

    //Clean up when we change conversation
    return () => {
      if (audioRef.current) {
        audioRef.current.pause()
        audioRef.current.src = ''
        setIsPlaying(false)
      }
    }
  }, [conversationUid])

  const handlePlayPause = async () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause()
        setIsPlaying(false)
      } else {
        if (!audioRef.current.src || audioRef.current.src !== audioSrc) {
          audioRef.current.src = audioSrc
          audioRef.current.load()
        }

        try {
          setIsLoading(true)
          await audioRef.current.play()
          setIsPlaying(true)
        } catch {
          toast({
            title: t`Error playing audio file`,
            description: t`The audio file could not be played. Please try again later.`,
            status: 'error',
          })
        } finally {
          setIsLoading(false)
        }
      }
    }
  }

  const handleProgressClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (audioRef.current && progressRef.current) {
      const rect = progressRef.current.getBoundingClientRect()
      const x = e.clientX - rect.left
      const percentage = (x / rect.width) * 100
      const time = (percentage / 100) * audioRef.current.duration
      audioRef.current.currentTime = time
      setProgress(time)
    }
  }

  const toggleSpeed = useCallback((): void => {
    setPlaybackRate((currentRate) => {
      const newRate = currentRate < 2 ? currentRate + 0.25 : 1
      if (audioRef.current) {
        audioRef.current.playbackRate = newRate
      }
      return newRate
    })
  }, [])

  return (
    <Box width="100%">
      <audio
        ref={audioRef}
        onTimeUpdate={(e) => setProgress(e.currentTarget.currentTime)}
        onLoadedMetadata={() => formatDuration(progress)}
        onEnded={() => setIsPlaying(false)}
        hidden
      />

      <Box
        border="1px solid"
        borderColor="gray.200"
        p={4}
        bg="accents.lightBackground.default"
        borderTopRadius="md"
      >
        <Box px={4} py={2}>
          <Box
            ref={progressRef}
            h="24px"
            bg="gray.300"
            cursor="pointer"
            onClick={handleProgressClick}
            position="relative"
          >
            <SeekBar value={seekerValue * 100} />
          </Box>
        </Box>
      </Box>

      <Box
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor="gray.200"
        py={2}
        px={4}
      >
        <HStack spacing={4} align="start">
          <HStack spacing={4}>
            <PlayButton
              aria-label={isPlaying ? 'Pause' : 'Play'}
              onClick={handlePlayPause}
              size="sm"
              colorScheme="primary"
              isRound
              isLoading={isLoading}
              useSpeakerIcon={false}
              isPlaying={isPlaying}
            />

            <Text fontSize="sm" color="gray.600">
              {formatCurrentTime(progress)} /{' '}
              {formatDuration(audioRef?.current?.duration || 0)}
            </Text>
          </HStack>
          <IconButton
            aria-label="Skip backward"
            icon={<MdReplay10 />}
            size="sm"
            variant="ghost"
            onClick={() => {
              if (audioRef.current) {
                audioRef.current.currentTime -= 10
              }
            }}
          />

          <IconButton
            aria-label="Skip forward"
            icon={<MdForward10 />}
            size="sm"
            variant="ghost"
            onClick={() => {
              if (audioRef.current) {
                audioRef.current.currentTime += 10
              }
            }}
          />

          <Button
            aria-label="Change playback speed"
            onClick={toggleSpeed}
            size="sm"
            variant="ghost"
            _hover={{ bg: 'transparent' }}
            _active={{ bg: 'transparent' }}
          >
            {playbackRate}x
          </Button>
        </HStack>
      </Box>
    </Box>
  )
}
