import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FC } from 'react'

type ConfirmCancelDialogProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmCancelDialog: FC<ConfirmCancelDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Trans>Are you sure you want to cancel?</Trans>
        </ModalHeader>
        <ModalBody>
          <Trans>Any unsaved changes will be lost.</Trans>
        </ModalBody>
        <ModalFooter gap={2}>
          <Button onClick={onClose}>
            <Trans>Close</Trans>
          </Button>
          <Button colorScheme="red" onClick={onConfirm}>
            <Trans>Confirm</Trans>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
