import { useFilterPeriodContext } from '@capturi/filters'
import { useOrganization } from '@capturi/stores'
import qs from 'query-string'

import { useSelectedSharedContext } from '../contexts/ContextualDashboardContext'
import { useDashboardPublicContext } from '../contexts/DashboardPublicContext'
import { TimeResolution, Widget, WidgetModel } from '../types'

const mappedWidgetRoutes = {
  [Widget.Score]: '/insights/scoring',
  [Widget.HitRate]: '/analytics/hitrate',
  [Widget.ConversationsDuration]: '/insights/activity',
} as const

export function sanitizeObject<T extends object>(
  widgetFilter: T,
): Partial<T> | undefined {
  if (!widgetFilter) return

  return Object.entries(widgetFilter).reduce<Partial<T>>(
    (memo, [key, value]) => {
      if (value == null || value === '') {
        return memo
      }

      if (Array.isArray(value)) {
        if (value.length === 0) return memo
        if (typeof value[0] === 'object') {
          const newValue = value.map((o) => sanitizeObject(o))
          /* TODO: Fix type - keys can't be used to partial indexing of memo */
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          memo[key] = newValue
          return memo
        }
      }

      if (typeof value === 'object' && !Array.isArray(value)) {
        const sanitizedValue = sanitizeObject(value)

        if (!sanitizedValue || Object.keys(sanitizedValue).length === 0)
          return memo
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        memo[key] = sanitizedValue
        return memo
      }

      memo[key as keyof T] = value
      return memo
    },
    {},
  )
}

type UseWidgetFilterLinkProps = {
  widget: WidgetModel & {
    resolution?: TimeResolution
  }
  additionalUserFilter?: string[]
  additionalTeamFilter?: string[]
}

const useWidgetFilterLink = ({
  widget,
  additionalUserFilter,
  additionalTeamFilter,
}: UseWidgetFilterLinkProps): string | null => {
  const { periodDef } = useFilterPeriodContext()
  const sharedContext = useSelectedSharedContext()
  const isPublicDashboard = useDashboardPublicContext()
  const { uid: orgUid } = useOrganization(
    // Block this request in case it's a public dashboard
    isPublicDashboard,
  )

  if (isPublicDashboard) return null

  const phoneTeamFilter = Array.from(
    new Set([
      ...(widget.filters?.teamUids ?? []),
      ...(additionalTeamFilter ?? []),
      ...(sharedContext?.type === 'Team' ? (sharedContext.value ?? []) : []),
    ]),
  )
  const phoneUserFilter = Array.from(
    new Set([
      ...(widget.filters?.userUids ?? []),
      ...(additionalUserFilter ?? []),
      ...(sharedContext?.type === 'User' ? (sharedContext.value ?? []) : []),
    ]),
  )
  const widgetWithSharedContextFilter: WidgetModel['filters'] = {
    ...widget.filters,
    savedFilterGroupUid: undefined,
    teamUids: phoneTeamFilter,
    userUids: phoneUserFilter,
  }

  const textTeamFilter = [
    ...(widget.textFilters?.teamFilters ?? []),
    ...(additionalTeamFilter
      ? [{ values: additionalTeamFilter, inverted: false }]
      : []),
    ...(sharedContext?.type === 'Team' && sharedContext.value
      ? [{ values: sharedContext.value, inverted: false }]
      : []),
  ]

  const textUserFilter = [
    ...(widget.textFilters?.userFilters ?? []),
    ...(additionalUserFilter
      ? [{ values: additionalUserFilter, inverted: false }]
      : []),
    ...(sharedContext?.type === 'User' && sharedContext.value
      ? [{ values: sharedContext.value, inverted: false }]
      : []),
  ]
  const widgetWithSharedContextTextFilter = {
    ...widget.textFilters,
    savedCaseFilterUid: undefined,
    teamFilters: textTeamFilter,
    userFilters: textUserFilter,
  }

  const widgetFilter = sanitizeObject(widgetWithSharedContextFilter)
  const widgetTextFilter = sanitizeObject(widgetWithSharedContextTextFilter)
  const shareFilterSearchParams = sanitizeObject(widget.shareFilter || {})
  const textShareFilterSearchParams = sanitizeObject(
    widget.textShareFilter || {},
  )

  if (!(widget.type in mappedWidgetRoutes)) return null

  const url =
    location.origin +
    mappedWidgetRoutes[widget.type as keyof typeof mappedWidgetRoutes]
  const queryItems = {
    score: widget.scoreUid,
    resolution: widget.resolution,
    period: periodDef.name,
  }
  // Determine whether to use the savedFilterGroupUid or explicit filters. I tested this extensively,
  // on a lot of widgets. I think it's safe? But this is filters, so I don't know.
  const shouldUseSavedFilter = Boolean(widget.filters?.savedFilterGroupUid)

  const widgetLink = qs.stringifyUrl(
    widget.filters
      ? {
          url,
          query: {
            ...queryItems,
            org: orgUid,
            filter:
              Object.entries(widgetFilter ?? {}).length && !shouldUseSavedFilter
                ? JSON.stringify(widgetFilter)
                : undefined,
            subFilter: Object.entries(shareFilterSearchParams ?? {})
              ? JSON.stringify(shareFilterSearchParams)
              : undefined,
            savedFilterUid: shouldUseSavedFilter
              ? widget.filters.savedFilterGroupUid
              : undefined,
            savedSubFilterUid:
              widget.shareFilter?.savedFilterGroupUid || undefined,
            channel: 'phone',
          },
        }
      : {
          url,
          query: {
            ...queryItems,
            org: orgUid,
            filter: Object.entries(widgetTextFilter ?? {}).length
              ? JSON.stringify(widgetTextFilter)
              : undefined,
            subFilter: Object.entries(textShareFilterSearchParams ?? {})
              ? JSON.stringify(textShareFilterSearchParams)
              : undefined,
            savedFilterUid:
              widget.textFilters !== null
                ? widget.textFilters.savedCaseFilterUid || undefined
                : null,
            savedSubFilterUid:
              widget.textShareFilter?.savedCaseFilterUid || undefined,
            channel: 'email',
          },
        },
  )
  return widgetLink
}

export default useWidgetFilterLink
