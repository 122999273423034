import {
  Button,
  Flex,
  HStack,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Tooltip,
  VStack,
  chakra,
  useDisclosure,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { FC, useRef, useState } from 'react'
import isEmailValid from 'utils/isEmailValid'

export const EmailChipsInput: FC<{
  recipients: string[]
  setRecipients: (recipients: string[]) => void
}> = ({ recipients, setRecipients }) => {
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setInputValue(event.target.value.replace(' ', ''))
  }

  const handleSubmit = (): void => {
    const trimmedValue = inputValue.trim()
    if (trimmedValue) {
      addChips(trimmedValue)
      setRecipients([...recipients, ...[trimmedValue]])
      setInputValue('')
    }
  }

  const handleInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSubmit()
    } else if (
      event.key === 'Backspace' &&
      inputValue === '' &&
      recipients.length > 0
    ) {
      event.preventDefault()
      removeChip(recipients.length - 1)
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>): void => {
    event.preventDefault()
    const pasteData = event.clipboardData.getData('Text')
    addChips(pasteData)
  }

  const addChips = (chips: string): void => {
    const newChips = chips
      .split(',')
      .map((chip) => chip.trim())
      .filter((chip) => !recipients.includes(chip))
    if (newChips.length > 0) {
      setRecipients([...recipients, ...newChips])
    }
  }

  const removeChip = (index: number): void => {
    setRecipients(recipients.toSpliced(index, 1))
  }

  const handleClearAll = (): void => {
    setInputValue('')
    setRecipients([])
    inputRef.current?.focus()
  }

  return (
    <>
      <InputGroup id="inputgroup" maxW="100%">
        <VStack w="100%">
          <HStack
            flexWrap="wrap"
            maxH="20rem"
            overflowY="auto"
            py={1.5}
            px={2}
            gap={1.5}
            borderWidth={1}
            onClick={() => inputRef.current?.focus()}
            padding={2}
            minH="75px"
            border="solid 1px"
            borderColor="border.light"
            borderRadius="md"
            tabIndex={0}
            alignItems="start"
            boxShadow="inputOutline"
            w="100%"
            _focusWithin={{
              borderColor: 'primary.500',
              shadow: 'extended',
            }}
            sx={{
              '&::-webkit-scrollbar': {
                width: '0px',
              },
              '&': {
                scrollbarWidth: 'none',
              },
            }}
          >
            <Flex flexWrap="wrap" overflowY="scroll" flex="80%">
              {recipients.map((chip, index) => (
                <Tooltip
                  key={index}
                  isDisabled={isEmailValid(chip)}
                  label={t`Invalid email address`}
                  hasArrow
                  placement="top"
                >
                  <Tag
                    size="xs"
                    borderRadius="full"
                    border={!isEmailValid(chip) ? '1px solid' : 'initial'}
                    borderColor={!isEmailValid(chip) ? 'danger' : 'initial'}
                    px={2}
                    mx={1}
                    mb={2}
                    variant="subtle"
                    maxW="100%"
                    h={5}
                  >
                    <TagLabel
                      fontSize="xs"
                      fontWeight="normal"
                      color={!isEmailValid(chip) ? 'danger' : 'initial'}
                    >
                      {chip}
                    </TagLabel>
                    <TagCloseButton
                      ml={1}
                      h={4}
                      onClick={() => removeChip(index)}
                    />
                  </Tag>
                </Tooltip>
              ))}
              <chakra.input
                ref={inputRef}
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                onPaste={handlePaste}
                onBlur={handleSubmit}
                placeholder={t`Add email...`}
                fontSize="sm"
                border="none"
                outline="none"
                h={5}
                mx={1}
              />
            </Flex>
          </HStack>
          <Button
            alignSelf="flex-end"
            variant="ghost"
            _hover={{ backgroundColor: 'none' }}
            _focus={{ backgroundColor: 'none' }}
            aria-label={t`Clear all`}
            onClick={onOpen}
            isDisabled={recipients.length === 0}
          >
            <Trans>Clear all</Trans>
          </Button>
        </VStack>
      </InputGroup>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              <Trans>Are you sure you want to remove all recipients?</Trans>
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              <Trans>Cancel</Trans>
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                handleClearAll()
                onClose()
              }}
            >
              <Trans>Continue</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
