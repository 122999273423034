import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FC } from 'react'

import { QuestionnaireAnswer } from '@capturi/api-conversations'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { QaQuestionnaire } from '../../types'
import { QuestionnairePreview } from './QuestionnairePreview'

type QuestionnairePreviewModalProps = BaseModalComponentProps<
  QuestionnaireAnswer['answers']
> & {
  questionnaire: QaQuestionnaire
  initialAnswers?: QuestionnaireAnswer['answers']
  showSubmitButton?: boolean
  isDisabled?: boolean
}

export const QuestionnairePreviewModal: FC<QuestionnairePreviewModalProps> = ({
  onSubmit,
  onClose,
  questionnaire,
  initialAnswers,
  showSubmitButton = false,
  isDisabled = false,
}) => {
  return (
    <Modal isOpen={true} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Trans>Preview</Trans>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <QuestionnairePreview
            questionnaire={questionnaire}
            initialAnswers={initialAnswers}
            showSubmitButton={showSubmitButton}
            isDisabled={isDisabled}
            onSubmit={onSubmit}
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="primary" onClick={onClose}>
            <Trans>Close</Trans>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
