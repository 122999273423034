import analytics from '@capturi/analytics'
import { useCurrentUser } from '@capturi/core'
import { User, useOrganization, useUsers } from '@capturi/stores'
import {
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useState } from 'react'
import {
  Subscription,
  SubscriptionFormModel,
} from '../../shared/Subscriptions/types'
import {
  useCreateSubscription,
  useUpdateSubscription,
} from '../../shared/Subscriptions/useSubscriptions'
import Recurrence from './components/Recurrence/Recurrence'
import SubscriptionDescription from './components/SubscriptionDescription'
import SubscriptionMailField from './components/SubscriptionEmails/SubscriptionMailField'
import SubscriptionFilter from './components/SubscriptionFilter'
import SubscriptionFooter from './components/SubscriptionFooter'
import SubscriptionName from './components/SubscriptionName'
import SubscriptionOwnershipTransfer from './components/SubscriptionOwnershipTransfer'

type Props = {
  isOpen: boolean
  onClose: () => void
  canTransferOwnership?: boolean
  subscription?: Subscription
  users?: User[]
  subscriptionUid?: string
}

const SubscriptionModal: React.FC<Props> = ({
  onClose,
  isOpen,
  subscription,
  users,
}) => {
  const [subscriptionState, setSubscriptionState] =
    useState<SubscriptionFormModel>({
      uid: subscription?.uid ?? undefined,
      name: subscription?.name ?? '',
      description: subscription?.description ?? '',
      filter: subscription?.filter ?? {},
      period: subscription?.period ?? { type: 'Week', week: 'Previous' },
      recipients: subscription?.recipients ?? [],
      schedule: subscription?.schedule ?? { type: 'Weekly', days: ['Monday'] },
      createdByUserUid: subscription?.createdByUserUid ?? undefined,
    })

  const { isOwner } = useCurrentUser()
  const { timeZone, uiLanguage } = useOrganization()
  const { mutate: createSubsciption, isPending: isCreatingSubscription } =
    useCreateSubscription()
  const { mutate: updateSubscription, isPending: isUpdatingSubscription } =
    useUpdateSubscription()
  const toast = useToast()
  const { getUserByUid } = useUsers()

  const onCreateSubscription = (payload: SubscriptionFormModel) => {
    if (subscription?.uid) {
      updateSubscription(
        {
          ...subscriptionState,
          timeZone: timeZone,
          language: uiLanguage,
        },
        {
          onSuccess: () => {
            toast({
              status: 'success',
              title: t`Successfully updated subscription`,
            })
            analytics.event('subscription_updated', {
              name: subscriptionState.name,
              description: subscriptionState.description,
              filter: subscriptionState.filter,
              period: subscriptionState.period,
              schedule: subscriptionState.schedule,
              recipients: subscriptionState.recipients,
            })
            onClose()
          },
          onError: (error) => {
            toast({
              status: 'error',
              title: t`Failed to update subscription. Please try again`,
              description: error.message,
            })
          },
        },
      )
    } else {
      createSubsciption(
        {
          ...payload,
          timeZone: timeZone,
          language: uiLanguage,
        },
        {
          onSuccess: () => {
            toast({
              status: 'success',
              title: t`Successfully created subscription`,
            })
            analytics.event('subscription_created', {
              name: subscriptionState.name,
              description: subscriptionState.description,
              filter: subscriptionState.filter,
              period: subscriptionState.period,
              schedule: subscriptionState.schedule,
              recipients: subscriptionState.recipients,
            })
            onClose()
          },
          onError: (error) => {
            toast({
              status: 'error',
              title: t`Failed to create subscription. Please try again`,
              description: error.message,
            })
          },
        },
      )
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {subscription?.name ?? <Trans>New subscription</Trans>}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          borderBottom="1px solid"
          borderTop="1px solid"
          borderColor="gray.200"
        >
          <Grid
            minH="512px"
            templateColumns={{
              base: '1fr 1fr',
            }}
            gap={6}
          >
            <Stack
              borderRight={{
                base: 'none',
                md: '1px solid var(--chakra-colors-gray-200)',
              }}
              pr={6}
            >
              <Flex>
                <SubscriptionName
                  name={subscriptionState.name}
                  setName={(name) =>
                    setSubscriptionState((s) => ({ ...s, name }))
                  }
                />
              </Flex>
              <Flex>
                <SubscriptionDescription
                  description={subscriptionState.description}
                  setDescription={(description) =>
                    setSubscriptionState((s) => ({ ...s, description }))
                  }
                />
              </Flex>
              <Flex>
                <SubscriptionFilter
                  filter={subscriptionState.filter}
                  setFilter={(filter) =>
                    setSubscriptionState((s) => ({ ...s, filter }))
                  }
                />
              </Flex>
              <Flex w="100%" flexDir="column">
                <Recurrence
                  schedule={subscriptionState.schedule}
                  setSchedule={(schedule) =>
                    setSubscriptionState((s) => {
                      if (
                        schedule.type === 'Monthly' &&
                        schedule.dayOfMonth === 'Last'
                      ) {
                        return {
                          ...s,
                          schedule,
                          period: { type: 'Month', month: 'Current' },
                        }
                      }
                      if (
                        schedule.type === 'Monthly' &&
                        schedule.dayOfMonth === 'First'
                      ) {
                        return {
                          ...s,
                          schedule,
                          period: { type: 'Month', month: 'Previous' },
                        }
                      }
                      return { ...s, schedule }
                    })
                  }
                  period={subscriptionState.period}
                  setPeriod={(period) =>
                    setSubscriptionState((s) => ({ ...s, period }))
                  }
                />
              </Flex>
            </Stack>
            <Stack>
              <SubscriptionMailField
                recipients={subscriptionState.recipients}
                setRecipients={(recipients) =>
                  setSubscriptionState((s) => ({
                    ...s,
                    recipients,
                  }))
                }
              />
              {users && isOwner && (
                <SubscriptionOwnershipTransfer
                  createdByUserUid={subscription?.createdByUserUid}
                  setNewOwner={(createdByUserUid) =>
                    setSubscriptionState((s) => ({
                      ...s,
                      createdByUserUid: createdByUserUid,
                    }))
                  }
                  users={users}
                />
              )}
              {subscription?.createdByUserUid !==
                subscriptionState.createdByUserUid && (
                <Text color="warning">
                  <Trans>
                    This will transfer ownership of the subscription to the
                    selected owner.
                  </Trans>
                </Text>
              )}
            </Stack>
          </Grid>
        </ModalBody>
        <SubscriptionFooter
          isDisabled={
            !subscriptionState.name || !subscriptionState.recipients.length
          }
          isScheduleDaysEmpty={
            subscriptionState.schedule.type === 'Weekly' &&
            subscriptionState.schedule.days.length === 0
          }
          isLoading={isCreatingSubscription || isUpdatingSubscription}
          createdByUsername={getUserByUid(subscription?.createdByUserUid).name}
          updatedByUsername={getUserByUid(subscription?.updatedByUserUid).name}
          createdDate={subscription?.created}
          updatedDate={subscription?.updated}
          subscriptionUid={subscriptionState.uid}
          onCreateSubscription={() =>
            onCreateSubscription({
              ...subscriptionState,
              createdByUserUid: subscriptionState.createdByUserUid,
            })
          }
          onClose={onClose}
        />
      </ModalContent>
    </Modal>
  )
}

export default SubscriptionModal
