import { PageHeading } from '@capturi/ui-components'
import { Card, Flex, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FC } from 'react'

export const EmptyFile: FC = () => {
  return (
    <Card p={4} mt={10}>
      <Flex flexDir="column" align="center" justify="center" h="100%">
        <PageHeading>
          <Trans>File doesn't exist</Trans>
        </PageHeading>
        <Text color="gray.600" textAlign="center" mt={3}>
          <Trans>There's no file available on this conversation.</Trans>
        </Text>
      </Flex>
    </Card>
  )
}
