import React, { useCallback, useMemo, useState } from 'react'

import { Box, Divider, Spinner } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { FilterSelect } from '../../components/FilterSelect/components/FilterSelect'
import useTeamsFilterOptions from '../../data/useTeamsFilterOptions'
import { useSegmentStatesContext } from '../../state/segment-state'
import { defaultSWRConfig } from '../constants'

type Team = {
  uid: string
  name: string
}

type Props = {
  value: string[]
  initialFocusRef?: React.RefObject<HTMLInputElement>
  setValue: (values: string[]) => void
  onClose: () => void
}

export function TeamSelect({
  value,
  initialFocusRef,
  setValue,
  onClose,
}: Props): React.ReactElement {
  const { teams, isLoading } = useTeamsFilterOptions(defaultSWRConfig)
  const [searchQuery, setSearchQuery] = useState('')
  const { states } = useSegmentStatesContext()
  const channel = states.length > 0 ? states[0].channel : 'phone'

  const filteredTeams = useMemo(() => {
    if (!searchQuery) return teams
    return teams.filter((team) =>
      team.name.toLowerCase().includes(searchQuery.toLowerCase()),
    )
  }, [teams, searchQuery])

  const teamsMap = useMemo(
    () => new Map<string, Team>(teams.map((team) => [team.uid, team])),
    [teams],
  )

  const selectedTeamsSet = useMemo(() => new Set(value), [value])

  const handleSelect = useCallback(
    (teamId: string) => {
      const index = value.findIndex((teamUid) => teamUid === teamId)
      if (index !== -1) {
        setValue(value.toSpliced(index, 1))
      } else {
        setValue([...value, teamId])
      }
    },
    [value, setValue],
  )

  const handleSelectAll = useCallback(() => {
    const newSelections = filteredTeams.map((team) => team.uid)
    setValue([...new Set([...value, ...newSelections])])
    setSearchQuery('')
  }, [value, filteredTeams, setValue])

  const renderTeamOption = useCallback(
    (team: Team, isSelected: boolean) => (
      <FilterSelect.OptionItem
        key={team.uid}
        isSelected={isSelected}
        onClick={() => handleSelect(team.uid)}
      >
        <Box>{team.name}</Box>
      </FilterSelect.OptionItem>
    ),
    [handleSelect],
  )
  if (isLoading) {
    return <Spinner />
  }

  return (
    <Box maxH="400px" display="flex" flexDirection="column">
      <Box px={2}>
        <FilterSelect.Search
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          ref={initialFocusRef}
        />
      </Box>
      <Divider />
      <Box flex={1} maxH="350px" overflowY="auto">
        <FilterSelect.FilterSelectOptions
          selected={value.length}
          total={filteredTeams.length}
          selectedItems={value.map((teamUid) =>
            renderTeamOption(
              teamsMap.get(teamUid) ?? {
                uid: teamUid,
                name: t`Unknown team`,
              },
              true,
            ),
          )}
          unselectedItems={filteredTeams.map((team) =>
            renderTeamOption(team, selectedTeamsSet.has(team.uid)),
          )}
        />
      </Box>
      <Box flexShrink={0}>
        {channel === 'phone' && (
          <FilterSelect.Footer
            selectedCount={value.length}
            onSelectAll={handleSelectAll}
            onReset={() => setValue([])}
            onApply={onClose}
          />
        )}
      </Box>
    </Box>
  )
}
