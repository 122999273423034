import {
  ConversationResponseModel,
  QuestionnaireAnswer,
} from '@capturi/api-conversations'
import { useCurrentUser } from '@capturi/core'
import { useMutation, useQueryClient } from '@tanstack/react-query'

type UseAnswerMutationProps = {
  conversationUid: string
}

type QuestionAnswer =
  | { type: 'freeText'; answer: string }
  | { type: 'yesNo'; answer: 'Yes' | 'No' }
  | { type: 'range'; answer: number }
  | { type: 'notRelevant' }

const trimAnswer = (answer: QuestionAnswer): QuestionAnswer =>
  answer.type === 'freeText'
    ? { ...answer, answer: answer.answer.trim() }
    : answer

const trimAnswers = (answers: Record<string, QuestionAnswer>) =>
  Object.fromEntries(
    Object.entries(answers).map(([k, a]) => [k, trimAnswer(a)]),
  )

export type QuestionnaireAnswersInput = Record<string, QuestionAnswer>

// Helper to update conversation cache
const useConversationCache = (conversationUid: string) => {
  const queryClient = useQueryClient()

  return (
    updater: (
      conversation: ConversationResponseModel,
    ) => ConversationResponseModel,
  ) => {
    queryClient.setQueryData<ConversationResponseModel>(
      ['conversations', conversationUid],
      (oldData) => {
        if (!oldData) return oldData
        return updater(oldData)
      },
    )
  }
}

// Create new answer
export const useCreateAnswer = ({
  conversationUid,
}: UseAnswerMutationProps) => {
  const updateConversationCache = useConversationCache(conversationUid)
  const currentUser = useCurrentUser()

  return useMutation<
    QuestionnaireAnswer,
    Error,
    { questionnaireUid: string; answers: QuestionnaireAnswersInput }
  >({
    mutationFn: ({ questionnaireUid, answers }) => {
      // Fake implementation - in real version this would be an API call
      const currentDate = new Date()

      return Promise.resolve({
        uid: crypto.randomUUID(),
        questionnaireUid,
        created: currentDate,
        createdByUserUid: currentUser.id,
        updated: currentDate,
        updatedByUserUid: currentUser.id,
        answers: trimAnswers(answers),
      })
    },
    onSuccess: (createdAnswer) => {
      updateConversationCache((conversation) => ({
        ...conversation,
        questionnaireAnswers: [
          ...(conversation.questionnaireAnswers || []),
          createdAnswer,
        ],
      }))
    },
  })
}

// Update existing answer
export const useUpdateAnswer = ({
  conversationUid,
}: UseAnswerMutationProps) => {
  const updateConversationCache = useConversationCache(conversationUid)
  const queryClient = useQueryClient()
  const currentUser = useCurrentUser()

  return useMutation<
    QuestionnaireAnswer,
    Error,
    {
      questionnaireUid: string
      answerUid: string
      updates: QuestionnaireAnswersInput
    }
  >({
    mutationFn: ({ questionnaireUid, answerUid, updates }) => {
      // Fake implementation - in real version this would be an API call
      // Find the existing answer in the cache to update it
      const conversation = queryClient.getQueryData<ConversationResponseModel>([
        'conversations',
        conversationUid,
      ])

      const existingAnswer = conversation?.questionnaireAnswers?.find(
        (answer) => answer.uid === answerUid,
      )

      if (!existingAnswer) {
        throw new Error(`Answer with ID ${answerUid} not found`)
      }

      // Merge existing answers with updates
      const mergedAnswers = {
        ...existingAnswer.answers,
        ...updates,
      }

      return Promise.resolve({
        ...existingAnswer,
        questionnaireUid,
        updated: new Date(),
        updatedByUserUid: currentUser.id,
        answers: trimAnswers(mergedAnswers),
      })
    },
    onSuccess: (updatedAnswer) => {
      updateConversationCache((conversation) => ({
        ...conversation,
        questionnaireAnswers:
          conversation.questionnaireAnswers?.map((answer) =>
            answer.uid === updatedAnswer.uid ? updatedAnswer : answer,
          ) || [],
      }))
    },
  })
}

// Delete answer
export const useDeleteAnswer = ({
  conversationUid,
}: UseAnswerMutationProps) => {
  const updateConversationCache = useConversationCache(conversationUid)

  return useMutation<
    string,
    Error,
    { questionnaireUid: string; answerUid: string }
  >({
    mutationFn: ({ answerUid }) => {
      // Fake implementation - in real version this would be an API call
      return Promise.resolve(answerUid)
    },
    onSuccess: (deletedAnswerUid) => {
      updateConversationCache((conversation) => ({
        ...conversation,
        questionnaireAnswers:
          conversation.questionnaireAnswers?.filter(
            (answer) => answer.uid !== deletedAnswerUid,
          ) || [],
      }))
    },
  })
}
