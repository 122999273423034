import { Box, Divider, Spinner } from '@chakra-ui/react'
import { FilterSelect } from 'pages/Archive/FilterSelect/components/FilterSelect'
import { Footer } from 'pages/Archive/FilterSelect/components/FilterSelectFooter'
import { useFilterData } from 'pages/Archive/api/useArchivedConversations'
import { FC, useCallback, useMemo, useState } from 'react'

type Props = {
  values?: string[]
  onSubmit: (values: string[]) => void
  onClose: () => void
}

export const UserEmailSelect: FC<Props> = ({
  values = [],
  onSubmit,
  onClose,
}) => {
  const { data } = useFilterData('userEmails')
  const [searchQuery, setSearchQuery] = useState('')

  const filteredOptions = useMemo(() => {
    if (!data?.values) return []
    if (!searchQuery) return data.values

    return data.values.filter((email) =>
      email.toLowerCase().includes(searchQuery.toLowerCase()),
    )
  }, [data?.values, searchQuery])

  const userEmailSet = useMemo(() => new Set(values), [values])

  const handleSelect = useCallback(
    (userEmail: string) => {
      if (userEmailSet.has(userEmail)) {
        onSubmit(values.filter((s) => s !== userEmail))
      } else {
        onSubmit([...values, userEmail])
      }
    },
    [values, onSubmit, userEmailSet],
  )

  const handleSelectAll = useCallback(() => {
    onSubmit([...new Set([...values, ...filteredOptions])])
    setSearchQuery('')
  }, [values, filteredOptions, onSubmit])

  const handleReset = useCallback(() => {
    onSubmit([])
  }, [onSubmit])

  const renderOption = useCallback(
    (email: string) => (
      <FilterSelect.OptionItem
        key={email}
        isSelected={userEmailSet.has(email)}
        onClick={() => handleSelect(email)}
      >
        {email}
      </FilterSelect.OptionItem>
    ),
    [userEmailSet, handleSelect],
  )

  if (!data) {
    return <Spinner />
  }

  return (
    <Box maxH="550px" display="flex" flexDirection="column">
      <Box px={2}>
        <FilterSelect.Search
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>
      <Divider />
      <Box flex={1} maxH="450px" overflowY="auto">
        <FilterSelect.FilterSelectOptions
          selected={values.length}
          total={filteredOptions.length}
          selectedItems={values.map(renderOption)}
          unselectedItems={filteredOptions.map(renderOption)}
        />
      </Box>
      <Box flexShrink={0}>
        <Footer
          selectedCount={values.length}
          onSelectAll={handleSelectAll}
          onReset={handleReset}
          onApply={onClose}
        />
      </Box>
    </Box>
  )
}
