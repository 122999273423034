import { QuestionnaireAnswer } from '@capturi/api-conversations'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FC } from 'react'

import { LazyQuestionnairePreview } from './LazyQuestionnairePreview'

type LazyQuestionnairePreviewModalProps = BaseModalComponentProps<
  QuestionnaireAnswer['answers']
> & {
  questionnaireUid: string
  initialAnswers?: QuestionnaireAnswer['answers']
  showSubmitButton?: boolean
  isDisabled?: boolean
}

export const LazyQuestionnairePreviewModal: FC<
  LazyQuestionnairePreviewModalProps
> = ({
  onSubmit,
  onClose,
  questionnaireUid,
  initialAnswers,
  showSubmitButton = false,
  isDisabled = false,
}) => {
  return (
    <Modal isOpen={true} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Trans>Preview</Trans>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <LazyQuestionnairePreview
            questionnaireUid={questionnaireUid}
            initialAnswers={initialAnswers}
            showSubmitButton={showSubmitButton}
            isDisabled={isDisabled}
            onSubmit={onSubmit}
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="primary" onClick={onClose}>
            <Trans>Close</Trans>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
