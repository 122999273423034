import { useConfirmCallback } from '@capturi/use-confirm'
import { t } from '@lingui/macro'
import { useStore } from '@tanstack/react-store'
import { FC, useCallback, useEffect } from 'react'
import { useBlocker } from '../../../router/useBlocker'
import {
  closeQuestionnairePreview,
  questionnaireStore,
} from './questionnaireStore'

export const QuestionnaireBlocker: FC = () => {
  const { isInlinePreviewOpen } = useStore(questionnaireStore)
  const showConfirm = useConfirmCallback()

  const blocker = useCallback(
    (tx: { retry: () => void; location: { pathname: string } }) => {
      showConfirm({
        options: {
          title: t`Discard questionnaire?`,
          description: t`Your data will be lost if you navigate away from this questionnaire without submitting`,
          confirmText: t`Discard`,
        },
        onConfirm: () => {
          closeQuestionnairePreview()
          tx.retry()
        },
      })
    },
    [showConfirm],
  )

  const unblock = useBlocker(blocker, isInlinePreviewOpen)

  // Cleanup unblock on unmount
  useEffect(() => {
    return () => {
      unblock()
    }
  }, [unblock])

  return null
}
