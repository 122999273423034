import { QuestionAnswer } from '@capturi/api-conversations'
import { useCallback, useMemo, useState } from 'react'
import isEqual from 'react-fast-compare'

import { Guid } from '../../types'

export const useQuestionnaireAnswers = ({
  initialAnswers,
}: {
  initialAnswers?: Record<Guid, QuestionAnswer>
}) => {
  const [answers, setAnswers] = useState<Record<Guid, QuestionAnswer>>(
    initialAnswers || {},
  )

  const setAnswerFor = useCallback(
    (questionUid: string, answer: QuestionAnswer) => {
      setAnswers((prev) => ({
        ...prev,
        [questionUid]: answer,
      }))
    },
    [],
  )

  const getAnswerFor = useCallback(
    (questionUid: string): QuestionAnswer | undefined => {
      return answers[questionUid]
    },
    [answers],
  )

  const resetToInitialAnswers = useCallback(() => {
    if (initialAnswers) {
      setAnswers(initialAnswers)
    }
  }, [initialAnswers])

  const hasChanges = useMemo(
    () => !isEqual(answers, initialAnswers),
    [answers, initialAnswers],
  )

  return {
    answers,
    setAnswerFor,
    getAnswerFor,
    resetToInitialAnswers,
    hasChanges,
  }
}
