import { useModal } from '@capturi/use-modal'
import { ComponentProps, FC, useCallback } from 'react'

import { ConfirmationDialog } from './ConfirmationDialog'
import { BaseConfirmDialogProps } from './useConfirm'

export type ConfirmCallbackOptions<P extends BaseConfirmDialogProps> = {
  options?: Omit<P, keyof BaseConfirmDialogProps>
  onConfirm?: () => void
  onCancel?: () => void
}

export type ShowConfirmCallbackModal<P extends BaseConfirmDialogProps> = (
  options: ConfirmCallbackOptions<P>,
) => void

export const useConfirmCallback = (): ShowConfirmCallbackModal<
  ComponentProps<typeof ConfirmationDialog>
> => {
  return useConfirmCallbackWithComponent(ConfirmationDialog)
}

// This overload allows providing a custom confirmation dialog component
export const useConfirmCallbackWithComponent = <
  T extends FC<BaseConfirmDialogProps>,
>(
  component: T,
): ShowConfirmCallbackModal<ComponentProps<T>> => {
  const [showModal, hideModal] = useModal(component)

  return useCallback(
    ({
      options: extraProps,
      onConfirm,
      onCancel,
    }: ConfirmCallbackOptions<ComponentProps<T>>) => {
      // Create the base props that all confirmation dialogs need
      const baseProps: BaseConfirmDialogProps = {
        onConfirm: () => {
          onConfirm?.()
          hideModal()
        },
        onClose: () => {
          onCancel?.()
          hideModal()
        },
      }

      showModal({
        ...extraProps,
        ...baseProps,
      })
    },
    [showModal, hideModal],
  )
}
