import { Flex } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FC } from 'react'
import isEqual from 'react-fast-compare'
import { useEditorStore } from '../EditorStoreProvider'
import { EditorStoreState } from '../useQuestionnaireEditorStore'
import FreeTextQuestionEditor from './FreeTextQuestionEditor'
import RangeQuestionEditor from './RangeQuestionEditor'
import YesNoQuestionEditor from './YesNoQuestionEditor'

const select = (state: EditorStoreState) => state.selectedQuestion

export const QuestionEditor: FC = () => {
  const selectedQuestion = useEditorStore(select, isEqual)
  if (!selectedQuestion) {
    return (
      <Flex
        height="100%"
        alignItems="center"
        justifyContent="center"
        color="gray.500"
      >
        <Trans>Select a question to edit</Trans>
      </Flex>
    )
  }

  const isSubQuestion = !!selectedQuestion.parentQuestionUid

  switch (selectedQuestion.questionType) {
    case 'freeText':
      return (
        <FreeTextQuestionEditor
          sectionUid={selectedQuestion.sectionUid}
          questionUid={selectedQuestion.questionUid}
          isSubQuestion={isSubQuestion}
          parentQuestionUid={selectedQuestion.parentQuestionUid}
        />
      )
    case 'yesNo':
      return (
        <YesNoQuestionEditor
          sectionUid={selectedQuestion.sectionUid}
          questionUid={selectedQuestion.questionUid}
          isSubQuestion={isSubQuestion}
          parentQuestionUid={selectedQuestion.parentQuestionUid}
        />
      )
    case 'range':
      return (
        <RangeQuestionEditor
          sectionUid={selectedQuestion.sectionUid}
          questionUid={selectedQuestion.questionUid}
          isSubQuestion={isSubQuestion}
          parentQuestionUid={selectedQuestion.parentQuestionUid}
        />
      )
    default:
      return null
  }
}

export default QuestionEditor
