import { FC, useCallback } from 'react'

import { RangeQuestion } from 'features/questionnaire'
import { useEditorStore } from '../../EditorStoreProvider'
import {
  EditorStoreState,
  findQuestionByUID,
} from '../../useQuestionnaireEditorStore'
import { RangeConditionEditor } from './RangeConditionEditor'
import { YesNoConditionEditor } from './YesNoConditionEditor'

interface ConditionEditorProps {
  sectionUid: string
  parentQuestionUid: string
}

export const ConditionEditor: FC<ConditionEditorProps> = ({
  sectionUid,
  parentQuestionUid,
}) => {
  const select = useCallback(
    (state: EditorStoreState) => {
      const selectedQuestion = state.selectedQuestion
      const questions = state.questionnaire.sections.find(
        (s) => s.uid === sectionUid,
      )?.questions
      if (!questions) return { selectedQuestion, parentQuestion: null }
      return {
        selectedQuestion,
        parentQuestion: findQuestionByUID(questions, parentQuestionUid),
      }
    },
    [sectionUid, parentQuestionUid],
  )

  const { selectedQuestion, parentQuestion } = useEditorStore(select)

  if (!selectedQuestion || !parentQuestion) return null
  if (parentQuestion.type !== 'yesNo' && parentQuestion.type !== 'range') {
    return null
  }

  if (parentQuestion.type === 'yesNo') {
    const currentGroup = parentQuestion.subQuestions?.find(
      (group) => group.question.uid === selectedQuestion.questionUid,
    )
    return (
      <YesNoConditionEditor
        sectionUid={sectionUid}
        parentQuestion={parentQuestion}
        selectedQuestionUid={selectedQuestion.questionUid}
        currentGroup={currentGroup}
      />
    )
  }

  if (parentQuestion.type === 'range') {
    const currentGroup = parentQuestion.subQuestions?.find(
      (group) => group.question.uid === selectedQuestion.questionUid,
    )
    const rangeParent = parentQuestion as RangeQuestion
    return (
      <RangeConditionEditor
        sectionUid={sectionUid}
        parentQuestion={parentQuestion}
        selectedQuestionUid={selectedQuestion.questionUid}
        currentGroup={currentGroup}
        scale={rangeParent.scale}
      />
    )
  }

  return null
}
