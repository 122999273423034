import { QuestionAnswer } from '@capturi/api-conversations'
import { Guid, QaQuestionnaire, Question } from '../types'
import {
  isRangeConditionSatisfied,
  isYesNoConditionSatisfied,
} from './conditions'

type AnswerRecord = Record<Guid, QuestionAnswer>

export const isQuestionAnswered = (
  question: Question,
  answers: AnswerRecord,
): boolean => {
  const answer = answers[question.uid]
  if (!answer) return false
  return question.type === 'freeText' && answer.type === 'freeText'
    ? answer.answer.trim() !== ''
    : true
}

export const getVisibleQuestions = (
  question: Question,
  answers: AnswerRecord,
): Question[] => {
  if (!('subQuestions' in question)) {
    return [question]
  }

  const answer = answers[question.uid]
  const visibleSubQuestions = question.subQuestions.filter((group) => {
    const { condition } = group
    if (
      condition.type === 'yesNoAnswer' ||
      condition.type === 'notRelevantYesNo'
    ) {
      return isYesNoConditionSatisfied(condition, answer)
    }
    if (
      condition.type === 'rangeAnswer' ||
      condition.type === 'notRelevantRange'
    ) {
      return isRangeConditionSatisfied(condition, answer)
    }
    return false
  })

  return [
    question,
    ...visibleSubQuestions.flatMap((group) =>
      getVisibleQuestions(group.question, answers),
    ),
  ]
}

export const areAllQuestionsAnswered = (
  questionnaire: QaQuestionnaire,
  answers: AnswerRecord,
): boolean => {
  return !questionnaire.sections.some((section) =>
    section.questions.some((question) => {
      const visibleQuestions = getVisibleQuestions(question, answers)
      return visibleQuestions.some((q) => !isQuestionAnswered(q, answers))
    }),
  )
}
