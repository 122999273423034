import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Stack,
  Switch,
  Text,
  Textarea,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { FC, useCallback, useMemo } from 'react'
import isEqual from 'react-fast-compare'
import { MdStraighten } from 'react-icons/md'
import { useEditorStore } from '../EditorStoreProvider'

import { useModal } from '@capturi/use-modal'
import {
  EditorStoreState,
  findQuestionByUID,
} from '../useQuestionnaireEditorStore'
import { ConditionEditor } from './ConditionEditor'
import { ConfirmDeleteQuestionDialog } from './ConfirmDeleteQuestionDialog'
import { QuestionnaireRangeTooltip } from './QuestionnaireRangeTooltip'

interface RangeQuestionEditorProps {
  sectionUid: string
  questionUid: string
  isSubQuestion?: boolean
  parentQuestionUid?: string
}

const RangeQuestionEditor: FC<RangeQuestionEditorProps> = ({
  sectionUid,
  questionUid,
  isSubQuestion = false,
  parentQuestionUid,
}) => {
  const select = useCallback(
    (state: EditorStoreState) => ({
      question: findQuestionByUID(
        state.questionnaire.sections.find((s) => s.uid === sectionUid)
          ?.questions || [],
        questionUid,
      ),
      setQuestionText: state.setQuestionText,
      setQuestionDescription: state.setQuestionDescription,
      setQuestionNotRelevantOption: state.setQuestionNotRelevantOption,
      removeSubQuestion: state.removeSubQuestion,
      duplicateQuestion: state.duplicateQuestion,
      setQuestionScale: state.setQuestionScale,
    }),
    [sectionUid, questionUid],
  )

  const {
    question,
    setQuestionText,
    setQuestionDescription,
    setQuestionNotRelevantOption,
    removeSubQuestion,
    duplicateQuestion,
    setQuestionScale,
  } = useEditorStore(select, isEqual)

  const [openDeleteQuestionDialog] = useModal(ConfirmDeleteQuestionDialog)

  const hasDependantNASubQuestions = useMemo(() => {
    if (!question || question.type !== 'range') return false

    return question.subQuestions.some(
      (subQuestion) => subQuestion.condition.type === 'notRelevantRange',
    )
  }, [question])

  if (!question || question.type !== 'range') return null

  return (
    <Box p={6}>
      <Stack spacing={8}>
        <Flex justifyContent="space-between" alignItems="center">
          <HStack>
            <Icon as={MdStraighten} boxSize="20px" />
            <Text fontSize="lg" fontWeight="medium">
              <Trans>Number scale</Trans>
            </Text>
          </HStack>
          <Flex gap={2}>
            <Button
              onClick={() =>
                duplicateQuestion({
                  sectionUid,
                  parentQuestionUid,
                  questionUid: question.uid,
                })
              }
              aria-label={t`Duplicate`}
            >
              <Trans>Duplicate</Trans>
            </Button>
            <Button
              colorScheme="red"
              onClick={() =>
                openDeleteQuestionDialog({
                  onConfirm: () => removeSubQuestion(sectionUid, question.uid),
                })
              }
              aria-label={t`Delete`}
            >
              <Trans>Delete</Trans>
            </Button>
          </Flex>
        </Flex>

        {isSubQuestion && parentQuestionUid && (
          // Render the new shared condition editor.
          <ConditionEditor
            sectionUid={sectionUid}
            parentQuestionUid={parentQuestionUid}
          />
        )}

        <FormControl isRequired>
          <FormLabel>
            <Trans>Question</Trans>
          </FormLabel>
          <Input
            autoFocus
            value={question.title}
            onChange={(e) =>
              setQuestionText(sectionUid, question.uid, e.currentTarget.value)
            }
            placeholder={t`Enter question text`}
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            <Trans>Description</Trans>
          </FormLabel>
          <Textarea
            value={question.description || ''}
            onChange={(e) =>
              setQuestionDescription(
                sectionUid,
                question.uid,
                e.currentTarget.value || null,
              )
            }
            placeholder={t`Type in a description that is visible in the survey...`}
            rows={3}
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            <Trans>Scale</Trans>
          </FormLabel>
          <Text fontSize="sm" color="gray.600" mb={8}>
            <Trans>
              Select the desired number of options (e.g., if you want a
              five-point scale)
            </Trans>
          </Text>
          <Box>
            <RangeSlider
              colorScheme="primary"
              min={0}
              max={10}
              step={1}
              value={[question.scale.min, question.scale.max]}
              onChange={(range) => {
                setQuestionScale(
                  sectionUid,
                  question.uid,
                  {
                    min: range[0],
                    max: range[1],
                  },
                  question.labels,
                )
              }}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <QuestionnaireRangeTooltip
                label={question.scale.min}
                style={{
                  position: 'absolute',
                  left: `${(question.scale.min / 10) * 100}%`,
                }}
              >
                <RangeSliderThumb index={0} />
              </QuestionnaireRangeTooltip>
              <QuestionnaireRangeTooltip
                label={question.scale.max}
                style={{
                  position: 'absolute',
                  left: `${(question.scale.max / 10) * 100}%`,
                }}
              >
                <RangeSliderThumb index={1} />
              </QuestionnaireRangeTooltip>
            </RangeSlider>
          </Box>

          <HStack justifyContent="space-between">
            <Input
              value={question.labels.min}
              onChange={(e) =>
                setQuestionScale(sectionUid, question.uid, question.scale, {
                  ...question.labels,
                  min: e.currentTarget.value,
                })
              }
              placeholder={t`Left label`}
              w="30%"
            />

            <Input
              textAlign="right"
              value={question.labels.max}
              onChange={(e) =>
                setQuestionScale(sectionUid, question.uid, question.scale, {
                  ...question.labels,
                  max: e.currentTarget.value,
                })
              }
              placeholder={t`Right label`}
              w="30%"
            />
          </HStack>
        </FormControl>

        <FormControl>
          <HStack alignItems="flex-start" justifyContent="space-between">
            <VStack align="flex-start" spacing={0}>
              <FormLabel fontSize="md" mb={0}>
                <Trans>"Not relevant" option</Trans>
              </FormLabel>
              <Text fontSize="sm">
                <Trans>Add an option to select "Not relevant".</Trans>
              </Text>
            </VStack>
            <Tooltip
              label={
                question.notRelevantOption && hasDependantNASubQuestions ? (
                  <Trans>
                    Cannot be disabled because it has dependant N/A
                    sub-questions
                  </Trans>
                ) : (
                  ''
                )
              }
              placement="top"
              hasArrow
              isDisabled={
                !question.notRelevantOption || !hasDependantNASubQuestions
              }
            >
              <Switch
                isChecked={question.notRelevantOption}
                isDisabled={
                  question.notRelevantOption && hasDependantNASubQuestions
                }
                onChange={(e) =>
                  setQuestionNotRelevantOption(
                    sectionUid,
                    question.uid,
                    e.target.checked,
                  )
                }
              />
            </Tooltip>
          </HStack>
        </FormControl>
      </Stack>
    </Box>
  )
}

export default RangeQuestionEditor
