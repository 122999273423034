import request from '@capturi/request'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

export type Role = 'user' | 'administrator' | 'teamlead'
export type User = {
  uid: string
  name: string
  title: string
  isAuthenticated: boolean
  organizationUid: string
  email: string
  profileImage: { key: string; type: string } | null
  isDeleted: boolean
  isSubordinate: boolean
  role: Role
  teamUid: string | null
}

type UserConstruct = {
  /**
   * users includes all non-deleted users sorted alphabetically
   */
  users: User[]
  /**
   * getUserByUid always return a user object.
   * it also includes deleted users
   * will return a "N/A" user, if the user isn't found
   */
  getUserByUid: (uid: string | null | undefined) => User
  getUsersByTeamUid: (uid: string | null | undefined) => User[]
  isLoading: boolean
}

type UserState = {
  users: User[]
  userMap: Map<string, User>
  userTeamMap: Map<string | null, User[]>
}

const maxFetchesCache = new Map<string, number>()

const createEmptyUser = (name: string, uid: string): User => ({
  uid,
  name: name,
  title: '',
  organizationUid: '',
  email: '',
  profileImage: null,
  isAuthenticated: false,
  isDeleted: false,
  isSubordinate: false,
  role: 'user',
  teamUid: null,
})
const compare = new Intl.Collator().compare

export const useUsers = (isDisabled?: boolean): UserConstruct => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['global', 'users'],
    queryFn: async (): Promise<UserState> => {
      const response = await request.get<User[]>(
        'authentication/users/simple?includeDeleted=true',
      )

      const userMap = new Map()
      const teamMap = new Map()
      const nonDeleted: User[] = []

      response.forEach((user) => {
        userMap.set(user.uid, user)

        const team = teamMap.get(user.teamUid) || []
        team.push(user)
        teamMap.set(user.teamUid, team)

        if (!user.isDeleted) {
          nonDeleted.push(user)
        }
      })

      return {
        users: nonDeleted.sort((a, b) => compare(a.name, b.name)),
        userMap: userMap,
        userTeamMap: teamMap,
      }
    },
    enabled: !isDisabled,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60, // 60 minutes
  })

  return useMemo(() => {
    const getUserByUid = (uid: string | null | undefined): User => {
      if (!data) return createEmptyUser('Loading...', uuidv4())
      if (!uid) {
        return createEmptyUser('N/A', uuidv4())
      }

      const u = data.userMap.get(uid)
      if (u) return u

      const fetchCount = maxFetchesCache.get(uid) || 0
      if (fetchCount < 3) {
        refetch()
        maxFetchesCache.set(uid, fetchCount + 1)
        return createEmptyUser('Loading...', uuidv4())
      }
      return createEmptyUser('N/A', uid)
    }

    const getUsersByTeamUid = (teamUid: string | null | undefined): User[] => {
      if (!data) return []
      if (!teamUid) return []
      return data.userTeamMap.get(teamUid) ?? []
    }

    return {
      users: data?.users || [],
      isLoading: isFetching,
      getUserByUid,
      getUsersByTeamUid,
      refetch: refetch,
    }
  }, [data, isFetching, refetch])
}

export default useUsers
