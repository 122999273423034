import { useState } from 'react'
import { DEFAULT_LANGUAGE, isSupportedLanguage } from './languages'

export default function usePreferredLanguage(
  initialLanguage?: string,
): [string, (language: string) => void] {
  return useState(() => {
    if (initialLanguage && isSupportedLanguage(initialLanguage)) {
      return initialLanguage
    }

    // Inspect browser preferred languages
    const matches = window.navigator.languages.filter(isSupportedLanguage)

    if (matches.length > 0) {
      const bestMatch = matches.includes('da')
        ? 'da-DK'
        : matches.includes('en')
          ? 'en-US'
          : //fallback to english
            'en-US'
      return bestMatch
    }

    return DEFAULT_LANGUAGE
  })
}
