import request, { ResponseError } from '@capturi/request'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'

import { QaQuestionnaire, QaQuestionnaireListItem, Question } from '../types'

export type UseQuestionnairesResult = {
  questionnaires?: QaQuestionnaireListItem[]
  questionnairesMap: Record<string, QaQuestionnaireListItem>
  isLoading: boolean
  isError: boolean
}

type QuestionnaireListResponse = {
  questionnaires: QaQuestionnaireListItem[]
}

const publishedSelector = (
  response: QuestionnaireListResponse,
): QuestionnaireListResponse => ({
  ...response,
  questionnaires: response.questionnaires.filter((q) => q.published),
})

const allSelector = (
  response: QuestionnaireListResponse,
): QuestionnaireListResponse => response

export const usePublishedQuestionnaires = () =>
  useQuestionnaires({ selector: publishedSelector })

export const useAllQuestionnaires = () =>
  useQuestionnaires({ selector: allSelector })

const useQuestionnaires = ({
  selector,
}: {
  selector: (response: QuestionnaireListResponse) => QuestionnaireListResponse
}): UseQuestionnairesResult => {
  const {
    data: response,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['questionnaire', 'list'],
    select: selector,
    queryFn: () =>
      request.get<QuestionnaireListResponse>('questionnaires/list', {
        query: { 'api-version': '3.3' },
      }),
    staleTime: 1000 * 60 * 5, // 5 minutes
  })
  const questionnaires = response?.questionnaires
  const questionnairesMap = useMemo(() => {
    if (!questionnaires) {
      return {}
    }

    return questionnaires.reduce<Record<string, QaQuestionnaireListItem>>(
      (acc, questionnaire) => {
        acc[questionnaire.uid] = questionnaire
        return acc
      },
      {},
    )
  }, [questionnaires])

  return {
    questionnaires,
    questionnairesMap,
    isLoading,
    isError,
  }
}

export const useQuestionnaire = (uid: string | undefined) => {
  return useQuery({
    queryKey: ['questionnaire', uid],
    queryFn: async () => {
      return await request.get<QaQuestionnaire>(`questionnaires/${uid}`, {
        query: { 'api-version': '3.3' },
      })
    },
    enabled: !!uid,
    staleTime: 1000 * 60 * 5, // 5 minutes
  })
}

type CreateQuestionnairePayload = {
  title: string | null
  sections:
    | {
        uid: string
        title: string | null
        questions: Question[] | null
      }[]
    | null
}

export const useCreateQuestionnaire = () => {
  const queryClient = useQueryClient()

  return useMutation<
    QaQuestionnaire,
    ResponseError,
    CreateQuestionnairePayload
  >({
    mutationFn: async (
      payload: CreateQuestionnairePayload,
    ): Promise<QaQuestionnaire> => {
      return await request.post<QaQuestionnaire>('questionnaires', {
        query: {
          'api-version': '3.3',
        },
        json: payload,
      })
    },
    onSuccess: (result) => {
      // Update the list without sections
      const { sections, ...listItem } = result
      queryClient.setQueryData<QuestionnaireListResponse | undefined>(
        ['questionnaire', 'list'],
        (old) => {
          return {
            questionnaires: [...(old?.questionnaires || []), listItem],
          }
        },
      )
      // Store the full questionnaire with sections
      queryClient.setQueryData<QaQuestionnaire>(
        ['questionnaire', result.uid],
        result,
      )
    },
  })
}

type UpdateQuestionnairePayload = Partial<CreateQuestionnairePayload> & {
  uid: string
  published?: boolean
}

export const useUpdateQuestionnaire = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      uid,
      ...payload
    }: UpdateQuestionnairePayload): Promise<QaQuestionnaire> => {
      return await request.patch<QaQuestionnaire>(`questionnaires/${uid}`, {
        query: {
          'api-version': '3.3',
        },
        json: payload,
      })
    },
    onSuccess: (result) => {
      // Update the list without sections
      const { sections, ...listItem } = result
      queryClient.setQueryData<QuestionnaireListResponse | undefined>(
        ['questionnaire', 'list'],
        (old) => {
          if (!old) return { questionnaires: [listItem] }
          return {
            questionnaires: old.questionnaires.map((item) =>
              item.uid === result.uid ? listItem : item,
            ),
          }
        },
      )
      // Store the full questionnaire with sections
      queryClient.setQueryData<QaQuestionnaire>(
        ['questionnaire', result.uid],
        result,
      )
    },
  })
}

export const useDeleteQuestionnaire = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (uid: string): Promise<void> => {
      await request.delete(`questionnaires/${uid}`, {
        query: {
          'api-version': '3.3',
        },
      })
    },
    onSuccess: (_, uid) => {
      // Remove the questionnaire from the list cache
      queryClient.setQueryData<QuestionnaireListResponse | undefined>(
        ['questionnaire', 'list'],
        (old) => {
          if (!old) return { questionnaires: [] }
          return {
            questionnaires: old.questionnaires.filter(
              (item) => item.uid !== uid,
            ),
          }
        },
      )
      // Remove the full questionnaire from the cache
      queryClient.removeQueries({ queryKey: ['questionnaire', uid] })
    },
  })
}

export const usePublishQuestionnaire = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (uid: string): Promise<QaQuestionnaire> => {
      return await request.post<QaQuestionnaire>(
        `questionnaires/${uid}/publish`,
        {
          query: {
            'api-version': '3.3',
          },
        },
      )
    },
    onSuccess: (result) => {
      // Update the list without sections
      const { sections, ...listItem } = result
      queryClient.setQueryData<QuestionnaireListResponse | undefined>(
        ['questionnaire', 'list'],
        (old) => {
          if (!old) return { questionnaires: [listItem] }
          return {
            questionnaires: old.questionnaires.map((item) =>
              item.uid === result.uid ? listItem : item,
            ),
          }
        },
      )
      // Store the full questionnaire with sections
      queryClient.setQueryData<QaQuestionnaire>(
        ['questionnaire', result.uid],
        result,
      )
    },
  })
}

export const useCloneQuestionnaire = () => {
  const queryClient = useQueryClient()
  const createQuestionnaireMutation = useCreateQuestionnaire()

  return useMutation({
    mutationFn: async (uid: string): Promise<QaQuestionnaire> => {
      // Fetch the full questionnaire with sections
      const original = await queryClient.fetchQuery({
        queryKey: ['questionnaire', uid],
        queryFn: async () => {
          return await request.get<QaQuestionnaire>(`questionnaires/${uid}`, {
            query: { 'api-version': '3.3' },
          })
        },
      })

      if (!original) {
        throw new Error('Failed to fetch questionnaire to clone')
      }

      // Create the clone with the same structure using mutateAsync for internal implementation
      return await createQuestionnaireMutation.mutateAsync({
        title: `${original.title} (Copy)`,
        sections: original.sections.map((section) => ({
          uid: section.uid,
          title: section.title,
          questions: section.questions,
        })),
      })
    },
  })
}
