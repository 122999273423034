import { useCurrentUser } from '@capturi/core'
import { useUsers } from '@capturi/stores'
import { Box, Divider, Spinner } from '@chakra-ui/react'
import { FilterSelect } from '../../components/FilterSelect/components/FilterSelect'
import { useSegmentStatesContext } from '../../state/segment-state'

import { t } from '@lingui/macro'
import { FC, useCallback, useMemo, useState } from 'react'

type Props = {
  value: string[]
  initialFocusRef?: React.RefObject<HTMLInputElement>
  setValue: (values: string[]) => void
  resetValue: () => void
  onClose: () => void
}

type User = {
  uid: string
  name: string
  title?: string
  email?: string
}

export const UserSelect: FC<Props> = ({
  value: maybeValue = [],
  initialFocusRef,
  setValue,
  resetValue,
  onClose,
}) => {
  const { users: allUsers, isLoading } = useUsers()
  const { isAdmin } = useCurrentUser()
  const [searchQuery, setSearchQuery] = useState('')
  const { states } = useSegmentStatesContext()
  const channel = states.length > 0 ? states[0].channel : 'phone'

  const value = maybeValue ?? []

  const users = useMemo(
    () => (isAdmin ? allUsers : allUsers.filter((u) => u.isSubordinate)),
    [allUsers, isAdmin],
  )

  const userMap = useMemo(
    () => new Map<string, User>(users.map((user) => [user.uid, user])),
    [users],
  )

  const filteredUsers = useMemo(() => {
    if (!searchQuery) return users
    return users.filter(
      (user) =>
        user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.title?.toLowerCase().includes(searchQuery.toLowerCase()),
    )
  }, [users, searchQuery])

  const handleSelect = useCallback(
    (userId: string) => {
      const index = value.findIndex((userUid) => userUid === userId)
      if (index !== -1) {
        setValue(value.toSpliced(index, 1))
      } else {
        setValue([...value, userId])
      }
    },
    [value, setValue],
  )

  const handleSelectAll = useCallback(() => {
    const newSelections = filteredUsers.map((user) => user.uid)
    setValue([...new Set([...value, ...newSelections])])
    setSearchQuery('')
  }, [value, filteredUsers, setValue])

  const renderUserOption = useCallback(
    (user: User, isSelected: boolean) => (
      <FilterSelect.OptionItem
        key={user.uid}
        isSelected={isSelected}
        onClick={() => handleSelect(user.uid)}
      >
        <Box>
          {user.name}
          {(user.title || user.email) && (
            <Box fontSize="sm" color="textMuted">
              {[user.title, user.email].filter(Boolean).join(', ')}
            </Box>
          )}
        </Box>
      </FilterSelect.OptionItem>
    ),
    [handleSelect],
  )
  if (isLoading) {
    return <Spinner />
  }

  return (
    <Box maxH="400px" display="flex" flexDirection="column">
      <Box px={2}>
        <FilterSelect.Search
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          ref={initialFocusRef}
        />
      </Box>
      <Divider />
      <Box flex={1} maxH="350px" overflowY="auto">
        <FilterSelect.FilterSelectOptions
          selected={value.length}
          total={filteredUsers.length}
          selectedItems={value.map((userUid) =>
            renderUserOption(
              userMap.get(userUid) ?? {
                uid: userUid,
                name: t`Unknown user`,
              },
              true,
            ),
          )}
          unselectedItems={filteredUsers.map((user) =>
            renderUserOption(user, value.includes(user.uid)),
          )}
        />
      </Box>
      <Box flexShrink={0}>
        {channel === 'phone' && (
          <FilterSelect.Footer
            selectedCount={value.length}
            onSelectAll={handleSelectAll}
            onReset={resetValue}
            onApply={onClose}
          />
        )}
      </Box>
    </Box>
  )
}
