import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Stack,
  Switch,
  Text,
  Textarea,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { FC, useCallback, useMemo } from 'react'
import isEqual from 'react-fast-compare'
import { MdCheckCircle } from 'react-icons/md'

import { useModal } from '@capturi/use-modal'
import { useEditorStore } from '../EditorStoreProvider'
import { EditorStoreState } from '../useQuestionnaireEditorStore'
import { findQuestionByUID } from '../useQuestionnaireEditorStore'
import { ConditionEditor } from './ConditionEditor'
import { ConfirmDeleteQuestionDialog } from './ConfirmDeleteQuestionDialog'

interface YesNoQuestionEditorProps {
  sectionUid: string
  questionUid: string
  isSubQuestion?: boolean
  parentQuestionUid?: string
}

const YesNoQuestionEditor: FC<YesNoQuestionEditorProps> = ({
  sectionUid,
  questionUid,
  isSubQuestion = false,
  parentQuestionUid,
}) => {
  const select = useCallback(
    (state: EditorStoreState) => ({
      question: findQuestionByUID(
        state.questionnaire.sections.find((s) => s.uid === sectionUid)
          ?.questions || [],
        questionUid,
      ),
      setQuestionText: state.setQuestionText,
      setQuestionDescription: state.setQuestionDescription,
      setQuestionNotRelevantOption: state.setQuestionNotRelevantOption,
      removeSubQuestion: state.removeSubQuestion,
      duplicateQuestion: state.duplicateQuestion,
      validationErrors: state.validationErrors,
    }),
    [sectionUid, questionUid],
  )

  const {
    question,
    setQuestionText,
    setQuestionDescription,
    setQuestionNotRelevantOption,
    removeSubQuestion,
    duplicateQuestion,
    validationErrors,
  } = useEditorStore(select, isEqual)

  const [openDeleteQuestionDialog] = useModal(ConfirmDeleteQuestionDialog)

  const hasDependantNASubQuestions = useMemo(() => {
    if (!question || question.type !== 'yesNo') return false

    return question.subQuestions.some(
      (subQuestion) => subQuestion.condition.type === 'notRelevantYesNo',
    )
  }, [question])

  if (!question || question.type !== 'yesNo') return null

  return (
    <Box p={6}>
      <Stack spacing={8}>
        <Flex justifyContent="space-between" alignItems="center">
          <HStack>
            <Icon as={MdCheckCircle} boxSize="20px" />
            <Text fontSize="lg" fontWeight="medium">
              {t`Yes/no Question`}
            </Text>
          </HStack>
          <Flex gap={2}>
            <Button
              onClick={() =>
                duplicateQuestion({
                  sectionUid,
                  parentQuestionUid,
                  questionUid: question.uid,
                })
              }
              aria-label={t`Duplicate`}
            >
              {t`Duplicate`}
            </Button>
            <Button
              colorScheme="red"
              onClick={() =>
                openDeleteQuestionDialog({
                  onConfirm: () => removeSubQuestion(sectionUid, question.uid),
                })
              }
              aria-label={t`Delete`}
            >
              {t`Delete`}
            </Button>
          </Flex>
        </Flex>

        {isSubQuestion && parentQuestionUid && (
          <ConditionEditor
            sectionUid={sectionUid}
            parentQuestionUid={parentQuestionUid}
          />
        )}

        <FormControl
          isRequired
          isInvalid={validationErrors.invalidQuestions.emptyTitle.has(
            question.uid,
          )}
        >
          <FormLabel>{t`Question`}</FormLabel>
          <Input
            autoFocus
            value={question.title}
            onChange={(e) =>
              setQuestionText(sectionUid, question.uid, e.currentTarget.value)
            }
            placeholder={t`Enter question text`}
          />
        </FormControl>

        <FormControl>
          <FormLabel>{t`Description`}</FormLabel>
          <Textarea
            value={question.description || ''}
            onChange={(e) =>
              setQuestionDescription(
                sectionUid,
                question.uid,
                e.currentTarget.value || null,
              )
            }
            placeholder={t`Type in a description that is visible in the survey...`}
            rows={3}
          />
        </FormControl>

        <FormControl>
          <HStack alignItems="flex-start" justifyContent="space-between">
            <VStack align="flex-start" spacing={0}>
              <FormLabel
                fontSize="md"
                mb={0}
              >{t`"Not relevant" option`}</FormLabel>
              <Text fontSize="sm">
                <Trans>Add an option to select "Not relevant".</Trans>
              </Text>
            </VStack>
            <Tooltip
              label={
                question.notRelevantOption && hasDependantNASubQuestions
                  ? t`Cannot be disabled because it has dependant N/A sub-questions`
                  : ''
              }
              placement="top"
              hasArrow
              isDisabled={
                !question.notRelevantOption || !hasDependantNASubQuestions
              }
            >
              <Switch
                isChecked={question.notRelevantOption}
                isDisabled={
                  question.notRelevantOption && hasDependantNASubQuestions
                }
                onChange={(e) =>
                  setQuestionNotRelevantOption(
                    sectionUid,
                    question.uid,
                    e.target.checked,
                  )
                }
              />
            </Tooltip>
          </HStack>
        </FormControl>
      </Stack>
    </Box>
  )
}

export default YesNoQuestionEditor
