import { Box, Checkbox } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { MouseEventHandler, ReactNode, memo } from 'react'
import { styles } from '../styles'

export const OptionItem: React.FC<{
  children: ReactNode
  isSelected: boolean
  onClick: MouseEventHandler<HTMLDivElement>
}> = memo(({ isSelected = false, onClick, children }) => {
  return (
    <div
      // biome-ignore lint/a11y/useSemanticElements: <no need to do anything else here>
      role="button"
      tabIndex={0}
      onClick={onClick}
      style={isSelected ? styles.selectedOption : styles.option}
    >
      <Checkbox
        width="100%"
        pointerEvents="none"
        userSelect="none"
        isChecked={isSelected}
        css={css`
          & > .chakra-checkbox__label {
            whitespace: nowrap;
            overflow: hidden;
            flex-grow: 1;
          }
        `}
      >
        <Box textOverflow="ellipsis" overflow="hidden">
          {children}
        </Box>
      </Checkbox>
    </div>
  )
})
