import analytics from '@capturi/analytics'
import { useAllTrackers } from '@capturi/api-trackers'
import { Text } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

import { BaseTrackerState } from '@capturi/api-filters'
import { FilterValueLabel } from '../../components/FilterValueLabel'

export const TrackersValueLabel: React.FC<{
  state: BaseTrackerState
  exclusion?: boolean
}> = ({ state, exclusion = false }) => {
  const hasValues = !isEmpty(state?.uids)
  const { data: trackers, error } = useAllTrackers()
  const isLoading = hasValues && !trackers && !error
  const trackersArr = Array.from(trackers ?? [])

  const names = state?.uids?.map((uid) => {
    const tracker = trackersArr.find((x) => x.uid === uid)
    const accessLevel = tracker?.accessLevel
    if (accessLevel === 'None') analytics.event('filter_trackers_noView')
    return (
      <Text
        as="span"
        color={accessLevel === 'None' ? 'warning' : 'black'}
        key={tracker?.uid}
      >
        {tracker?.name ?? '?'}
      </Text>
    )
  })

  const nameDescriptor = exclusion
    ? t`Tracker not detected`
    : t`Tracker detected`
  return (
    <FilterValueLabel
      name={nameDescriptor}
      value={names}
      isLoading={isLoading}
    />
  )
}
