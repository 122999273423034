import {
  InaccessibleSavedTextFilter,
  SavedTextFilter,
  SenderType,
  TextFilterValues,
  useUpdateSavedTextFilter,
} from '@capturi/api-filters'
import { useAllTrackers } from '@capturi/api-trackers'
import { useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import { useToast } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { Trans, t } from '@lingui/macro'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import isEqual from 'react-fast-compare'
import { IoMdPie, IoMdPricetag } from 'react-icons/io'
import {
  MdAlternateEmail,
  MdCompareArrows,
  MdDataUsage,
  MdEmail,
  MdInbox,
  MdLocationDisabled,
  MdMoreVert,
  MdMyLocation,
  MdPeople,
  MdPerson,
  MdTextFields,
  MdVerifiedUser,
} from 'react-icons/md'
import { TbNumbers } from 'react-icons/tb'
import { FilterValueLabel } from '../../components/FilterValueLabel'
import { CreateUpdateSavedTextFilterDialog } from '../../components/SavedFilterDialogs'
import { useFilterMenuPlacementContext } from '../../hooks/useMenuPlacementContext'
import { useSaveButtonState } from '../../hooks/useValidateAsSavedTextSegment'
import { toTextFilterValues } from '../../mappers'
import { configurations } from '../../segmentConfigurations'
import { DEFAULT_TEXT_STATE } from '../../state/segment-state'
import { Channel, TextSegmentBuilderState } from '../../types'
import { FilterChannelButton } from '../FilterChannelButton'
import { AddFilterCriteriaButton } from '../PhoneFilter/components/PhoneSegmentBuilder/components/AddFilterCriteriaButton'
import {
  CaseIdFilterComponent,
  DurationFilterComponent,
  DurationValueLabel,
  EmailAddressesFilterComponent,
  InboxFilter,
  InitialDirectionFilter,
  KeyTopicsFilter,
  LabelsFilter,
  StatusFilter,
  TeamSelectFilterComponent,
  TeamsValueLabel,
  TrackedCustomFieldFilter,
  TrackersSelectFilterComponent,
  TrackersValueLabel,
  UserSelectFilterComponent,
  UsersValueLabel,
  createNonTrackedCustomFieldFilter,
} from './Filters'
import { InitialSenderFilterComponent } from './Filters/InitialSender'
import { useCustomFieldsKeys } from './Filters/hooks/useFilterValues'
import {
  FilterCriteriaButtonIcon,
  TextFilterPopover,
} from './TextFilterPopover'
import { SavedTextFilterButton } from './components/SavedTextFilterButton'
import SavedTextFilterPopover from './components/SavedTextFilterPopover'
import { FilterEvent, logEvent } from './events'
import { useTextFilter } from './useTextFilter'

type Props = {
  initialValue: TextFilterValues
  segmentState?: TextSegmentBuilderState
  onSegmentStateChange?: (state: TextSegmentBuilderState) => void
  onChannelChange: (channel: Channel) => void
  showSaveButton?: boolean
  resetSavedFilterOnValuesChange?: boolean
  disabledChannels?: Channel[]
  children?: React.ReactNode
  extraMenuItems?: React.ReactNode
  configuration?: { label: string; color: string }
}

export const TextFilter: React.FC<Props> = ({
  initialValue,
  segmentState,
  onSegmentStateChange,
  onChannelChange,
  disabledChannels,
  showSaveButton = false,
  resetSavedFilterOnValuesChange = false,
  children,
  extraMenuItems,
  configuration = configurations[0],
}) => {
  const {
    items,
    addItem,
    addCustomFieldItem,
    removeItem,
    changeItem,
    filterValues,
    reinitWithValues,
  } = useTextFilter(initialValue, configuration.label)

  const { isSaveDisabled, saveDisabledReason } =
    useSaveButtonState(segmentState)

  const { mutate: updateSavedTextFilter } = useUpdateSavedTextFilter()

  const { data: customFieldsKeys } = useCustomFieldsKeys()
  const { data: trackers } = useAllTrackers()
  const { isChatOrg } = useFeatureFlags()

  const toast = useToast()
  const [openCreateUpdateSavedTextFilterDialog] = useModal(
    CreateUpdateSavedTextFilterDialog,
  )

  const notAllowedTrackers = useMemo(() => {
    if (trackers === undefined) return new Set()

    return trackers.reduce((set, t) => {
      if (t.accessLevel === 'None') {
        set.add(t.uid)
      }
      return set
    }, new Set())
  }, [trackers])

  const filterContainsInacessibleTextTracker = useMemo(
    () =>
      filterValues?.trackers?.some((t) =>
        t?.uids?.some((u: string) => notAllowedTrackers.has(u)),
      ),
    [filterValues?.trackers, notAllowedTrackers],
  )

  const currentUser = useCurrentUser()

  const [focusedFilterUid, setFocusedFilterUid] = useState<string>()
  const resetFocusedFilterUid = useCallback(
    () => setFocusedFilterUid(undefined),
    [],
  )

  const previousFilterValues = useRef<TextFilterValues>(filterValues)
  const handleUpdateSegmentState = useCallback(
    (state: TextSegmentBuilderState) => {
      previousFilterValues.current = state.values
      onSegmentStateChange?.(state)
    },
    [onSegmentStateChange],
  )

  useEffect(() => {
    if (isEqual(filterValues, previousFilterValues.current)) {
      return
    }

    previousFilterValues.current = filterValues
    handleUpdateSegmentState?.({
      channel: 'email',
      values: filterValues,
      savedTextFilter: resetSavedFilterOnValuesChange
        ? undefined
        : segmentState?.savedTextFilter,
    })
  }, [
    filterValues,
    handleUpdateSegmentState,
    segmentState?.savedTextFilter,
    resetSavedFilterOnValuesChange,
  ])

  const {
    onOpen: onOpenAddFilterMenu,
    isOpen: isOpenAddFilterMenu,
    onClose: onCloseAddFilterMenu,
  } = useDisclosure()

  const {
    isOpen: isContextMenuOpen,
    onOpen: onOpenContextMenu,
    onClose: onCloseContextMenu,
  } = useDisclosure()

  const {
    isOpen: isSaveSegmentMenuOpen,
    onOpen: onOpenSaveSegmentMenu,
    onClose: onCloseSaveSegmentMenu,
  } = useDisclosure()

  const {
    onOpen: onOpenSavedSegmentPopover,
    isOpen: isOpenSavedSegmentPopover,
    onClose: onCloseSavedSegmentPopover,
  } = useDisclosure({ defaultIsOpen: false })

  const closeAllMenus = useCallback(() => {
    onCloseSaveSegmentMenu()
    onCloseContextMenu()
    onCloseSavedSegmentPopover()
    onCloseAddFilterMenu()
  }, [
    onCloseSaveSegmentMenu,
    onCloseContextMenu,
    onCloseSavedSegmentPopover,
    onCloseAddFilterMenu,
  ])

  const handleOpenSavedSegmentPopover = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()
      onOpenSavedSegmentPopover()
    },
    [onOpenSavedSegmentPopover],
  )

  const handleAddFilterCriteria = useCallback(
    (key: keyof Omit<TextFilterValues, 'customFieldFilters'>): void => {
      const uid = addItem(key)
      setFocusedFilterUid(uid)
      logEvent(FilterEvent.AddFilterCriteria, {
        Filter: key,
        segmentLabel: configuration.label,
        channel: 'E-mail',
      })
    },
    [addItem, configuration.label],
  )

  const handleRemoveFilterItem = useCallback(
    (uid: string): void => {
      removeItem(uid)
      logEvent(FilterEvent.RemoveFilterCriteria, {
        channel: 'E-mail',
      })
    },
    [removeItem],
  )

  const handleAddCustomFieldFilter = useCallback(
    (key: string): void => {
      const uid = addCustomFieldItem(key)
      setFocusedFilterUid(uid)
      logEvent(FilterEvent.AddFilterCriteria, {
        Filter: key,
        segmentLabel: configuration.label,
        channel: 'E-mail',
      })
    },
    [addCustomFieldItem, configuration.label],
  )

  const handleSavedTextFilterSelected = useCallback(
    (savedTextFilter: SavedTextFilter | InaccessibleSavedTextFilter): void => {
      closeAllMenus()

      if (savedTextFilter?.accessLevel === 'None') {
        return
      }

      const values = toTextFilterValues(savedTextFilter.values)
      handleUpdateSegmentState?.({
        channel: 'email',
        savedTextFilter,
        values,
      })
      reinitWithValues(values)
    },
    [closeAllMenus, reinitWithValues, handleUpdateSegmentState],
  )

  const handleResetSavedFilter = useCallback((): void => {
    closeAllMenus()

    const values = { ...DEFAULT_TEXT_STATE }
    handleUpdateSegmentState?.({
      channel: 'email',
      savedTextFilter: undefined,
      values,
    })
    reinitWithValues(values)
  }, [closeAllMenus, reinitWithValues, handleUpdateSegmentState])

  const placement = useFilterMenuPlacementContext()

  return (
    <Flex
      align="center"
      wrap="wrap"
      gap={2}
      my={2}
      w="100%"
      data-stonly="filter"
    >
      <FilterChannelButton
        channel="email"
        color={configuration.color}
        onChannelChange={onChannelChange}
        disabledChannels={disabledChannels}
      />
      {segmentState?.savedTextFilter && (
        <SavedTextFilterButton
          savedTextFilter={segmentState.savedTextFilter}
          onChangeSavedFilter={handleSavedTextFilterSelected}
          onResetSavedFilter={handleResetSavedFilter}
        />
      )}
      {items.map((item) => {
        const defaultProps = {
          onChangeValue: changeItem,
          isOpen: focusedFilterUid === item.uid,
          onOpen: setFocusedFilterUid,
          onClose: resetFocusedFilterUid,
          onRemove: handleRemoveFilterItem,
        }
        switch (item.filterType) {
          case 'customFieldFilters':
            return customFieldsKeys?.keys.find(
              (customFieldKey) => customFieldKey.key === item.field,
            )?.tracked === true ? (
              <TrackedCustomFieldFilter
                {...defaultProps}
                key={item.uid}
                field={item.field}
                item={item}
              />
            ) : (
              <TextFilterPopover
                {...defaultProps}
                key={item.uid}
                item={item}
                hasValue={(item.values?.length ?? 0) > 0}
                filterCriteriaButtonProps={{
                  leftIcon: <FilterCriteriaButtonIcon icon={MdTextFields} />,
                  children: (
                    <FilterValueLabel
                      name={item.field ?? ''}
                      inverted={item.inverted}
                      value={item.values?.join(', ')}
                    />
                  ),
                }}
                PopoverComponent={createNonTrackedCustomFieldFilter(item.field)}
              />
            )
          case 'directionFilters': // use InitialDirection
            return (
              <InitialDirectionFilter
                {...defaultProps}
                key={item.uid}
                item={item}
              />
            )
          case 'senderFilters':
            return (
              <TextFilterPopover
                {...defaultProps}
                key={item.uid}
                item={item}
                hasValue={(item.values?.length ?? 0) > 0}
                filterCriteriaButtonProps={{
                  leftIcon: <FilterCriteriaButtonIcon icon={MdEmail} />,
                  children: (
                    <FilterValueLabel
                      name={
                        item.type === SenderType.Domain
                          ? t`Initial sender domain`
                          : t`Initial sender e-mail`
                      }
                      inverted={item.inverted}
                      value={item.values?.join(', ')}
                    />
                  ),
                }}
                PopoverComponent={InitialSenderFilterComponent}
              />
            )
          case 'emailsFilters':
            return (
              <TextFilterPopover
                {...defaultProps}
                key={item.uid}
                item={item}
                hasValue={(item.values?.length ?? 0) > 0}
                filterCriteriaButtonProps={{
                  leftIcon: <FilterCriteriaButtonIcon icon={MdPerson} />,
                  children: (
                    <FilterValueLabel
                      name={t`Customer`}
                      inverted={item.inverted}
                      value={item.values?.join(', ')}
                    />
                  ),
                }}
                PopoverComponent={EmailAddressesFilterComponent}
              />
            )
          case 'durationFilters':
            return (
              <TextFilterPopover
                {...defaultProps}
                key={item.uid}
                item={item}
                hasValue={true} // always show (with default)
                filterCriteriaButtonProps={{
                  leftIcon: <FilterCriteriaButtonIcon icon={TbNumbers} />,
                  children: <DurationValueLabel item={item} />,
                  maxW: '24rem',
                }}
                PopoverComponent={DurationFilterComponent}
              />
            )
          case 'externalUidFilters':
            return (
              <TextFilterPopover
                {...defaultProps}
                key={item.uid}
                item={item}
                hasValue={(item.values?.length ?? 0) > 0}
                filterCriteriaButtonProps={{
                  leftIcon: <FilterCriteriaButtonIcon icon={IoMdPricetag} />,
                  children: (
                    <FilterValueLabel
                      name={t`Ticket IDs`}
                      inverted={item.inverted}
                      value={item.values?.join(', ')}
                    />
                  ),
                }}
                PopoverComponent={CaseIdFilterComponent}
              />
            )
          case 'inboxFilters':
            return <InboxFilter {...defaultProps} key={item.uid} item={item} />
          case 'tagFilters':
            return <LabelsFilter {...defaultProps} key={item.uid} item={item} />
          case 'statusFilters':
            return <StatusFilter {...defaultProps} key={item.uid} item={item} />
          case 'teamFilters':
            return (
              <TextFilterPopover
                {...defaultProps}
                key={item.uid}
                item={item}
                hasValue={(item.values?.length ?? 0) > 0}
                filterCriteriaButtonProps={{
                  leftIcon: <FilterCriteriaButtonIcon icon={MdPeople} />,
                  children: (
                    <TeamsValueLabel
                      inverted={item.inverted}
                      uids={item.values}
                    />
                  ),
                }}
                PopoverComponent={TeamSelectFilterComponent}
              />
            )
          case 'trackers':
            return (
              <TextFilterPopover
                {...defaultProps}
                key={item.uid}
                item={item}
                hasValue={(item.uids?.length ?? 0) > 0}
                filterCriteriaButtonProps={{
                  border: filterContainsInacessibleTextTracker
                    ? '1px solid'
                    : 'none',
                  borderColor: filterContainsInacessibleTextTracker
                    ? 'warning'
                    : 'none',
                  borderRadius: '6px',
                  leftIcon: (
                    <FilterCriteriaButtonIcon
                      icon={item.inverted ? MdLocationDisabled : MdMyLocation}
                    />
                  ),
                  children: (
                    <Box
                      as="span"
                      color={
                        filterContainsInacessibleTextTracker
                          ? 'warning'
                          : 'initial'
                      }
                    >
                      <TrackersValueLabel
                        inverted={item.inverted}
                        uids={item.uids}
                      />
                    </Box>
                  ),
                }}
                PopoverComponent={TrackersSelectFilterComponent}
              />
            )
          case 'userFilters':
            return (
              <TextFilterPopover
                {...defaultProps}
                key={item.uid}
                item={item}
                hasValue={(item.values?.length ?? 0) > 0}
                filterCriteriaButtonProps={{
                  leftIcon: <FilterCriteriaButtonIcon icon={MdPerson} />,
                  children: (
                    <UsersValueLabel
                      inverted={item.inverted}
                      uids={item.values}
                    />
                  ),
                }}
                PopoverComponent={UserSelectFilterComponent}
              />
            )
          case 'keyTopicFilters':
            return (
              <KeyTopicsFilter {...defaultProps} key={item.uid} item={item} />
            )
        }
      })}
      <Menu
        placement={placement}
        isLazy
        autoSelect={false} // prevents scroll jump to last custom field
        onOpen={onOpenAddFilterMenu}
        isOpen={isOpenAddFilterMenu}
        onClose={closeAllMenus}
      >
        <AddFilterCriteriaButton component={MenuButton} showText={true} />

        <MenuList
          maxH="min(calc( 100vh - 264px ),700px)"
          scrollBehavior="smooth"
          overflowY="auto"
          css={css`
            &::-webkit-scrollbar {
              display: none;
            }
          `}
        >
          <MenuItem
            closeOnSelect={false}
            icon={<IoMdPie />}
            onClick={handleOpenSavedSegmentPopover}
          >
            <Trans>Saved segments</Trans>
          </MenuItem>
          <SavedTextFilterPopover
            isOpen={isOpenSavedSegmentPopover}
            onClose={closeAllMenus}
            savedFilter={segmentState?.savedTextFilter}
            onChangeSavedFilter={handleSavedTextFilterSelected}
          />
          <MenuDivider />
          <MenuItem
            icon={<IoMdPricetag />}
            onClick={() => handleAddFilterCriteria('externalUidFilters')}
          >
            <Trans>Ticket IDs</Trans>
          </MenuItem>
          <MenuItem
            icon={<MdPerson />}
            onClick={() => handleAddFilterCriteria('emailsFilters')}
          >
            <Trans>Customer</Trans>
          </MenuItem>
          <MenuItem
            icon={<MdInbox />}
            onClick={() => handleAddFilterCriteria('inboxFilters')}
          >
            <Trans>Inbox</Trans>
          </MenuItem>
          <MenuItem
            icon={<MdCompareArrows />}
            onClick={() => handleAddFilterCriteria('directionFilters')}
          >
            <Trans>Initial direction</Trans>
          </MenuItem>
          {!isChatOrg && (
            <MenuItem
              icon={<MdAlternateEmail />}
              onClick={() => handleAddFilterCriteria('senderFilters')}
            >
              <Trans>Initial sender</Trans>
            </MenuItem>
          )}
          <MenuItem
            icon={<MdDataUsage />}
            onClick={() => handleAddFilterCriteria('keyTopicFilters')}
          >
            <Trans>Key topics</Trans>
          </MenuItem>
          <MenuItem
            icon={<IoMdPricetag />}
            onClick={() => handleAddFilterCriteria('tagFilters')}
          >
            <Trans>Labels</Trans>
          </MenuItem>
          <MenuItem
            icon={<TbNumbers />}
            onClick={() => handleAddFilterCriteria('durationFilters')}
          >
            <Trans>Number of messages</Trans>
          </MenuItem>
          <MenuItem
            icon={<MdVerifiedUser />}
            onClick={() => handleAddFilterCriteria('statusFilters')}
          >
            <Trans>Status</Trans>
          </MenuItem>
          <MenuItem
            icon={<MdPeople />}
            onClick={() => handleAddFilterCriteria('teamFilters')}
          >
            <Trans>Teams</Trans>
          </MenuItem>
          <MenuItem
            icon={<MdMyLocation />}
            onClick={() => handleAddFilterCriteria('trackers')}
          >
            <Trans>Tracker</Trans>
          </MenuItem>
          <MenuItem
            icon={<MdPerson />}
            onClick={() => handleAddFilterCriteria('userFilters')}
          >
            <Trans>Users</Trans>
          </MenuItem>
          {customFieldsKeys && customFieldsKeys.keys.length > 0 && (
            <>
              <MenuDivider />
              {customFieldsKeys.keys.map((customFieldKey) => (
                <MenuItem
                  key={customFieldKey.key}
                  icon={<MdTextFields />}
                  onClick={() => handleAddCustomFieldFilter(customFieldKey.key)}
                >
                  {customFieldKey.key}
                </MenuItem>
              ))}
            </>
          )}
        </MenuList>
      </Menu>

      {children}

      {((showSaveButton && currentUser.permissions.editSegment) ||
        extraMenuItems) && (
        <Box>
          {/* Box wrapper ensures we do not apply margins to Menu component (unsupported) */}
          <Menu
            autoSelect={false}
            isOpen={isContextMenuOpen}
            onClose={closeAllMenus}
          >
            <MenuButton
              as={IconButton}
              icon={<MdMoreVert />}
              variant="ghost"
              size="xs"
              onClick={onOpenContextMenu}
              data-stonly="filter three dot"
            />
            <MenuList minW="auto">
              {showSaveButton && currentUser.permissions.editSegment && (
                <Tooltip
                  hasArrow
                  label={saveDisabledReason}
                  aria-label={saveDisabledReason}
                  placement="left"
                >
                  {segmentState && segmentState.savedTextFilter != null ? (
                    <>
                      <MenuItem
                        icon={<IoMdPie />}
                        isDisabled={isSaveDisabled}
                        onClick={onOpenSaveSegmentMenu}
                        closeOnSelect={false}
                      >
                        <Trans>Save segment</Trans>
                      </MenuItem>

                      <Menu
                        placement="auto-start"
                        isOpen={isSaveSegmentMenuOpen}
                        onClose={closeAllMenus}
                      >
                        <MenuList>
                          <Tooltip
                            label={t`You don't have edit rights for this segment`}
                            isDisabled={
                              segmentState.savedTextFilter.accessLevel ===
                              'Edit'
                            }
                            hasArrow
                            placement="top"
                          >
                            <MenuItem
                              closeOnSelect={true}
                              onClick={() => {
                                if (segmentState.savedTextFilter == null) {
                                  return
                                }
                                updateSavedTextFilter(
                                  {
                                    uid: segmentState.savedTextFilter.uid,
                                    filter: {
                                      ...segmentState.savedTextFilter,
                                      values: filterValues,
                                    },
                                  },
                                  {
                                    onSuccess: (savedTextFilter) => {
                                      const values = toTextFilterValues(
                                        savedTextFilter.values,
                                      )
                                      handleUpdateSegmentState?.({
                                        channel: 'email',
                                        savedTextFilter,
                                        values,
                                      })

                                      toast({
                                        title: t`Segment saved`,
                                        status: 'success',
                                      })
                                    },
                                    onError: (error) => {
                                      toast({
                                        title: t`An error occurred`,
                                        description: error.message,
                                        status: 'error',
                                      })
                                    },
                                  },
                                )

                                closeAllMenus()
                              }}
                              isDisabled={
                                segmentState.savedTextFilter.accessLevel !==
                                'Edit'
                              }
                            >
                              <span>
                                <Trans>
                                  Save to{' '}
                                  <b>{segmentState.savedTextFilter.name}</b>
                                </Trans>
                              </span>
                            </MenuItem>
                          </Tooltip>
                          <MenuItem
                            closeOnSelect={true}
                            onClick={() => {
                              closeAllMenus()
                              openCreateUpdateSavedTextFilterDialog({
                                state: filterValues,
                                onFilterCreated: (savedTextFilter) => {
                                  handleUpdateSegmentState?.({
                                    channel: 'email',
                                    savedTextFilter,
                                    values: toTextFilterValues(
                                      savedTextFilter.values,
                                    ),
                                  })
                                },
                                onFilterUpdated: (savedTextFilter) => {
                                  handleUpdateSegmentState?.({
                                    channel: 'email',
                                    savedTextFilter,
                                    values: toTextFilterValues(
                                      savedTextFilter.values,
                                    ),
                                  })
                                },
                              })
                            }}
                          >
                            <Trans>Save as new segment</Trans>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </>
                  ) : (
                    <MenuItem
                      icon={<IoMdPie />}
                      aria-disabled={isSaveDisabled}
                      isDisabled={isSaveDisabled}
                      onClick={() => {
                        closeAllMenus()
                        openCreateUpdateSavedTextFilterDialog({
                          state: filterValues,
                          onFilterCreated: (savedTextFilter) => {
                            onSegmentStateChange?.({
                              channel: 'email',
                              savedTextFilter,
                              values: toTextFilterValues(
                                savedTextFilter.values,
                              ),
                            })
                          },
                          onFilterUpdated: (savedTextFilter) => {
                            onSegmentStateChange?.({
                              channel: 'email',
                              savedTextFilter,
                              values: toTextFilterValues(
                                savedTextFilter.values,
                              ),
                            })
                          },
                        })
                      }}
                    >
                      <Trans>Save segment</Trans>
                    </MenuItem>
                  )}
                </Tooltip>
              )}
              {extraMenuItems}
            </MenuList>
          </Menu>
        </Box>
      )}
    </Flex>
  )
}
