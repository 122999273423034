import { QuestionnaireAnswer } from '@capturi/api-conversations'
import { VStack } from '@chakra-ui/react'
import { usePublishedQuestionnaires } from 'features/questionnaire'
import { FC, useMemo } from 'react'
import { QAQuestionnaireItem } from './QAQuestionnaireItem'

type QAQuestionnaireListProps = {
  answers: QuestionnaireAnswer[]
}

export const QAQuestionnaireList: FC<QAQuestionnaireListProps> = ({
  answers,
}) => {
  const { questionnairesMap } = usePublishedQuestionnaires()

  const answersWithQuestionnaires = useMemo(
    () =>
      answers.map((answer) => ({
        answer,
        questionnaireListItem: questionnairesMap[answer.questionnaireUid],
      })),
    [answers, questionnairesMap],
  )

  return (
    <VStack spacing={2} width="100%" align="stretch">
      {answersWithQuestionnaires.map(
        ({ answer, questionnaireListItem }) =>
          questionnaireListItem && (
            <QAQuestionnaireItem
              key={answer.uid}
              answer={answer}
              questionnaireListItem={questionnaireListItem}
            />
          ),
      )}
    </VStack>
  )
}
