import { Button, getLocalizedCustomPropLabel } from '@capturi/ui-components'
import {
  Box,
  Flex,
  HStack,
  Tag,
  TagCloseButton,
  TagLabel,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { ReactElement, useState } from 'react'

import { CustomPropFilterProps } from './types'

type CustomPropTextProps = CustomPropFilterProps<'Text'> & {
  initialFocusRef?: React.RefObject<HTMLInputElement>
}

const renderPhrase = (
  phrase: string,
  index: number,
  removePhrase: (index: number) => void,
): ReactElement => {
  return (
    <Tag m="1" justifyContent="space-between" key={index} borderRadius="999">
      <TagLabel>{phrase}</TagLabel>
      <TagCloseButton onClick={() => removePhrase(index)} />
    </Tag>
  )
}

export function CustomPropText({
  settings,
  locale,
  ...props
}: CustomPropTextProps): React.ReactElement {
  const [value, setValue] = useState<string[]>(props.value ?? [])
  const [newPhrase, setNewPhrase] = useState<string>('')

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>): void => {
    event.preventDefault()
    const text = event.clipboardData.getData('Text')

    const values = text.split(/,|\s/) // we split a , and any whitespace
    const s = new Set(value)
    const filteredNumbers = values.filter(
      (v, index) => v && !s.has(v) && values.indexOf(v) === index,
    )

    const newState = [...value, ...filteredNumbers]
    setValue(newState)
    props.setValue?.(newState)
    setNewPhrase('')
    props.initialFocusRef?.current?.focus()
  }

  const handleAdd = (): void => {
    const trimmed = newPhrase.trim()
    if (!trimmed) {
      setNewPhrase('')
      return
    }
    if (value.includes(trimmed)) {
      setNewPhrase('')
      return
    }
    const updatedValue = [...value, trimmed]
    setValue(updatedValue)
    props.setValue?.(updatedValue)
    setNewPhrase('')
  }

  const removePhrase = (index: number): void => {
    setValue(value.toSpliced(index, 1))
    props.setValue?.(value.toSpliced(index, 1))
    props.initialFocusRef?.current?.focus()
  }

  const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (['Enter', ',', 'Tab', '.', ' '].includes(e.key)) {
      handleAdd()
    }
  }

  return (
    <HStack m={2}>
      <Flex
        onClick={() => props.initialFocusRef}
        border="solid 1px"
        borderColor="border.light"
        borderRadius="md"
        height="100%"
        transition="200ms padding ease-out"
        padding={1}
        justify="space-between"
        w="100%"
        flexDirection="column"
        _focusWithin={{
          borderColor: 'primary.500',
          boxShadow: '0 0 0 1px var(--chakra-colors-primary-500)',
        }}
      >
        <Flex wrap="wrap" alignItems={'center'}>
          {value.map((phrase, index) =>
            renderPhrase(phrase, index, removePhrase),
          )}
          <Box
            as="input"
            fontSize="sm"
            px={2}
            my="6px"
            outline={0}
            placeholder={
              getLocalizedCustomPropLabel(settings.label, locale) ?? '?'
            }
            ref={props.initialFocusRef}
            onBlur={handleAdd}
            onKeyDown={handleKeypress}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNewPhrase(e.target.value)
            }
            value={newPhrase}
            onPaste={handlePaste}
          />
        </Flex>
      </Flex>
      <Button
        borderTopLeftRadius={0}
        borderBottomLeftRadius={0}
        onClick={() => {
          handleAdd()
          props.onClose?.()
        }}
      >
        <Trans>Search</Trans>
      </Button>
    </HStack>
  )
}
