import {
  SpeakerTrackerWordHitsResponseModel,
  insightsAPI,
} from '@capturi/api-insights'
import { BaseTracker, PhraseSettings } from '@capturi/api-trackers'
import {
  useFetchPhoneSegments,
  useFilterPeriodContext,
  useSegmentStatesContext,
} from '@capturi/filters'
import { PlaySnippetsButton } from '@capturi/ui-components'
import { Box, Popover, PopoverTrigger, Portal } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import NearnessPopoverContent from 'components/ConversationDetails/components/NearnessPopoverContent'
import { ColumnDefinition, DataGrid } from 'components/DataGrid'
import React, { useCallback, useMemo, useRef } from 'react'
import { MdTrackChanges } from 'react-icons/md'

import { useAtom } from 'jotai'
import hasNearnessSettings from '../../../../utils/hasNearnessSettings'
import NoDataText from '../../shared/components/NoDataText'
import UserBreakdownSkeleton from '../../shared/components/UserBreakdownSkeleton'
import { POLL_INTERVAL } from '../constants'
import {
  ItemData,
  TextTrackerPhraseHitsResponseModel,
  convertSegmentsToWordPhraseSegments,
  useWordPhraseHits,
} from '../data/useWordPhraseHits'
import {
  useEmailTrackerExamplesDrawer,
  usePhoneTrackerExamplesDrawer,
} from '../drawer'
import { Event, logEvent } from '../events'
import { useRevalidateData } from '../hooks/useRevalidateData'
import { useTextSegments } from '../hooks/useTextSegments'
import { plottableWordsAtom } from '../state'
import { formatHitRate, isProcessingInCurrentPeriod } from '../utils'

const hasHits = (d: ItemData): boolean =>
  d.segments.some((x) => x.data?.value && x.data.value > 0)

export type WordHitsProps = {
  tracker: BaseTracker
}

const WordHits = React.memo<WordHitsProps>(function WordHitsMemo({ tracker }) {
  const { states, phoneSegmentStates, textSegmentStates, getIndexForState } =
    useSegmentStatesContext()
  const openPhoneConversationDrawer = usePhoneTrackerExamplesDrawer()
  const openEmailConversationDrawer = useEmailTrackerExamplesDrawer()
  const { period, periodDef } = useFilterPeriodContext()
  const processingInCurrentPeriod = isProcessingInCurrentPeriod({
    isProcessing: tracker.isProcessing,
    processingProgress: tracker.processingProgress,
    isTextProcessing: tracker.isTextProcessing,
    textProcessingProgress: tracker.textProcessingProgress,
    periodFrom: period.from,
  })

  const [selectedWords, setSelectedWords] = useAtom(
    plottableWordsAtom(tracker.uid),
  )

  const {
    segments: phoneSegments,
    revalidate,
    isLoading,
  } = useFetchPhoneSegments<SpeakerTrackerWordHitsResponseModel>(
    () => insightsAPI.getSpeakerTrackerWordHits(tracker.uid),
    {
      refetchInterval: processingInCurrentPeriod ? POLL_INTERVAL : false,
    },
  )

  const { segments: textSegments, refetch: refetchTextSegments } =
    useTextSegments<TextTrackerPhraseHitsResponseModel>({
      url: `insights/trackers/text/${tracker.uid}/phrases`,
      refetchInterval: processingInCurrentPeriod ? POLL_INTERVAL : false,
    })

  const res = convertSegmentsToWordPhraseSegments(
    phoneSegmentStates,
    phoneSegments,
    textSegmentStates,
    textSegments,
    getIndexForState,
  )

  const triggerRevalidation = useRevalidateData(() => {
    revalidate()
    refetchTextSegments()
  })

  const data = useWordPhraseHits(res)
  const segmentsCount = res.length

  const handleGoToPhoneWordConversations = useCallback(
    (valueIndex: number, word: string): void => {
      const state = states[valueIndex]
      if (state.channel !== 'phone') {
        return
      }

      logEvent(Event.TrackerWord_ViewPhoneTrackerExamples)
      openPhoneConversationDrawer({
        tracker,
        filterState: {
          ...state.values,
          trackers: [
            ...state.values.trackers,
            {
              word: word,
              uids: [tracker.uid],
              inverted: false,
            },
          ],
        },
        periodDef,
        excludeDeletedConversations: false,
        onClose: () => {
          triggerRevalidation()
        },
      })
    },
    [
      states,
      openPhoneConversationDrawer,
      periodDef,
      tracker,
      triggerRevalidation,
    ],
  )

  const handleGoToEmailPhraseConversations = useCallback(
    (valueIndex: number, phrase: string): void => {
      const state = states[valueIndex]
      if (state.channel !== 'email') {
        return
      }

      logEvent(Event.TrackerWord_ViewEmailTrackerExamples)
      openEmailConversationDrawer({
        tracker,
        phrase,
        filterState: {
          ...state.values,
        },
        periodDef,
        onClose: () => {
          triggerRevalidation()
        },
      })
    },
    [
      states,
      openEmailConversationDrawer,
      periodDef,
      tracker,
      triggerRevalidation,
    ],
  )

  const handleSelectRows = useCallback(
    (rows: ItemData[]) => {
      setSelectedWords(rows.map((x) => x.item))
      logEvent(Event.TrackerWord_PlotTimeSeries, {
        wordPlotCount: rows.length,
        segmentCount: segmentsCount,
      })
    },
    [setSelectedWords, segmentsCount],
  )

  const columns = useMemo(() => {
    const columns: ColumnDefinition<ItemData>[] = [
      {
        type: 'button',
        id: 'nearness',
        width: 25,
        render: (row, segmentIndex) => {
          const nearnessObject = tracker.speech?.phrasesSettings?.[row.item]

          return (
            <>
              {hasNearnessSettings(nearnessObject) && segmentIndex === 0 && (
                <Popover trigger="hover">
                  <PopoverTrigger>
                    <Box>
                      <MdTrackChanges />
                    </Box>
                  </PopoverTrigger>
                  <Portal>
                    <NearnessPopoverContent
                      phrasesSetting={nearnessObject as PhraseSettings}
                    />
                  </Portal>
                </Popover>
              )}
            </>
          )
        },
      },
      {
        type: 'value',
        id: 'word',
        getValue: (d) => d.item,
        width: 100,
      },
      {
        type: 'value',
        id: 'hit-rate',
        getSegmentValue: (s) => s.data?.valuePct,
        formatValue: formatHitRate,
        alignRight: true,
      },
      {
        type: 'progress',
        getSegmentValue: (s) => s.data?.valuePct,
        formatValue: formatHitRate,
        range: [0, 1],
      },
      {
        type: 'button',

        render: (row, rowSegmentIndex) => {
          const data = row.segments[rowSegmentIndex].data
          if (!data) return <Box />
          const stateIndex = data.stateIndex
          const isEmailChannel = states[stateIndex].channel === 'email'
          return (
            <PlaySnippetsButton
              label={t`View conversations`}
              onClick={() =>
                isEmailChannel
                  ? handleGoToEmailPhraseConversations(stateIndex, row.item)
                  : handleGoToPhoneWordConversations(stateIndex, row.item)
              }
            />
          )
        },
      },
    ]
    return columns
  }, [
    handleGoToPhoneWordConversations,
    handleGoToEmailPhraseConversations,
    states,
    tracker.speech?.phrasesSettings,
  ])

  const initialSelectedRowsRef = useRef(() => {
    return data.reduce<Record<string, boolean>>((memo, row, i) => {
      if (selectedWords.indexOf(row.item) !== -1) {
        memo[i] = true
      }
      return memo
    }, {})
  })

  return (
    <UserBreakdownSkeleton isLoaded={!isLoading}>
      <DataGrid
        data={data}
        columns={columns}
        selectableRows
        initialSelectedRows={initialSelectedRowsRef.current}
        onSelectedRowsChange={handleSelectRows}
        isRowSelectable={hasHits}
      >
        <NoDataText show={!isLoading && data.length === 0} />
      </DataGrid>
    </UserBreakdownSkeleton>
  )
})

export default WordHits
