import { Box, BoxProps, Tooltip } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'

import { isCuiElement } from '../cui'
import ListContext from './ListContext'

export type ListItemProps = {
  dense?: boolean
  button?: boolean
  hasDivider?: boolean
  hasToolTip?: boolean
  isDisabled?: boolean
  isSelected?: boolean
  disableGutters?: boolean
  ContainerComponent?: React.ElementType
  defaultHideSecondaryAction?: boolean
} & BoxProps

function useStyle({
  disableGutters,
  hasDivider,
  isDisabled,
  isSelected,
  dense,
  button,
}: ListItemProps): BoxProps {
  const root: BoxProps = {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    px: disableGutters ? 0 : 4,
    py: dense ? 1 : 2,
  }

  let dividerProps = {}
  let disabledProps = {}
  let buttonProps = {}

  if (hasDivider) {
    dividerProps = {
      borderBottom: '1px',
      borderBottomColor: 'gray.300',
    }
  }
  if (isDisabled) {
    disabledProps = {
      opacity: 0.5,
      pointerEvents: 'none',
      cursor: 'default',
    }
  }
  if (button) {
    const selectedBG = 'blackAlpha.100'
    buttonProps = {
      tabIndex: 0,
      role: 'button',
      cursor: 'pointer',
      'aria-disabled': isDisabled,
      _hover: {
        backgroundColor: isSelected ? selectedBG : 'blackAlpha.50',
      },
      _active: {
        backgroundColor: 'blackAlpha.200',
      },
      _focus: {
        backgroundColor: selectedBG,
        outline: 0,
      },
      ...(isSelected
        ? {
            backgroundColor: selectedBG,
          }
        : {}),
    }
  }
  return {
    ...root,
    ...dividerProps,
    ...disabledProps,
    ...buttonProps,
  }
}

export const ListItem = React.forwardRef<HTMLDivElement, ListItemProps>(
  function ListItem(
    {
      dense: denseProp = false,
      button = false,
      disableGutters = false,
      hasDivider = false,
      hasToolTip = false,
      isDisabled = false,
      isSelected = false,
      ContainerComponent = 'li',
      children: childrenProp,
      as: asProp,
      defaultHideSecondaryAction = false,
      ...restProps
    },
    ref,
  ) {
    const context = React.useContext(ListContext)
    const dense = denseProp || context.dense
    const childContext = React.useMemo(
      () => ({
        dense,
        disableGutters,
      }),
      [dense, disableGutters],
    )

    const styleProps = useStyle({
      disableGutters,
      hasDivider,
      isDisabled,
      dense,
      button,
      isSelected,
    })

    let asComponent = asProp || 'li'
    if (button) {
      asComponent = asProp || 'div'
    }

    const children = React.Children.toArray(childrenProp)
    let secondaryActionIndex = -1
    if (children.length > 0) {
      secondaryActionIndex = children.findIndex((x) =>
        isCuiElement(x, ['ListItemSecondaryAction', 'ListItemMenu']),
      )
    }
    if (secondaryActionIndex !== -1) {
      // Extract secondary action from children
      const [secondaryAction] = children.splice(
        secondaryActionIndex,
        1,
      ) as React.ReactElement[]
      return (
        <ListContext.Provider value={childContext}>
          <Tooltip
            hasArrow
            label={t`You don't have permission to edit this tracker`}
            placement="top"
            isDisabled={!hasToolTip}
          >
            <Box
              cursor={isDisabled ? 'not-allowed' : 'pointer'}
              ref={ref}
              as={ContainerComponent}
              position={'relative'}
              // biome-ignore lint/a11y/useSemanticElements: need group here
              role="group"
            >
              <Box
                as={asProp || 'div'}
                {...styleProps}
                pr={dense ? 8 : 12}
                {...restProps}
              >
                {children}
              </Box>
              {React.cloneElement(secondaryAction, {
                showSecondaryAction: !defaultHideSecondaryAction || isSelected,
              })}
            </Box>
          </Tooltip>
        </ListContext.Provider>
      )
    }

    return (
      <Box ref={ref} as={asComponent} {...styleProps} {...restProps}>
        {children}
      </Box>
    )
  },
)
