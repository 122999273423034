import colors from '../colors'

export default {
  base: '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  md: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  lg: '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  extended:
    '0px 1px 1px 0px rgba(0, 0, 0, 0.10), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 5px 3px 0px rgba(0, 0, 0, 0.05), 0px 10px 4px 0px rgba(0, 0, 0, 0.01), 0px 15px 4px 0px rgba(0, 0, 0, 0.00)',
  textExtended:
    '0px 15px 4px rgba(0, 0, 0, 0.00), 0px 10px 4px rgba(0, 0, 0, 0.01), 0px 5px 3px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.09), 0px 1px 1px rgba(0, 0, 0, 0.10)',
  xl: '0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  '2xl':
    '0px 36px 80px rgba(0, 0, 0, 0.07), 0px 23.3333px 46.8519px rgba(0, 0, 0, 0.0531481), 0px 13.8667px 25.4815px rgba(0, 0, 0, 0.0425185), 0px 7.2px 13px rgba(0, 0, 0, 0.035), 0px 2.93333px 6.51852px rgba(0, 0, 0, 0.0274815), 0px 0.666667px 3.14815px rgba(0, 0, 0, 0.0168519)',
  outline: `0 0 0 3px ${colors.outlineColor}`,
  inputOutline: `0px 2px 0px 0px ${colors.gray[300]}`,
  'kbd-inner': '0px -2px 0px 0px #E2E8F0 inset',
}
