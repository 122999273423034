import { ReactNode, createContext, useContext, useMemo } from 'react'

import {
  EditorStoreState,
  createQuestionnaireEditorStoreInstance,
} from './useQuestionnaireEditorStore'

/* Scoped Zustand Store Provider and hook for Questionnaire Editor */
export const QuestionnaireEditorStoreContext = createContext<ReturnType<
  typeof createQuestionnaireEditorStoreInstance
> | null>(null)

export const EditorStoreProvider = ({
  children,
  autoFocusOnQuestionnaireTitle = false,
}: {
  children: ReactNode
  autoFocusOnQuestionnaireTitle?: boolean
}) => {
  const store = useMemo(
    () => createQuestionnaireEditorStoreInstance(autoFocusOnQuestionnaireTitle),
    [autoFocusOnQuestionnaireTitle],
  )

  return (
    <QuestionnaireEditorStoreContext.Provider value={store}>
      {children}
    </QuestionnaireEditorStoreContext.Provider>
  )
}

export const useEditorStore = <T,>(
  selector: (state: EditorStoreState) => T,
  equalityFn?: (left: T, right: T) => boolean,
) => {
  const store = useContext(QuestionnaireEditorStoreContext)
  if (!store) {
    throw new Error(
      'useEditorStore must be used within a QuestionnaireEditorStoreProvider',
    )
  }
  return store(selector, equalityFn)
}
