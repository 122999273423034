import { useModal } from '@capturi/use-modal'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { FC, useCallback } from 'react'
import isEqual from 'react-fast-compare'
import { MdTextFields } from 'react-icons/md'
import { useEditorStore } from '../EditorStoreProvider'
import {
  EditorStoreState,
  findQuestionByUID,
} from '../useQuestionnaireEditorStore'
import { ConditionEditor } from './ConditionEditor'
import { ConfirmDeleteQuestionDialog } from './ConfirmDeleteQuestionDialog'

interface FreeTextQuestionEditorProps {
  sectionUid: string
  questionUid: string
  isSubQuestion?: boolean
  parentQuestionUid?: string
}

const FreeTextQuestionEditor: FC<FreeTextQuestionEditorProps> = ({
  sectionUid,
  questionUid,
  isSubQuestion = false,
  parentQuestionUid,
}) => {
  const select = useCallback(
    (state: EditorStoreState) => ({
      question: findQuestionByUID(
        state.questionnaire.sections.find((s) => s.uid === sectionUid)
          ?.questions || [],
        questionUid,
      ),
      setQuestionText: state.setQuestionText,
      setQuestionDescription: state.setQuestionDescription,
      removeSubQuestion: state.removeSubQuestion,
      duplicateQuestion: state.duplicateQuestion,
    }),
    [sectionUid, questionUid],
  )

  const {
    question,
    setQuestionText,
    setQuestionDescription,
    removeSubQuestion,
    duplicateQuestion,
  } = useEditorStore(select, isEqual)

  const [openDeleteQuestionDialog] = useModal(ConfirmDeleteQuestionDialog)

  if (!question) return null

  return (
    <Box p={6}>
      <Stack spacing={8}>
        <Flex justifyContent="space-between" alignItems="center">
          <HStack>
            <Icon as={MdTextFields} boxSize="20px" />
            <Text fontSize="lg" fontWeight="medium">
              {t`Free Text Question`}
            </Text>
          </HStack>
          <Flex gap={2}>
            <Button
              onClick={() =>
                duplicateQuestion({
                  sectionUid,
                  parentQuestionUid,
                  questionUid: question.uid,
                })
              }
              aria-label={t`Duplicate`}
            >
              <Trans>Duplicate</Trans>
            </Button>
            <Button
              colorScheme="red"
              onClick={() =>
                openDeleteQuestionDialog({
                  onConfirm: () => removeSubQuestion(sectionUid, question.uid),
                })
              }
              aria-label={t`Delete`}
            >
              <Trans>Delete</Trans>
            </Button>
          </Flex>
        </Flex>

        {isSubQuestion && parentQuestionUid && (
          // Render the new shared condition editor.
          <ConditionEditor
            sectionUid={sectionUid}
            parentQuestionUid={parentQuestionUid}
          />
        )}

        <FormControl isRequired>
          <FormLabel>
            <Trans>Question</Trans>
          </FormLabel>

          <Input
            autoFocus={true}
            value={question.title}
            onChange={(e) =>
              setQuestionText(sectionUid, question.uid, e.currentTarget.value)
            }
            placeholder={t`Enter question text`}
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            <Trans>Description</Trans>
          </FormLabel>
          <Textarea
            value={question.description || ''}
            onChange={(e) =>
              setQuestionDescription(
                sectionUid,
                question.uid,
                e.currentTarget.value || null,
              )
            }
            placeholder={t`Type in a description that is visible in the survey...`}
            rows={3}
          />
        </FormControl>
      </Stack>
    </Box>
  )
}

export default FreeTextQuestionEditor
