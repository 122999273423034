import { apiRequest } from './request'
import { RequestOptions } from './types'

export async function downloadHeaderExtensionBlob(url: string): Promise<void> {
  const response = await apiRequest(url)
  const blob = await response.blob()
  const a = document.createElement('a')
  a.href = window.URL.createObjectURL(blob)
  const contentDisposition = response.headers.get('content-disposition') || ''
  const extension = contentDisposition.split('.').at(1) || ''
  if (extension) {
    a.setAttribute('download', `conversation.${extension}`)
    a.click()
  } else {
    const contentType = response.headers.get('content-type') || ''
    const { default: mimeDb } = await import('mime-db')
    const mime = mimeDb[contentType]
    const extension = mime.extensions?.at(0)
    a.setAttribute('download', `conversation.${extension}`)
    a.click()
  }
  window.URL.revokeObjectURL(a.href)
  a.remove()
}

export async function downloadBlob(
  url: string,
  name: string,
  options?: Partial<RequestOptions>,
): Promise<void> {
  const response = await apiRequest(url, options)
  const blob = await response.blob()

  const a = document.createElement('a')
  a.href = window.URL.createObjectURL(blob)
  a.setAttribute('download', name)
  a.click()

  window.URL.revokeObjectURL(a.href)
  a.remove()
}
