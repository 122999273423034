import { Input, InputProps } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { forwardRef } from 'react'

export const Search = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  return (
    <Input
      mt={2}
      ref={ref}
      {...props}
      autoFocus={true}
      padding="8px"
      marginBottom="8px"
      placeholder={t`Search...`}
    />
  )
})
