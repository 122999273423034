import { QuestionAnswer } from '@capturi/api-conversations'
import {
  Box,
  Divider,
  HStack,
  Skeleton,
  SkeletonText,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FC } from 'react'

import { useQuestionnaire } from '../../hooks/useQuestionnaires'
import { Guid } from '../../types'
import { QuestionnairePreview } from './QuestionnairePreview'

type LazyQuestionnairePreviewProps = {
  questionnaireUid: string
  initialAnswers?: Record<Guid, QuestionAnswer>
  showSubmitButton?: boolean
  isDisabled?: boolean
  onSubmit?: (answers: Record<Guid, QuestionAnswer>) => void
}

export const LazyQuestionnairePreview: FC<LazyQuestionnairePreviewProps> = ({
  questionnaireUid,
  initialAnswers,
  showSubmitButton = true,
  isDisabled = false,
  onSubmit,
}) => {
  // If questionnaireUid is provided, fetch the full questionnaire
  const {
    data: questionnaire,
    isLoading,
    isError,
    error,
  } = useQuestionnaire(questionnaireUid)

  if (isLoading) {
    return (
      <Box>
        <SkeletonText skeletonHeight={4} noOfLines={1} width="30%" mb={4} />

        <VStack align="start" mb={6} gap={0}>
          <SkeletonText skeletonHeight={5} noOfLines={1} width="15%" mb={1} />
          <Divider mb={4} />
          {/* Question 1 */}
          <VStack align="start" gap={1} w="100%">
            <SkeletonText skeletonHeight={4} noOfLines={1} width="30%" />
            <HStack gap={2} w="100%">
              <Skeleton height={4} width={12} borderRadius="sm" />
              <Skeleton height={4} width={12} borderRadius="sm" />
            </HStack>
          </VStack>
        </VStack>
      </Box>
    )
  }

  if (isError) {
    return (
      <Text color="danger">
        <Trans>Failed to load questionnaire: {error.message}</Trans>
      </Text>
    )
  }

  if (!questionnaire) {
    return null
  }

  return (
    <QuestionnairePreview
      questionnaire={questionnaire}
      initialAnswers={initialAnswers}
      showSubmitButton={showSubmitButton}
      isDisabled={isDisabled}
      onSubmit={onSubmit}
    />
  )
}
