import { Store } from '@tanstack/react-store'

type QuestionnaireState = {
  selectedQuestionnaireUid: string | null
  isInlinePreviewOpen: boolean
}

export const questionnaireStore = new Store<QuestionnaireState>({
  selectedQuestionnaireUid: null,
  isInlinePreviewOpen: false,
})

export const selectQuestionnaire = (uid: string) => {
  questionnaireStore.setState(() => ({
    selectedQuestionnaireUid: uid,
    isInlinePreviewOpen: true,
  }))
}

export const closeQuestionnairePreview = () => {
  questionnaireStore.setState(() => ({
    selectedQuestionnaireUid: null,
    isInlinePreviewOpen: false,
  }))
}
