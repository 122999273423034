import {
  PhoneFilterValues,
  SavedTextFilter,
  TextFilterValues,
} from '@capturi/api-filters'
import { createSchema, morphism } from 'morphism'

import { DEFAULT_TEXT_STATE } from '../state/segment-state'
import { toDuration } from '../utils/durations'

const valuesToSavedFilterValuesSchema = createSchema<
  PhoneFilterValues,
  Partial<PhoneFilterValues>
>({
  userUids: 'userUids',
  trackers: 'trackers',
  notTrackers: 'notTrackers',
  duration: 'duration',
  subjects: 'subjects',
  customers: 'customers',
  labels: 'labels',
  teamUids: 'teamUids',
  status: 'status',
  sentiment: 'sentiment',
  externalIdentity: 'externalIdentity',
  qaIsReviewed: 'qaIsReviewed',
  scores: 'scores',
  speakers: 'speakers',
  keyTopics: 'keyTopics',
  repeatCalls: 'repeatCalls',
  customNumberProp1: 'customNumberProp1',
  customNumberProp2: 'customNumberProp2',
  customNumberProp3: 'customNumberProp3',
  customNumberProp4: 'customNumberProp4',
  customNumberProp5: 'customNumberProp5',
  customNumberProp6: 'customNumberProp6',
  customNumberProp7: 'customNumberProp7',
  customNumberProp8: 'customNumberProp8',
  customNumberProp9: 'customNumberProp9',
  customNumberProp10: 'customNumberProp10',
  customProp1: 'customProp1',
  customProp2: 'customProp2',
  customProp3: 'customProp3',
  customProp4: 'customProp4',
  customProp5: 'customProp5',
  customProp6: 'customProp6',
  customProp7: 'customProp7',
  customProp8: 'customProp8',
  customProp9: 'customProp9',
  customProp10: 'customProp10',
})

const savedFilterValuesToValuesSchema = createSchema<
  PhoneFilterValues,
  Partial<PhoneFilterValues>
>({
  userUids: 'userUids',
  trackers: 'trackers',
  notTrackers: 'notTrackers',
  duration: (iteratee) => {
    const { duration } = iteratee
    return toDuration({
      min: duration?.min,
      max: duration?.max,
    })
  },
  subjects: 'subjects',
  customers: 'customers',
  labels: 'labels',
  teamUids: 'teamUids',
  status: 'status',
  sentiment: 'sentiment',
  externalIdentity: 'externalIdentity',
  qaIsReviewed: 'qaIsReviewed',
  scores: 'scores',
  speakers: 'speakers',
  keyTopics: 'keyTopics',
  repeatCalls: 'repeatCalls',
  customNumberProp1: 'customNumberProp1',
  customNumberProp2: 'customNumberProp2',
  customNumberProp3: 'customNumberProp3',
  customNumberProp4: 'customNumberProp4',
  customNumberProp5: 'customNumberProp5',
  customNumberProp6: 'customNumberProp6',
  customNumberProp7: 'customNumberProp7',
  customNumberProp8: 'customNumberProp8',
  customNumberProp9: 'customNumberProp9',
  customNumberProp10: 'customNumberProp10',
  customProp1: 'customProp1',
  customProp2: 'customProp2',
  customProp3: 'customProp3',
  customProp4: 'customProp4',
  customProp5: 'customProp5',
  customProp6: 'customProp6',
  customProp7: 'customProp7',
  customProp8: 'customProp8',
  customProp9: 'customProp9',
  customProp10: 'customProp10',
})

export function toSavedFilterValues(
  state: Partial<PhoneFilterValues>,
): PhoneFilterValues {
  return morphism(valuesToSavedFilterValuesSchema, state)
}

export function toFilterValues(
  model: Partial<PhoneFilterValues>,
): PhoneFilterValues {
  return morphism(savedFilterValuesToValuesSchema, model)
}

export function toTextFilterValues(
  model: SavedTextFilter['values'],
): TextFilterValues {
  return {
    directionFilters:
      model.directionFilters ?? DEFAULT_TEXT_STATE.directionFilters,
    durationFilters:
      model.durationFilters ?? DEFAULT_TEXT_STATE.durationFilters,
    emailsFilters: model.emailsFilters ?? DEFAULT_TEXT_STATE.emailsFilters,
    externalUidFilters:
      model.externalUidFilters ?? DEFAULT_TEXT_STATE.externalUidFilters,
    inboxFilters: model.inboxFilters ?? DEFAULT_TEXT_STATE.inboxFilters,
    keyTopicFilters:
      model.keyTopicFilters ?? DEFAULT_TEXT_STATE.keyTopicFilters,
    senderFilters: model.senderFilters ?? DEFAULT_TEXT_STATE.senderFilters,
    statusFilters: model.statusFilters ?? DEFAULT_TEXT_STATE.statusFilters,
    tagFilters: model.tagFilters ?? DEFAULT_TEXT_STATE.tagFilters,
    teamFilters: model.teamFilters ?? DEFAULT_TEXT_STATE.teamFilters,
    userFilters: model.userFilters ?? DEFAULT_TEXT_STATE.userFilters,
    trackers: model.trackers ?? DEFAULT_TEXT_STATE.trackers,
    customFieldFilters:
      model.customFieldFilters ?? DEFAULT_TEXT_STATE.customFieldFilters,
  }
}
