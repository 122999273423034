import { PhraseNearnessSettings } from '@capturi/api-trackers'
import { useFeatureFlags } from '@capturi/feature-flags'
import { useOrganization } from '@capturi/stores'
import { Button, SortDirectionArrow } from '@capturi/ui-components'
import {
  Box,
  Th as ChakraTh,
  Flex,
  Icon,
  Table,
  TableColumnHeaderProps,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  chakra,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { MdAdd, MdInfoOutline } from 'react-icons/md'
import { Column, Row, useSortBy, useTable } from 'react-table'

import { PhraseState } from '../../../types'
import { usePhraseFields } from '../../../usePhraseFields'
import { UnknownWordsMessage } from '../UnknownWordsMessage'
import { logEvent } from '../logEvent'
import { useTrackerHitsCacheContext } from '../preview-data/useTrackerHitsPreview'
import { DeletePhraseCell } from './cells/DeletePhraseCell'
import { PhraseCell } from './cells/PhraseCell'
import { PhraseHitsCell } from './cells/PhraseHitsCell'
import { PhraseNearCell, PhraseNotNearCell } from './cells/PhraseNearnessCells'
import { PrecisionCell } from './cells/PrecisionCell'

type PhrasesAdvancedViewProps = {
  updatePreviewData: () => void
}

type UsePhraseFieldsReturn = ReturnType<typeof usePhraseFields>

export type TableInstanceProps = {
  addPhraseFields: UsePhraseFieldsReturn['addPhraseFields']
  removePhraseField: UsePhraseFieldsReturn['removePhraseField']
  updatePhraseField: UsePhraseFieldsReturn['updatePhraseField']
  phrasesListDOMRef: React.RefObject<HTMLTableSectionElement>
  updatePreviewData: PhrasesAdvancedViewProps['updatePreviewData']
}

const PhrasesAdvancedView: React.FC<PhrasesAdvancedViewProps> = ({
  updatePreviewData,
}) => {
  const { enableProbability } = useFeatureFlags()
  const { uiLanguage } = useOrganization()
  const phrasesListDOMRef = React.useRef<HTMLTableSectionElement>(null)
  const phraseHitsCache = useTrackerHitsCacheContext()
  const {
    phrases: state,
    updatePhraseField,
    addPhraseFields,
    removePhraseField,
  } = usePhraseFields()

  const sortTypes = React.useMemo(() => {
    return {
      phrase: (
        rowA: Row<PhraseState>,
        rowB: Row<PhraseState>,
        columnId: string,
        desc: boolean | undefined,
      ) => {
        const v1 = String(rowA.values[columnId])
        const v2 = String(rowB.values[columnId])
        // Make sure empty fields, i.e. newly added fields, are placed at the bottom of the list regardless of sorting direction.
        if (v1.trim() === '') {
          return 1 * (desc ? -1 : 1)
        }
        return v1.localeCompare(v2, uiLanguage, {
          sensitivity: 'base',
        })
      },
      nearness: (
        rowA: Row<PhraseState>,
        rowB: Row<PhraseState>,
        columnId: string,
      ) => {
        const getFirstPhrase = (row: Row<PhraseState>): string =>
          (row.values[columnId] as PhraseNearnessSettings | undefined)
            ?.phrases?.[0] ?? ''
        const rowAFirstPhrase = getFirstPhrase(rowA)
        const rowBFirstPhrase = getFirstPhrase(rowB)
        return rowAFirstPhrase.localeCompare(rowBFirstPhrase, uiLanguage, {
          sensitivity: 'base',
        })
      },
      precision: (
        rowA: Row<PhraseState>,
        _rowB: Row<PhraseState>,
        columnId: string,
      ) => {
        const a = rowA.values[columnId]
        if (a === 'HigherAccuracy') {
          return -1
        }
        return 1
      },
      hits: (
        rowA: Row<PhraseState>,
        rowB: Row<PhraseState>,
        columnId: string,
      ) => {
        const getHitCount = (row: Row<PhraseState>): number | undefined => {
          const phrase = row.values[columnId]
          const cacheEntry = phraseHitsCache.get(phrase)
          return cacheEntry?.conversations?.length
        }
        const h1 = getHitCount(rowA)
        const h2 = getHitCount(rowB)
        return Number(h1) - Number(h2)
      },
    }
  }, [uiLanguage, phraseHitsCache])

  const columns = React.useMemo<Column<PhraseState>[]>(() => {
    const cols: Column<PhraseState>[] = [
      {
        id: 'phrase',
        Header: (
          <chakra.span>
            <Trans>Words/phrases</Trans>
            <Tooltip
              hasArrow
              label={t`To only get exact matches on part or an entire phrase use quotes (" ") . E.g. "car" to avoid hits on plurals (cars).`}
            >
              <chakra.span ml={1}>
                <Icon as={MdInfoOutline} />
              </chakra.span>
            </Tooltip>
          </chakra.span>
        ),
        accessor: 'value',
        Cell: PhraseCell,
        sortType: sortTypes.phrase,
      },
      {
        id: 'near-settings',
        Header: t`Near`,
        accessor: (row) => row.settings.near,
        Cell: PhraseNearCell,
        sortType: sortTypes.nearness,
      },
      {
        id: 'not-near-settings',
        Header: t`Not near`,
        accessor: (row) => row.settings.notNear,
        Cell: PhraseNotNearCell,
        sortType: sortTypes.nearness,
      },
      ...(enableProbability
        ? [
            {
              id: 'precision-settings',
              Header: (
                <chakra.span data-stonly="tracker_probability">
                  <Trans>Probability</Trans>
                </chakra.span>
              ),
              accessor: (row) => row.settings.precision,
              Cell: PrecisionCell,
              sortType: sortTypes.precision,
            } as Column<PhraseState>,
          ]
        : []),
      {
        id: 'hits',
        Header: t`Hits identified in preview`,
        accessor: 'value',
        alignRight: true,
        Cell: PhraseHitsCell,
        sortDescFirst: true,
        sortType: sortTypes.hits,
      },
      {
        id: 'delete-phrase',
        accessor: 'value',
        Cell: DeletePhraseCell,
        disableSortBy: true,
      },
    ]
    return cols
  }, [sortTypes, enableProbability])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: state,
        autoResetSortBy: false,
        sortTypes: React.useMemo(
          () => ({
            alphanumeric: (
              row1: Row<PhraseState>,
              row2: Row<PhraseState>,
              columnId: string,
            ) => {
              const v1 = String(row1.values[columnId])
              const v2 = String(row2.values[columnId])
              return v1.localeCompare(v2, uiLanguage, {
                numeric: true,
                sensitivity: 'base',
              })
            },
          }),
          [uiLanguage],
        ),
        /*
          the following arguments are not part of the react-table API, but
          anything we put into these options will automatically be available 
          on the instance. That way we can call these functions from any cell 
          renderer!
        */
        updatePhraseField,
        updatePreviewData,
        addPhraseFields,
        removePhraseField,
        phrasesListDOMRef,
      },
      useSortBy,
    )

  return (
    <Box minW="300px" width="100%" overflowX="auto">
      <Table variant="unstyled" size="sm" {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            // biome-ignore lint/correctness/useJsxKeyInIterable: is spread in
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // biome-ignore lint/correctness/useJsxKeyInIterable: is spread in
                <Th
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({ title: undefined }),
                  )}
                >
                  <Flex
                    direction={column.alignRight ? 'row-reverse' : 'row'}
                    align="center"
                  >
                    {column.render('Header')}
                    {column.isSorted && (
                      <SortDirectionArrow
                        isSortedDesc={column.isSortedDesc}
                        mx={1}
                      />
                    )}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody ref={phrasesListDOMRef} {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              // biome-ignore lint/correctness/useJsxKeyInIterable: spread
              <Tr
                {...row.getRowProps()}
                _hover={{
                  background: 'gray.50',
                }}
              >
                {row.cells.map((cell) => {
                  // biome-ignore lint/correctness/useJsxKeyInIterable: spread
                  return <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                })}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <Flex mt={2} px={4} py={2}>
        <Button
          variant="solid"
          primary
          leftIcon={<MdAdd />}
          onClick={() => {
            logEvent('addedPhraseButtonClicked', true)
            addPhraseFields()
          }}
        >
          <Trans>Add word/phrase</Trans>
        </Button>
        <UnknownWordsMessage fields={state} />
      </Flex>
    </Box>
  )
}

export default PhrasesAdvancedView

const Th: React.FC<TableColumnHeaderProps> = (props) => (
  <ChakraTh
    {...props}
    color="text"
    fontSize="sm"
    textTransform="none"
    fontWeight="medium"
    letterSpacing="normal"
    wordBreak="break-word"
  />
)
