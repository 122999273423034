import { Box, Text } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { FC } from 'react'

import { FreetextAnswer } from '@capturi/api-conversations'
import DynamicTextArea from '../../../../pages/AskAI/Components/DynamicTextArea'
import { FreeTextQuestion } from '../../types'

type FreeTextPreviewProps = {
  question: FreeTextQuestion
  answer: FreetextAnswer | undefined
  onChange: (newAnswer: FreetextAnswer) => void
  isDisabled?: boolean
}

export const FreeTextPreview: FC<FreeTextPreviewProps> = ({
  question,
  answer,
  onChange,
  isDisabled = false,
}) => {
  const handleChange = (text: string) => {
    if (!isDisabled) {
      onChange({
        type: 'freeText',
        answer: text,
      })
    }
  }

  return (
    <Box>
      <Text fontSize="sm">{question.title || t`Add your question`}</Text>
      {question.description && (
        <Text fontSize="sm" color="gray.600" mb={2} whiteSpace="pre-wrap">
          {question.description}
        </Text>
      )}
      {isDisabled ? (
        <Text fontWeight="medium" whiteSpace="pre-wrap">
          {answer?.answer || ''}
        </Text>
      ) : (
        <DynamicTextArea
          bg="white"
          size="sm"
          placeholder={t`Type your answer...`}
          value={answer?.answer || ''}
          onChange={(e) => handleChange(e.target.value)}
          minH={14}
          px={2}
          py={1}
        />
      )}
    </Box>
  )
}
