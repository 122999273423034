import { Box, Flex, HStack, Icon, Text, VStack, chakra } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import UserAvatar from 'components/UserAvatar'
import { FC, memo, useCallback } from 'react'
import { MdMail, MdPhone } from 'react-icons/md'
import { ArchivedConversation } from '../api/types'

type Props = {
  conversation: ArchivedConversation
  isSelected: boolean
  onClick: (uid: string) => void
}

const dateFormat: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

export const ConversationListItem: FC<Props> = memo(
  ({ conversation, isSelected, onClick }) => {
    const handleClick = useCallback(() => {
      onClick(conversation.uid)
    }, [onClick, conversation.uid])
    return (
      <Box
        background={isSelected ? 'white' : 'gray.50'}
        borderLeftColor={isSelected ? 'primary.500' : 'gray.200'}
        borderLeftWidth={isSelected ? 2 : 0}
        cursor="pointer"
        borderBottom="1px"
        borderBottomColor="gray.300"
        h="108px" //This is how it is in figma
        padding="2"
        position="relative"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        onClick={handleClick}
      >
        <Flex
          alignItems="start"
          justifyContent="space-between"
          direction="column"
          color="gray.600"
          h="100%"
          w="100%"
        >
          <VStack alignItems="start" spacing={0} width="100%">
            <chakra.span
              color="gray.800"
              display="flex"
              alignItems="center"
              gap={1}
            >
              {conversation.type === 'Speech' ? (
                <Icon as={MdPhone} boxSize={4} color="gray.600" />
              ) : (
                <Icon as={MdMail} boxSize={4} color="gray.600" />
              )}
              {conversation.queue}
            </chakra.span>
            <VStack fontSize="sm" spacing={1} align="start" gap={0}>
              <Text>{i18n.date(conversation.date, dateFormat)}</Text>
              <Text>{conversation.customer}</Text>
            </VStack>
          </VStack>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            mt={2}
          >
            <HStack>
              <UserAvatar
                size="xs"
                profileImage={null}
                name={conversation.userName ?? ''}
              />
              <Text fontSize="xs" noOfLines={1}>
                {conversation.userName}
              </Text>
            </HStack>
          </Flex>
        </Flex>
      </Box>
    )
  },
)
