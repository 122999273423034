import { Box, Card, Flex, Text } from '@chakra-ui/react'
import { FC } from 'react'

type Props = { previewText: string }

export const TextPreview: FC<Props> = ({ previewText }) => {
  return (
    <Card p={4} mt={10}>
      <Flex flexDir="column">
        <Card p={4} mb={4} background="accents.lightBackground.default">
          <Box
            whiteSpace="pre-wrap"
            fontFamily="mono"
            fontSize="sm"
            overflowX="auto"
            sx={{
              '&::-webkit-scrollbar': {
                width: '8px',
                height: '8px',
                borderRadius: '8px',
                backgroundColor: 'gray.100',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'gray.300',
                borderRadius: '8px',
              },
            }}
          >
            <Text whiteSpace="pre-wrap">{previewText}</Text>
          </Box>
        </Card>
      </Flex>
    </Card>
  )
}
