import { PhoneFilterValues } from '@capturi/api-filters'
import { Period } from '@capturi/filters'
import { useSet } from '@capturi/react-utils'
import { downloadBlob } from '@capturi/request'
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { useState } from 'react'
import CsvCheckboxGroup from './CsvCheckboxGroup'
import { useCsvConfigData } from './useCsvConfigData'

type Props = {
  onClose: () => void
  period: Period
  filterValues: PhoneFilterValues | undefined
}

export const CsvConfiguratorModal: React.FC<Props> = ({
  onClose,
  period,
  filterValues,
}) => {
  const { CsvConfigData } = useCsvConfigData()
  const checkSet = useSet<string>([
    'Uid',
    'Date',
    'Subject',
    'Status',
    'Duration',
    'Customer',
    'UserUid',
    'UserName',
    'UserEmail',
    'Label',
    'ExternalIdentity',
    'TeamName',
    'TeamUid',
  ])

  const toast = useToast()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const handleSubmit = async (): Promise<void> => {
    const csvFields: string[] = []
    CsvConfigData.flatMap((u) => u.data).forEach((item) => {
      if (checkSet.has(item.value) && !item.hidden) csvFields.push(item.value)
    })
    setIsLoading(true)
    try {
      await downloadBlob(
        'conversations/csv?api-version=3.3',
        'conversation.csv',
        {
          method: 'post',
          json: {
            ...filterValues,
            fromInclusive: period.from,
            toInclusive: period.to,
            csvFields: csvFields,
          },
        },
      )
      setIsLoading(false)
      toast({
        title: 'Downloaded CSV file successfully',
        status: 'success',
      })
    } catch (_) {
      toast({
        title: 'Error downloading CSV file',
        description: 'Please try downloading again.',
        status: 'error',
      })
      setIsLoading(false)
    }
  }

  return (
    <Modal isOpen={true} onClose={onClose} size="3xl">
      <ModalOverlay>
        <ModalContent>
          <Flex direction="column">
            <ModalHeader>
              <Trans>Configure desired CSV data</Trans>
            </ModalHeader>
            <ModalCloseButton disabled={isLoading} />
          </Flex>
          <ModalBody>
            <Flex flexDir="row" flexWrap="wrap">
              {CsvConfigData.map((group, i) => {
                return (
                  <Box flex="1 0 33.3333%" p={2} key={i}>
                    {group.show !== false && (
                      <CsvCheckboxGroup
                        group={group}
                        set={checkSet}
                        isLoading={isLoading}
                      />
                    )}
                  </Box>
                )
              })}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button isDisabled={isLoading} mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              isLoading={isLoading}
              onClick={handleSubmit}
              colorScheme="primary"
            >
              Download CSV
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
