import analytics from '@capturi/analytics'
import { useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import { ButtonProps } from '@capturi/ui-components'
import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { MdMail, MdPhone } from 'react-icons/md'

import { Channel } from '../../types'

type FilterChannelButtonProps = {
  channel: Channel
  color: string
  disabledChannels?: Channel[]
  onChannelChange: (channel: Channel) => void
} & Omit<ButtonProps, 'children' | 'text'>
const FilterChannelButton: React.FC<FilterChannelButtonProps> =
  React.forwardRef<HTMLButtonElement, FilterChannelButtonProps>(
    function LoadContextButton(
      { channel, color, disabledChannels = [], onChannelChange },
      ref,
    ) {
      const { enableText } = useFeatureFlags()
      const currentUser = useCurrentUser()
      return (
        <Box>
          {/* Box wrapper ensures we do not apply margins to Menu component (unsupported) */}
          <Menu>
            <MenuButton
              ref={ref}
              as={IconButton}
              p={0}
              icon={channel === 'phone' ? <MdPhone /> : <MdMail />}
              aria-label={channel === 'phone' ? t`Phone` : t`Text`}
              colorScheme={color}
              variant="bottom-bordered-lifted"
              isDisabled={
                disabledChannels.includes('phone') &&
                disabledChannels.includes('email')
              }
            />
            <MenuList minW="auto">
              <MenuItem
                icon={<MdPhone />}
                isDisabled={disabledChannels.includes('phone')}
                onClick={() => {
                  onChannelChange('phone')
                  analytics.event('FilterChannel_changed', {
                    channel: 'Phone',
                  })
                }}
              >
                <Text fontWeight={channel === 'phone' ? '500' : '400'}>
                  <Trans>Phone</Trans>
                </Text>
              </MenuItem>
              <Tooltip
                hasArrow
                placement="top"
                isDisabled={
                  !disabledChannels.includes('email') &&
                  enableText &&
                  currentUser.permissions.text
                }
                label={t`In development`}
              >
                <MenuItem
                  isDisabled={
                    disabledChannels.includes('email') ||
                    !enableText ||
                    !currentUser.permissions.text
                  }
                  icon={<MdMail />}
                  onClick={() => {
                    onChannelChange('email')
                    analytics.event('FilterChannel_changed', {
                      channel: 'E-mail',
                    })
                  }}
                >
                  <Text fontWeight={channel === 'email' ? '500' : '400'}>
                    <Trans>Text</Trans>
                  </Text>
                </MenuItem>
              </Tooltip>
            </MenuList>
          </Menu>
        </Box>
      )
    },
  )

export default FilterChannelButton
