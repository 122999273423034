import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

import {
  ConditionForRange,
  Question,
  RangeQuestion,
} from 'features/questionnaire'
import { FC, useMemo, useState } from 'react'
import { MdClose, MdExpandMore, MdStraighten } from 'react-icons/md'
import { useEditorStore } from '../../EditorStoreProvider'
import { EditorStoreState } from '../../useQuestionnaireEditorStore'

interface RangeConditionEditorProps {
  sectionUid: string
  parentQuestion: RangeQuestion
  selectedQuestionUid: string
  currentGroup?: { condition: ConditionForRange; question: Question }
  scale: { min: number; max: number }
}

// Custom number dropdown that can display out-of-range values when closed
const NumberDropdown: FC<{
  value: number
  onChange: (value: number) => void
  min: number
  max: number
  isOutOfRange: boolean
  mr?: number | string
  ml?: number | string
}> = ({ value, onChange, min, max, isOutOfRange, mr, ml }) => {
  // Generate options from min to max
  const options = useMemo(() => {
    const opts = []
    for (let i = min; i <= max; i++) {
      opts.push(i)
    }
    return opts
  }, [min, max])

  return (
    <Menu isLazy>
      <MenuButton
        as={Button}
        bg="white"
        rightIcon={<Icon as={MdExpandMore} />}
        width={16}
        variant="outline"
        borderColor={isOutOfRange ? 'danger' : undefined}
        mr={mr}
        ml={ml}
      >
        {value}
      </MenuButton>
      <MenuList maxHeight="200px" overflowY="auto">
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => onChange(option)}
            fontWeight={option === value ? 'bold' : 'normal'}
            bg={option === value ? 'gray.100' : undefined}
          >
            {option}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

export const RangeConditionEditor: FC<RangeConditionEditorProps> = ({
  sectionUid,
  parentQuestion,
  selectedQuestionUid,
  currentGroup,
  scale,
}) => {
  const defaultCond: ConditionForRange = {
    type: 'rangeAnswer',
    answer: { min: scale.min, max: scale.max },
  }

  const currentCondition: ConditionForRange =
    currentGroup?.condition || defaultCond

  const initialMin =
    currentCondition.type === 'rangeAnswer'
      ? currentCondition.answer.min
      : scale.min
  const initialMax =
    currentCondition.type === 'rangeAnswer'
      ? currentCondition.answer.max
      : scale.max

  const [range, setRange] = useState<[number, number]>([initialMin, initialMax])
  const [isNotRelevant, setIsNotRelevant] = useState<boolean>(
    currentCondition.type === 'notRelevantRange',
  )

  const updateCondition = useEditorStore(
    (state: EditorStoreState) => state.updateSubQuestionRangeCondition,
  )

  // Check if min or max values are out of range
  const isMinOutOfRange = range[0] < scale.min || range[0] > scale.max
  const isMaxOutOfRange = range[1] < scale.min || range[1] > scale.max

  const handleTypeChange = (value: string) => {
    const newNA = value === 'notRelevantRange'
    setIsNotRelevant(newNA)
    const newCondition: ConditionForRange = newNA
      ? { type: 'notRelevantRange' }
      : {
          type: 'rangeAnswer',
          answer: { min: range[0], max: range[1] },
        }
    updateCondition(
      sectionUid,
      parentQuestion.uid,
      selectedQuestionUid,
      newCondition,
    )
  }

  const handleMinChange = (newMin: number) => {
    setRange([newMin, range[1]])
    const newCondition: ConditionForRange = {
      type: 'rangeAnswer',
      answer: { min: newMin, max: range[1] },
    }
    updateCondition(
      sectionUid,
      parentQuestion.uid,
      selectedQuestionUid,
      newCondition,
    )
  }

  const handleMaxChange = (newMax: number) => {
    setRange([range[0], newMax])
    const newCondition: ConditionForRange = {
      type: 'rangeAnswer',
      answer: { min: range[0], max: newMax },
    }
    updateCondition(
      sectionUid,
      parentQuestion.uid,
      selectedQuestionUid,
      newCondition,
    )
  }

  const shouldShowTypeMenuRow = useMemo(() => {
    if (!parentQuestion.notRelevantOption) return false
    return (
      parentQuestion.notRelevantOption ||
      currentCondition.type === 'rangeAnswer'
    )
  }, [parentQuestion.notRelevantOption, currentCondition.type])

  return (
    <Box p={2} borderWidth={1} borderRadius="md" bg="gray.50">
      <FormControl>
        <FormLabel mb={1}>
          <Trans>SHOW IF</Trans>
        </FormLabel>
        <VStack alignItems="stretch" gap={1}>
          {shouldShowTypeMenuRow && (
            <HStack gap={4} justifyContent="space-between" alignItems="center">
              <Text fontSize="xs">
                <Trans>The answer is</Trans>
              </Text>
              <Menu>
                <MenuButton
                  as={Button}
                  bg="white"
                  size="sm"
                  width={40}
                  rightIcon={<MdExpandMore />}
                  variant="outline"
                >
                  {isNotRelevant ? (
                    <HStack>
                      <Icon as={MdClose} />
                      <Text>
                        <Trans>Not relevant</Trans>
                      </Text>
                    </HStack>
                  ) : (
                    <HStack>
                      <Icon as={MdStraighten} />
                      <Text>
                        <Trans>Number</Trans>
                      </Text>
                    </HStack>
                  )}
                </MenuButton>
                <MenuList>
                  <MenuItem
                    value="rangeAnswer"
                    onClick={() => handleTypeChange('rangeAnswer')}
                  >
                    <HStack>
                      <Icon as={MdStraighten} />
                      <Text>
                        <Trans>Number</Trans>
                      </Text>
                    </HStack>
                  </MenuItem>
                  {parentQuestion.notRelevantOption && (
                    <MenuItem
                      value="notRelevantRange"
                      onClick={() => handleTypeChange('notRelevantRange')}
                    >
                      <HStack>
                        <Icon as={MdClose} />
                        <Text>
                          <Trans>Not relevant</Trans>
                        </Text>
                      </HStack>
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </HStack>
          )}

          {!isNotRelevant && (
            <Flex justifyContent="space-between" alignItems="center">
              <Text fontSize="xs" mr={4}>
                <Trans>Answer is between</Trans>
              </Text>
              <HStack justifyContent="space-between" gap={0} width={40}>
                <NumberDropdown
                  value={range[0]}
                  onChange={handleMinChange}
                  min={scale.min}
                  max={scale.max}
                  isOutOfRange={isMinOutOfRange}
                />
                <Text mx={2}>-</Text>
                <NumberDropdown
                  value={range[1]}
                  onChange={handleMaxChange}
                  min={scale.min}
                  max={scale.max}
                  isOutOfRange={isMaxOutOfRange}
                />
              </HStack>
            </Flex>
          )}
        </VStack>
      </FormControl>
    </Box>
  )
}
