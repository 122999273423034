export default {
  defaultProps: {
    colorScheme: 'primary',
  },
  variants: {
    yesButton: {
      container: {
        px: 3,
        py: 1,
        boxShadow: 'base',
        borderRadius: 'base',
        border: '1px solid',
        borderColor: 'gray.200',
        transition: 'all 0.1s ease',
        _checked: {
          bg: 'segments.quaternary.500',
          color: 'white',
        },
      },
      control: {
        _checked: {
          borderWidth: '3.5px',
          borderColor: 'white',
          bg: 'transparent',
          _before: {
            bg: 'segments.quaternary.500',
          },
          _hover: {
            bg: 'segments.quaternary.500',
            borderColor: 'white',
          },
        },
      },
      label: {
        ml: 1,
        fontSize: 'xs',
      },
    },
    noButton: {
      container: {
        px: 3,
        py: 1,
        boxShadow: 'base',
        borderRadius: 'base',
        border: '1px solid',
        borderColor: 'gray.200',
        transition: 'all 0.1s ease',
        _checked: {
          bg: 'segments.senary.500',
          color: 'white',
        },
      },
      control: {
        _checked: {
          borderWidth: '3.5px',
          borderColor: 'white',
          bg: 'transparent',
          _before: {
            bg: 'segments.senary.500',
          },
          _hover: {
            bg: 'segments.senary.500',
            borderColor: 'white',
          },
        },
      },
      label: {
        ml: 1,
        fontSize: 'xs',
      },
    },
    notRelevantButton: {
      container: {
        py: 1,
      },
      label: {
        ml: 1,
        fontSize: 'xs',
        color: 'gray.600',
      },
    },
  },
}
