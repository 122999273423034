import { Button, Flex, HStack, Text } from '@chakra-ui/react'
import { Plural, t } from '@lingui/macro'
import { memo } from 'react'

type FooterProps = {
  selectedCount: number
  onSelectAll: () => void
  onReset: () => void
  onApply: () => void
}

export const Footer: React.FC<FooterProps> = memo(
  ({ selectedCount, onSelectAll, onReset, onApply }) => {
    return (
      <HStack
        justify="space-between"
        p={0}
        pt={2}
        pb={2}
        px={1}
        boxShadow="0px -4px 8px -6px rgba(0, 0, 0, 0.20)"
      >
        <HStack w="100%" spacing={2} justify="space-between">
          <Flex align="center">
            <Button size="sm" variant="ghost" onClick={onSelectAll}>
              {t`Select all`}
            </Button>
            <Text color="gray.600">
              <Plural
                value={selectedCount}
                one="1 selected"
                other="# selected"
              />
            </Text>
          </Flex>
          <Flex>
            <Button size="sm" variant="ghost" onClick={onReset}>
              {t`Reset`}
            </Button>
            <Button
              size="sm"
              variant="ghost"
              color="primary.500"
              onClick={onApply}
            >
              {t`Apply`}
            </Button>
          </Flex>
        </HStack>
      </HStack>
    )
  },
)
