import { Box } from '@chakra-ui/react'
import { CSSProperties, FC, ReactElement } from 'react'

type SimpleTooltipProps = {
  label: string | number
  children: ReactElement
  style?: CSSProperties
}

export const QuestionnaireRangeTooltip: FC<SimpleTooltipProps> = ({
  label,
  children,
  style,
}) => {
  return (
    <Box display="inline-block" style={style}>
      {children}
      <Box
        position="absolute"
        bottom="100%"
        left="50%"
        transform="translateX(-50%)"
        filter="drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2))"
        mb={4}
      >
        <Box
          background="white"
          color="gray.800"
          px={0.5}
          py={0.5}
          borderRadius="sm"
          fontSize="sm"
          minW="26px"
          textAlign="center"
          position="relative"
          _after={{
            content: '""',
            position: 'absolute',
            top: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            borderLeft: '6px solid transparent',
            borderRight: '6px solid transparent',
            borderTop: '6px solid white',
          }}
        >
          {label}
        </Box>
      </Box>
    </Box>
  )
}
