import { useUsers } from '@capturi/stores'
import { InvertibleMultiSelectFooter } from '@capturi/ui-select'
import { Plural, t } from '@lingui/macro'
import React, { useCallback } from 'react'

import { FilterValueLabel } from '../../../components/FilterValueLabel'
import { UserSelect } from '../../../filters'
import { MultiValueFilterItem } from '../useTextFilter'
import { TextFilterPopoverComponentProps } from './types'

export const UsersValueLabel: React.FC<{
  inverted: boolean
  uids?: string[] | null
}> = ({ inverted, uids }) => {
  const { getUserByUid } = useUsers()
  const names = (uids ?? []).map((uid) => getUserByUid(uid).name)
  return <FilterValueLabel name={t`User`} inverted={inverted} value={names} />
}

export const UserSelectFilterComponent: React.FC<
  TextFilterPopoverComponentProps<MultiValueFilterItem>
> = ({ item, onChangeValue, onClose }) => {
  const { users } = useUsers()

  const handleSetValue = useCallback(
    (newValue: string[] | undefined) => {
      onChangeValue({
        ...item,
        values: newValue ?? null,
      })
    },
    [item, onChangeValue],
  )

  return (
    <>
      <UserSelect
        value={item.values ?? []}
        onClose={onClose}
        setValue={handleSetValue}
        resetValue={() => handleSetValue([])}
      />
      <InvertibleMultiSelectFooter
        entityName={t`User(s)`}
        isNot={item.inverted}
        onChangeIsNot={(isNot) =>
          onChangeValue({
            ...item,
            inverted: isNot,
          })
        }
        onReset={() =>
          onChangeValue({
            ...item,
            values: [],
          })
        }
        onSubmit={onClose}
        onSelectAllOptions={() =>
          onChangeValue({
            ...item,
            values: users.map((user) => user.uid),
          })
        }
        selectAllText={t`Select all`}
        selectedCount={item.values?.length || 0}
        formatSelectedCount={(count: string | number) => (
          <Plural value={count} one={'1 selected'} other={'# selected'} />
        )}
      />
    </>
  )
}
