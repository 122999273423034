import { QuestionnaireAnswer } from '@capturi/api-conversations'
import { useUsers } from '@capturi/stores'
import { Box, HStack, Text, VStack } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { QaQuestionnaireListItem } from 'features/questionnaire'
import { FC, memo } from 'react'
import { MdFlag } from 'react-icons/md'

const formatDate = (d: Date): string => {
  return i18n.date(d, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })
}

type QAQuestionnaireItemProps = {
  answer: QuestionnaireAnswer
  questionnaireListItem: QaQuestionnaireListItem
}

export const QAQuestionnaireItem: FC<QAQuestionnaireItemProps> = memo(
  ({ answer, questionnaireListItem }) => {
    const { getUserByUid } = useUsers()
    const creator = getUserByUid(answer.createdByUserUid)

    return (
      <Box
        borderWidth="1px"
        borderColor="gray.200"
        borderRadius="base"
        w="100%"
      >
        <HStack alignItems="flex-start" px={3} py={2} spacing={2}>
          <Box fontSize="lg">
            <MdFlag />
          </Box>
          <VStack spacing={0} alignItems="flex-start">
            <Text fontSize="sm">{questionnaireListItem?.title}</Text>
            <Text fontSize="xs" color="gray.500">
              {creator?.name} {formatDate(answer.created)}
            </Text>
          </VStack>
        </HStack>
      </Box>
    )
  },
)
