import { DEFAULT_FLAGS } from '@capturi/feature-flags'
import request from '@capturi/request'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export type OrgType = 'public' | 'private'

type OrganizationResponse = {
  org: {
    uid: string
    name: string
    uiLanguage: string
    timeZone: string
    conversationLanguage: string
    organizationType: OrgType
    features: Record<string, boolean>
    demoOrgSettings: {
      newestConversationDate: Date
    } | null
  }
}

type OrganizationStore = {
  isLoading: boolean
  isSucceeded: boolean

  uid: string
  name: string
  uiLanguage: string
  timeZone: string
  organizationType: OrgType
  conversationLanguage: string
  features: Record<string, boolean>
  demoOrgSettings: {
    newestConversationDate: Date
  } | null
  fetch: () => Promise<void>
}

const URL = 'authentication/organization?excludeUsers=true'

export const organizationStore = create<
  OrganizationStore,
  [['zustand/devtools', OrganizationStore]]
>(
  devtools((set, get) => ({
    uid: '',
    name: 'loading',
    conversationLanguage: 'da-DK',
    organizationType: 'private',
    uiLanguage: 'en-US',
    timeZone: 'Europe/London',
    demoOrgSettings: null,
    features: DEFAULT_FLAGS, //add default flags
    isLoading: false,
    isSucceeded: false,

    fetch: async () => {
      try {
        const isSucceeded = get().isSucceeded
        const isLoading = get().isLoading
        if (!(isSucceeded || isLoading)) {
          set(
            () => ({
              isLoading: true,
            }),
            false,
            'organizationStore.fetch',
          )

          const { org } = await request.get<OrganizationResponse>(URL)
          const {
            conversationLanguage,
            demoOrgSettings,
            features,
            uid,
            organizationType,
            name,
            uiLanguage,
            timeZone,
          } = org
          set(
            {
              conversationLanguage,
              demoOrgSettings,
              features: { ...DEFAULT_FLAGS, ...features },
              uid,
              organizationType,
              name,
              uiLanguage,
              timeZone,
              isLoading: false,
              isSucceeded: true,
            },
            false,
            'organizationStore.fetch',
          )
        }
      } catch {
        //TODO: some kind of error handling
        set(
          { isLoading: false, isSucceeded: true },
          false,
          'organizationStore.fetch',
        )
      }
    },
  })),
)
