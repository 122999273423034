import { produce } from 'immer'
import { create } from 'zustand'
import {
  ConditionForRange,
  ConditionForYesNo,
  FreeTextQuestion,
  Guid,
  QaQuestionnaire,
  Question,
  RangeQuestion,
  Section,
  YesNoQuestion,
} from '../../features/questionnaire'

export type ValidationErrorsRecord = {
  missingTitle: boolean
  invalidSections: {
    emptyTitle: Set<Guid>
    noQuestions: Set<Guid>
  }
  invalidQuestions: {
    emptyTitle: Set<Guid>
    outOfScaleCondition: Set<Guid>
  }
}

const calculateValidationErrors = (
  questionnaire: QaQuestionnaire,
): ValidationErrorsRecord => {
  const errors: ValidationErrorsRecord = {
    missingTitle: false,
    invalidSections: {
      emptyTitle: new Set(),
      noQuestions: new Set(),
    },
    invalidQuestions: {
      emptyTitle: new Set(),
      outOfScaleCondition: new Set(),
    },
  }
  if (!questionnaire.title.trim()) {
    errors.missingTitle = true
  }
  questionnaire.sections.forEach((section) => {
    if (!section.title.trim()) {
      errors.invalidSections.emptyTitle.add(section.uid)
    }
    if (section.questions.length === 0) {
      errors.invalidSections.noQuestions.add(section.uid)
    }
    // Note: we check every question in the entire tree
    const checkSubQuestion = (q: Question) => {
      if (!q.title.trim()) {
        errors.invalidQuestions.emptyTitle.add(q.uid)
      }

      // Check if range question has subquestions with conditions outside of scale
      if (q.type === 'range' && q.subQuestions && q.subQuestions.length > 0) {
        q.subQuestions.forEach((group) => {
          if (group.condition.type === 'rangeAnswer') {
            const condition = group.condition.answer
            const scale = q.scale

            // Check if condition range is within the question's scale
            if (condition.min < scale.min || condition.max > scale.max) {
              errors.invalidQuestions.outOfScaleCondition.add(
                group.question.uid,
              )
            }
          }

          checkSubQuestion(group.question)
        })
      } else if (q.type === 'yesNo' && q.subQuestions) {
        q.subQuestions.forEach((group) => checkSubQuestion(group.question))
      }
    }
    section.questions.forEach(checkSubQuestion)
  })
  return errors
}

/**
 * Creating a small helper to generate empty default structures.
 */
const createDefaultQuestionnaire = (): QaQuestionnaire => {
  const now = new Date()
  const creatorUid = crypto.randomUUID()
  return {
    uid: creatorUid,
    organizationUid: crypto.randomUUID(),
    title: '',
    created: now,
    createdByUserUid: creatorUid,
    updated: now,
    updatedByUserUid: creatorUid,
    published: false,
    publishedAt: null,
    publishedByUserUid: null,
    deleted: false,
    deletedByUserUid: null,
    deletedAt: null,
    sections: [
      {
        uid: crypto.randomUUID(),
        title: '',
        questions: [
          {
            uid: crypto.randomUUID(),
            type: 'yesNo',
            title: '',
            description: null,
            notRelevantOption: false,
            subQuestions: [],
          },
        ],
      },
    ],
  }
}

const createDefaultSection = (): Section => {
  return {
    uid: crypto.randomUUID(),
    title: '',
    questions: [],
  }
}

// Updated default free‑text question.
const createDefaultFreeTextQuestion = (): FreeTextQuestion => ({
  type: 'freeText',
  uid: crypto.randomUUID(),
  title: '',
  description: null,
})

// Default yes–no question now has a subQuestions array.
const createDefaultYesNoQuestion = (): YesNoQuestion => ({
  type: 'yesNo',
  uid: crypto.randomUUID(),
  title: '',
  description: null,
  notRelevantOption: false,
  subQuestions: [],
})

// New default range question.
const createDefaultRangeQuestion = (): RangeQuestion => ({
  type: 'range',
  uid: crypto.randomUUID(),
  title: '',
  description: null,
  notRelevantOption: false,
  scale: {
    min: 0,
    max: 10,
  },
  labels: {
    min: '',
    max: '',
  },
  subQuestions: [],
})

export type SelectedQuestion = {
  sectionUid: Guid
  questionUid: Guid
  questionType: 'freeText' | 'yesNo' | 'range'
  parentQuestionUid?: Guid
}

export type QuestionnaireEditorActions = {
  resetQuestionnaire: () => void
  loadQuestionnaire: (q: QaQuestionnaire) => void
  setTitle: (title: string) => void
  selectedQuestion: SelectedQuestion | null
  setSelectedQuestion: (question: SelectedQuestion | null) => void
  addSection: () => void
  removeSection: (sectionUid: Guid) => void
  setSectionTitle: (sectionUid: Guid, title: string) => void
  reorderSection: (sectionUid: Guid, direction: 'up' | 'down') => void
  canMoveSectionUp: (sectionUid: Guid) => boolean
  canMoveSectionDown: (sectionUid: Guid) => boolean
  addQuestion: (
    sectionUid: Guid,
    questionType: 'freeText' | 'yesNo' | 'range',
  ) => void
  addSubQuestion: (
    sectionUid: Guid,
    parentQuestionUid: Guid,
    subQuestionType: 'freeText' | 'yesNo' | 'range',
  ) => void
  removeSubQuestion: (sectionUid: Guid, questionUid: Guid) => void
  reorderQuestion: (
    sectionUid: Guid,
    questionUid: Guid,
    direction: 'up' | 'down',
  ) => void
  canMoveQuestionUp: (sectionUid: Guid, questionUid: Guid) => boolean
  canMoveQuestionDown: (sectionUid: Guid, questionUid: Guid) => boolean
  setQuestionText: (sectionUid: Guid, questionUid: Guid, text: string) => void
  setQuestionDescription: (
    sectionUid: Guid,
    questionUid: Guid,
    description: string | null,
  ) => void
  setQuestionNotRelevantOption: (
    sectionUid: Guid,
    questionUid: Guid,
    notRelevantOption: boolean,
  ) => void
  setQuestionScale: (
    sectionUid: Guid,
    questionUid: Guid,
    scale: { min: number; max: number },
    labels: { min: string; max: string },
  ) => void
  // NEW: Update subQuestions for YesNo and Range questions in a fine‐grained way.
  setQuestionSubQuestions: (
    sectionUid: Guid,
    questionUid: Guid,
    subQuestions: YesNoQuestion['subQuestions'] | RangeQuestion['subQuestions'],
  ) => void
  canPublish: () => boolean
  duplicateQuestion: ({
    sectionUid,
    questionUid,
    parentQuestionUid,
  }: {
    sectionUid: Guid
    questionUid: Guid
    parentQuestionUid?: Guid
  }) => void
  updateSubQuestionYesNoCondition: (
    sectionUid: Guid,
    parentQuestionUid: Guid,
    subQuestionUid: Guid,
    newCondition: ConditionForYesNo,
  ) => void
  updateSubQuestionRangeCondition: (
    sectionUid: Guid,
    parentQuestionUid: Guid,
    subQuestionUid: Guid,
    newCondition: ConditionForRange,
  ) => void
}

export type EditorStoreState = QuestionnaireEditorActions & {
  questionnaire: QaQuestionnaire
  validationErrors: ValidationErrorsRecord
  selectedQuestion: SelectedQuestion | null
  autoFocusOnQuestionnaireTitle: boolean
}

export type SubQuestionRecord = {
  condition: ConditionForYesNo | ConditionForRange
  question: Question
}

// The parent array may be either an array of top‑level Questions or an array of SubQuestionRecord.
export type ParentArray = Question[] | SubQuestionRecord[]

// Our visitor function gets the current question, the parent array (which might be either type),
// and the index in that array.
export type VisitorFunc = (
  q: Question,
  parentArray: ParentArray,
  index: number,
) => boolean

const traverseQuestions = (
  parentArr: ParentArray,
  visitor: VisitorFunc,
): boolean => {
  for (let i = 0; i < parentArr.length; i++) {
    // If the current element is a sub-question record (has the "question" property)
    // then extract its question; otherwise, the element itself is the question.
    // this is to have a single traverseQuestions vs having two versions
    const item = parentArr[i]
    const q = 'question' in item ? item.question : item

    if (visitor(q, parentArr, i)) {
      return true
    }
    // If this question can have subQuestions then recursively process them.
    if ('subQuestions' in q) {
      if (traverseQuestions(q.subQuestions, visitor)) {
        return true
      }
    }
  }
  return false
}

// Returns the question having question.uid === questionUid (or undefined if not found)
export const findQuestionByUID = (
  questions: Question[],
  questionUid: Guid,
): Question | undefined => {
  for (const q of questions) {
    if (q.uid === questionUid) return q
    if ('subQuestions' in q) {
      for (const group of q.subQuestions) {
        const found = findQuestionByUID([group.question], questionUid)
        if (found) return found
      }
    }
  }
  return undefined
}

// Returns the parent array and index of the question having question.uid === questionUid
const findQuestionParentAndIndex = (
  questions: Question[],
  questionUid: Guid,
): { parentArray: ParentArray; index: number } | undefined => {
  let result: { parentArray: ParentArray; index: number } | undefined
  traverseQuestions(questions, (q, parent, index) => {
    if (q.uid === questionUid) {
      result = { parentArray: parent, index }
      return true
    }
    return false
  })
  return result
}

// Removes the question with id === questionUid from the provided array (or its nested groups)
const removeQuestionByUid = (
  questions: Question[],
  questionUid: Guid,
): boolean => {
  return traverseQuestions(questions, (q, parent, index) => {
    if (q.uid === questionUid) {
      parent.splice(index, 1) // This directly removes the element from the real array.
      return true
    }
    return false
  })
}

// Reorders the question with id === questionUid within its parent array
const reorderQuestionByUid = (
  questions: Question[],
  questionUid: Guid,
  direction: 'up' | 'down',
): boolean => {
  return traverseQuestions(questions, (question, parent, index) => {
    if (question.uid === questionUid) {
      const newIndex = direction === 'up' ? index - 1 : index + 1
      if (newIndex >= 0 && newIndex < parent.length) {
        ;[parent[index], parent[newIndex]] = [parent[newIndex], parent[index]]
        return true
      }
    }
    return false
  })
}

const createDefaultSubQuestion = (
  subQuestionType: 'freeText' | 'yesNo' | 'range',
): Question => {
  switch (subQuestionType) {
    case 'freeText':
      return createDefaultFreeTextQuestion()
    case 'yesNo':
      return createDefaultYesNoQuestion()
    case 'range':
      return createDefaultRangeQuestion()
    default:
      throw new Error('Unsupported subQuestion type')
  }
}

// Helper function to deep copy a question with new UIDs for all subquestions
const deepCopyQuestionWithNewUIDs = (question: Question): Question => {
  switch (question.type) {
    case 'freeText':
      return {
        ...question,
        uid: crypto.randomUUID(),
      }

    case 'yesNo':
      return {
        ...question,
        uid: crypto.randomUUID(),
        subQuestions: question.subQuestions.map((subGroup) => ({
          condition: { ...subGroup.condition },
          question: deepCopyQuestionWithNewUIDs(subGroup.question),
        })),
      }

    case 'range':
      return {
        ...question,
        uid: crypto.randomUUID(),
        subQuestions: question.subQuestions.map((subGroup) => ({
          condition: { ...subGroup.condition },
          question: deepCopyQuestionWithNewUIDs(subGroup.question),
        })),
      }
  }
}

export const createQuestionnaireEditorStoreInstance = (
  autoFocusOnQuestionnaireTitle = false,
) =>
  create<EditorStoreState>((set, get) => {
    const initialQuestionnaire = createDefaultQuestionnaire()
    return {
      questionnaire: initialQuestionnaire,
      validationErrors: calculateValidationErrors(initialQuestionnaire),
      autoFocusOnQuestionnaireTitle,
      resetQuestionnaire: () => {
        const newQuestionnaire = createDefaultQuestionnaire()
        set((state) => ({
          questionnaire: newQuestionnaire,
          validationErrors: calculateValidationErrors(newQuestionnaire),
          selectedQuestion: null,
          autoFocusOnQuestionnaireTitle: state.autoFocusOnQuestionnaireTitle,
        }))
      },
      loadQuestionnaire: (q) => {
        set((state) => ({
          questionnaire: q,
          validationErrors: calculateValidationErrors(q),
          selectedQuestion:
            q.sections.length > 0 && q.sections[0].questions.length > 0
              ? {
                  sectionUid: q.sections[0].uid,
                  questionUid: q.sections[0].questions[0].uid,
                  questionType: q.sections[0].questions[0].type,
                }
              : null,
          autoFocusOnQuestionnaireTitle: state.autoFocusOnQuestionnaireTitle,
        }))
      },
      setTitle: (title: string) => {
        set(
          produce((state: EditorStoreState) => {
            state.questionnaire.title = title
            state.validationErrors = calculateValidationErrors(
              state.questionnaire,
            )
          }),
        )
      },
      selectedQuestion: null,
      setSelectedQuestion: (question: SelectedQuestion | null) => {
        set({ selectedQuestion: question })
      },
      addSection: () => {
        set(
          produce((state: EditorStoreState) => {
            const section = createDefaultSection()
            state.questionnaire.sections.push(section)
            state.validationErrors = calculateValidationErrors(
              state.questionnaire,
            )
          }),
        )
      },
      removeSection: (sectionUid: Guid) => {
        set(
          produce((state: EditorStoreState) => {
            state.questionnaire.sections = state.questionnaire.sections.filter(
              (s) => s.uid !== sectionUid,
            )
            state.validationErrors = calculateValidationErrors(
              state.questionnaire,
            )
          }),
        )
      },
      setSectionTitle: (sectionUid: Guid, title: string) => {
        set(
          produce((state: EditorStoreState) => {
            const section = state.questionnaire.sections.find(
              (s) => s.uid === sectionUid,
            )
            if (section) {
              section.title = title
              state.validationErrors = calculateValidationErrors(
                state.questionnaire,
              )
            }
          }),
        )
      },
      reorderSection: (sectionUid: Guid, direction: 'up' | 'down') => {
        set(
          produce((state: EditorStoreState) => {
            const { sections } = state.questionnaire
            const index = sections.findIndex((s) => s.uid === sectionUid)
            if (index !== -1) {
              const swapIndex = direction === 'up' ? index - 1 : index + 1
              if (swapIndex >= 0 && swapIndex < sections.length) {
                ;[sections[index], sections[swapIndex]] = [
                  sections[swapIndex],
                  sections[index],
                ]
                state.validationErrors = calculateValidationErrors(
                  state.questionnaire,
                )
              }
            }
          }),
        )
      },
      canMoveSectionUp: (sectionUid: Guid) => {
        const { sections } = get().questionnaire
        return sections.findIndex((s) => s.uid === sectionUid) > 0
      },
      canMoveSectionDown: (sectionUid: Guid) => {
        const { sections } = get().questionnaire
        return (
          sections.findIndex((s) => s.uid === sectionUid) < sections.length - 1
        )
      },
      canMoveQuestionUp: (sectionUid: Guid, questionUid: Guid) => {
        const section = get().questionnaire.sections.find(
          (s) => s.uid === sectionUid,
        )
        if (!section) return false
        const result = findQuestionParentAndIndex(
          section.questions,
          questionUid,
        )
        return result ? result.index > 0 : false
      },
      canMoveQuestionDown: (sectionUid: Guid, questionUid: Guid) => {
        const section = get().questionnaire.sections.find(
          (s) => s.uid === sectionUid,
        )
        if (!section) return false
        const result = findQuestionParentAndIndex(
          section.questions,
          questionUid,
        )
        return result ? result.index < result.parentArray.length - 1 : false
      },
      addQuestion: (
        sectionUid: Guid,
        questionType: 'freeText' | 'yesNo' | 'range',
      ) => {
        set((state) => {
          return produce(state, (draft) => {
            const section = draft.questionnaire.sections.find(
              (sec) => sec.uid === sectionUid,
            )
            if (section) {
              let question: Question
              if (questionType === 'freeText') {
                question = createDefaultFreeTextQuestion()
              } else if (questionType === 'yesNo') {
                question = createDefaultYesNoQuestion()
              } else if (questionType === 'range') {
                question = createDefaultRangeQuestion()
              } else {
                throw new Error('Unsupported question type')
              }
              section.questions.push(question)
              draft.selectedQuestion = {
                sectionUid,
                questionUid: question.uid,
                questionType,
              }
              draft.validationErrors = calculateValidationErrors(
                draft.questionnaire,
              )
            }
          })
        })
      },
      addSubQuestion: (sectionUid, parentQuestionUid, subQuestionType) => {
        set(
          produce((state: EditorStoreState) => {
            const section = state.questionnaire.sections.find(
              (s) => s.uid === sectionUid,
            )
            if (!section) return
            const parentQuestion = findQuestionByUID(
              section.questions,
              parentQuestionUid,
            )
            if (!parentQuestion) return
            if (
              parentQuestion.type !== 'yesNo' &&
              parentQuestion.type !== 'range'
            )
              return

            // Create the new sub-question
            const newSubQuestion = createDefaultSubQuestion(subQuestionType)

            if (parentQuestion.type === 'yesNo') {
              parentQuestion.subQuestions.push({
                condition: { type: 'yesNoAnswer', answer: 'Yes' },
                question: newSubQuestion,
              })
            } else if (parentQuestion.type === 'range') {
              parentQuestion.subQuestions.push({
                condition: {
                  type: 'rangeAnswer',
                  answer: {
                    min: parentQuestion.scale.min,
                    max: parentQuestion.scale.max,
                  },
                },
                question: newSubQuestion,
              })
            }

            state.validationErrors = calculateValidationErrors(
              state.questionnaire,
            )

            // Update the selection to the newly created sub-question
            state.selectedQuestion = {
              sectionUid,
              questionUid: newSubQuestion.uid,
              questionType: subQuestionType,
              parentQuestionUid: parentQuestionUid,
            }
          }),
        )
      },
      removeSubQuestion: (sectionUid: Guid, questionUid: Guid) => {
        set(
          produce((state: EditorStoreState) => {
            const section = state.questionnaire.sections.find(
              (sec) => sec.uid === sectionUid,
            )
            if (section) {
              removeQuestionByUid(section.questions, questionUid)
              state.validationErrors = calculateValidationErrors(
                state.questionnaire,
              )
            }
          }),
        )
      },
      reorderQuestion: (
        sectionUid: Guid,
        questionUid: Guid,
        direction: 'up' | 'down',
      ) => {
        set(
          produce((state: EditorStoreState) => {
            const section = state.questionnaire.sections.find(
              (sec) => sec.uid === sectionUid,
            )
            if (section) {
              reorderQuestionByUid(section.questions, questionUid, direction)
              state.validationErrors = calculateValidationErrors(
                state.questionnaire,
              )
            }
          }),
        )
      },
      setQuestionText: (sectionUid: Guid, questionUid: Guid, text: string) => {
        set(
          produce((state: EditorStoreState) => {
            const section = state.questionnaire.sections.find(
              (sec) => sec.uid === sectionUid,
            )
            if (section) {
              const question = findQuestionByUID(section.questions, questionUid)
              if (question) {
                question.title = text
                state.validationErrors = calculateValidationErrors(
                  state.questionnaire,
                )
              }
            }
          }),
        )
      },
      setQuestionDescription: (
        sectionUid: Guid,
        questionUid: Guid,
        description: string | null,
      ) => {
        set(
          produce((state: EditorStoreState) => {
            const section = state.questionnaire.sections.find(
              (sec) => sec.uid === sectionUid,
            )
            if (section) {
              const question = findQuestionByUID(section.questions, questionUid)
              if (question) {
                question.description = description
                state.validationErrors = calculateValidationErrors(
                  state.questionnaire,
                )
              }
            }
          }),
        )
      },
      setQuestionNotRelevantOption: (
        sectionUid: Guid,
        questionUid: Guid,
        notRelevantOption: boolean,
      ) => {
        set(
          produce((state: EditorStoreState) => {
            const section = state.questionnaire.sections.find(
              (sec) => sec.uid === sectionUid,
            )
            if (!section) {
              return
            }
            const question = findQuestionByUID(section.questions, questionUid)
            if (
              question &&
              (question.type === 'yesNo' || question.type === 'range')
            ) {
              question.notRelevantOption = notRelevantOption

              state.validationErrors = calculateValidationErrors(
                state.questionnaire,
              )
            }
          }),
        )
      },
      setQuestionScale: (
        sectionUid: Guid,
        questionUid: Guid,
        scale: { min: number; max: number },
        labels: { min: string; max: string },
      ) => {
        set(
          produce((state: EditorStoreState) => {
            const section = state.questionnaire.sections.find(
              (sec) => sec.uid === sectionUid,
            )
            if (!section) {
              return
            }
            const question = findQuestionByUID(section.questions, questionUid)
            if (question && question.type === 'range') {
              question.scale = scale
              question.labels = labels
              state.validationErrors = calculateValidationErrors(
                state.questionnaire,
              )
            }
          }),
        )
      },
      setQuestionSubQuestions: (
        sectionUid: Guid,
        questionUid: Guid,
        subQuestions:
          | YesNoQuestion['subQuestions']
          | RangeQuestion['subQuestions'],
      ) => {
        set(
          produce((state: EditorStoreState) => {
            const section = state.questionnaire.sections.find(
              (sec) => sec.uid === sectionUid,
            )
            if (section) {
              const question = findQuestionByUID(section.questions, questionUid)
              if (
                question &&
                (question.type === 'yesNo' || question.type === 'range')
              ) {
                question.subQuestions = subQuestions
                state.validationErrors = calculateValidationErrors(
                  state.questionnaire,
                )
              }
            }
          }),
        )
      },
      canPublish: () => {
        const { questionnaire, validationErrors } = get()
        if (
          validationErrors.missingTitle ||
          validationErrors.invalidSections.emptyTitle.size > 0 ||
          validationErrors.invalidSections.noQuestions.size > 0 ||
          validationErrors.invalidQuestions.emptyTitle.size > 0 ||
          validationErrors.invalidQuestions.outOfScaleCondition.size > 0
        ) {
          return false
        }
        if (
          questionnaire.sections.length === 0 ||
          questionnaire.sections.some((s) => s.questions.length === 0)
        ) {
          return false
        }
        return true
      },
      duplicateQuestion: ({
        sectionUid,
        questionUid,
        parentQuestionUid,
      }: {
        sectionUid: Guid
        questionUid: Guid
        parentQuestionUid?: Guid
      }) => {
        set((oldState) =>
          produce(oldState, (state) => {
            const section = state.questionnaire.sections.find(
              (s) => s.uid === sectionUid,
            )
            if (!section) return

            const questionToDuplicate = findQuestionByUID(
              section.questions,
              questionUid,
            )
            if (!questionToDuplicate) return

            // Deep copy of the question and append " (Copy)" to the questionText.
            const duplicatedQuestion =
              deepCopyQuestionWithNewUIDs(questionToDuplicate)
            if (duplicatedQuestion.title.length > 0) {
              duplicatedQuestion.title = `${duplicatedQuestion.title} (Copy)`
            }

            // Find the parent array + index where this question currently resides.
            const parentAndIndex = findQuestionParentAndIndex(
              section.questions,
              questionUid,
            )
            if (!parentAndIndex) return

            const { parentArray, index } = parentAndIndex
            const oldItem = parentArray[index]

            // Decide how to insert based on whether parentArray is a list of Questions or SubQuestionRecords
            if ('question' in oldItem) {
              // We are in a subQuestions array, i.e. oldItem is a SubQuestionRecord
              const oldRecord = oldItem // Type: SubQuestionRecord
              const newRecord = {
                condition: { ...oldRecord.condition }, // copy the condition
                question: duplicatedQuestion,
              }
              parentArray.splice(index + 1, 0, newRecord)
            } else {
              // We are in a top-level array of Questions
              parentArray.splice(index + 1, 0, duplicatedQuestion)
            }

            state.validationErrors = calculateValidationErrors(
              state.questionnaire,
            )

            state.selectedQuestion = {
              sectionUid,
              questionUid: duplicatedQuestion.uid,
              questionType: duplicatedQuestion.type,
              parentQuestionUid: parentQuestionUid,
            }
          }),
        )
      },
      updateSubQuestionYesNoCondition: (
        sectionUid: Guid,
        parentQuestionUid: Guid,
        subQuestionUid: Guid,
        newCondition: ConditionForYesNo,
      ) => {
        set(
          produce((state: EditorStoreState) => {
            const section = state.questionnaire.sections.find(
              (s) => s.uid === sectionUid,
            )
            if (!section) return

            const parentQuestion = findQuestionByUID(
              section.questions,
              parentQuestionUid,
            )
            if (!parentQuestion || parentQuestion.type !== 'yesNo') return
            const subIndex = parentQuestion.subQuestions.findIndex(
              (sub) => sub.question.uid === subQuestionUid,
            )
            if (subIndex === -1) return

            // Directly update the condition on the sub-question
            parentQuestion.subQuestions[subIndex].condition = newCondition

            state.validationErrors = calculateValidationErrors(
              state.questionnaire,
            )
          }),
        )
      },
      // Add new function for Range subquestions
      updateSubQuestionRangeCondition: (
        sectionUid: Guid,
        parentQuestionUid: Guid,
        subQuestionUid: Guid,
        newCondition: ConditionForRange,
      ) => {
        set(
          produce((state: EditorStoreState) => {
            const section = state.questionnaire.sections.find(
              (s) => s.uid === sectionUid,
            )
            if (!section) return

            const parentQuestion = findQuestionByUID(
              section.questions,
              parentQuestionUid,
            )
            if (!parentQuestion || parentQuestion.type !== 'range') return
            const subIndex = parentQuestion.subQuestions.findIndex(
              (sub) => sub.question.uid === subQuestionUid,
            )
            if (subIndex === -1) return

            // Directly update the condition on the sub-question
            parentQuestion.subQuestions[subIndex].condition = newCondition

            state.validationErrors = calculateValidationErrors(
              state.questionnaire,
            )
          }),
        )
      },
    }
  })
