import {
  BaseTracker,
  TrackerListItem,
  useAllTrackerFolders,
  useAllTrackers,
} from '@capturi/api-trackers'
import { Speaker } from '@capturi/core'
import { useOrganization } from '@capturi/stores'
import { Box, Divider, Flex, Spinner, Text } from '@chakra-ui/react'
import { Trans, select, t } from '@lingui/macro'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { useSegmentStatesContext } from '../../state/segment-state'

import { BaseTrackerState } from '@capturi/api-filters'
import { FilterSelect } from '../../components/FilterSelect/components/FilterSelect'
type Props = {
  value: BaseTrackerState
  initialFocusRef?: React.RefObject<HTMLInputElement>
  setValue: (values: BaseTrackerState) => void
  resetValue: () => void
  onClose: () => void
}
const localizeSpeaker = (orgType: string, speaker: Speaker): string => {
  const msgs: Record<Speaker, string> = {
    [Speaker.All]: t`All`,
    [Speaker.Employee]: t`Employee`,
    [Speaker.Customer]: select(orgType, {
      public: 'Citizen',
      other: 'Customer',
    }),
  }
  return msgs[speaker ?? Speaker.All]
}

const localizeTrackerSpeaker = (
  trackerListItem: TrackerListItem<true> | undefined,
  orgType: string,
): string | undefined => {
  if (trackerListItem === undefined) return ''
  if ('speech' in trackerListItem && trackerListItem.speech) {
    return localizeSpeaker(orgType, trackerListItem.speech.speakerId)
  }
  return undefined
}

export const TrackerSelect: FC<Props> = ({
  value,
  setValue,
  resetValue,
  onClose,
  initialFocusRef,
}) => {
  const { organizationType } = useOrganization()
  const { data: trackers, isLoading } = useAllTrackers()
  const { data: folders } = useAllTrackerFolders()
  const [searchQuery, setSearchQuery] = useState('')
  const { states } = useSegmentStatesContext()
  const channel = states.length > 0 ? states[0].channel : 'phone'

  const trackerOptions = useMemo(() => {
    const trackerFolderMap = new Map(
      folders?.map((folder) => [folder.uid, folder.title]),
    )

    return (
      trackers
        ?.filter((t): t is BaseTracker => t.accessLevel !== 'None')
        .map((t) => {
          const folderName =
            'folderUid' in t && t.folderUid
              ? trackerFolderMap.get(t.folderUid)
              : undefined

          return {
            uid: t.uid,
            name: t.name,
            folderName,
            accessLevel: t.accessLevel,
            speaker: localizeTrackerSpeaker(t, organizationType),
          }
        }) ?? []
    )
  }, [folders, organizationType, trackers])
  const selectedUids = value?.uids ?? []

  const filteredTrackers = useMemo(() => {
    if (!searchQuery) return trackerOptions

    const term = searchQuery.toLowerCase().trim()
    return trackerOptions.filter(
      (tracker) =>
        tracker.name.toLowerCase().includes(term) ||
        tracker.folderName?.toLowerCase().includes(term) ||
        tracker.speaker?.toLowerCase().includes(term),
    )
  }, [trackerOptions, searchQuery])

  const handleSelect = useCallback(
    (trackerUid: string) => {
      const newSelectedUids = selectedUids.includes(trackerUid)
        ? selectedUids.filter((uid) => uid !== trackerUid)
        : [...selectedUids, trackerUid]

      setValue({ uids: newSelectedUids })
    },
    [selectedUids, setValue],
  )

  const handleSelectAll = useCallback(() => {
    const newSelections = filteredTrackers.map((tracker) => tracker.uid)
    setValue({ uids: [...new Set([...selectedUids, ...newSelections])] })
    setSearchQuery('')
  }, [filteredTrackers, selectedUids, setValue])

  const renderTrackerOption = useCallback(
    (tracker: (typeof trackerOptions)[0], isSelected: boolean) => (
      <FilterSelect.OptionItem
        key={tracker.uid}
        isSelected={isSelected}
        onClick={() => handleSelect(tracker.uid)}
      >
        <Box>
          <Text color={tracker.accessLevel === 'None' ? 'warning' : 'inherit'}>
            {tracker.name}
          </Text>
          <Flex flexDirection="column">
            {tracker.folderName && (
              <Text fontSize="xs" color="textMuted">
                <Trans>Folder</Trans>: {tracker.folderName}
              </Text>
            )}
            {tracker.speaker && (
              <Text fontSize="xs" color="textMuted">
                <Trans>Speaker</Trans>: {tracker.speaker}
              </Text>
            )}
          </Flex>
        </Box>
      </FilterSelect.OptionItem>
    ),
    [handleSelect],
  )

  if (isLoading) {
    return <Spinner />
  }

  // Create a map of selected trackers for easy lookup
  const trackerMap = new Map(
    trackerOptions.map((tracker) => [tracker.uid, tracker]),
  )

  return (
    <Box maxH="400px" display="flex" flexDirection="column">
      <Box px={2}>
        <FilterSelect.Search
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          ref={initialFocusRef}
        />
      </Box>
      <Divider />
      <Box flex={1} maxH="350px" overflowY="auto">
        <FilterSelect.FilterSelectOptions
          selected={selectedUids.length}
          total={filteredTrackers.length}
          selectedItems={selectedUids.map((uid) =>
            renderTrackerOption(
              trackerMap.get(uid) ?? {
                uid,
                name: t`Unknown tracker`,
                accessLevel: 'None',
                folderName: undefined,
                speaker: undefined,
              },
              true,
            ),
          )}
          unselectedItems={filteredTrackers
            .filter((tracker) => !selectedUids.includes(tracker.uid))
            .map((tracker) => renderTrackerOption(tracker, false))}
        />
      </Box>
      {channel === 'phone' && (
        <Box flexShrink={0}>
          <FilterSelect.Footer
            selectedCount={selectedUids.length}
            onSelectAll={handleSelectAll}
            onReset={resetValue}
            onApply={onClose}
          />
        </Box>
      )}
    </Box>
  )
}

export const NotTrackerSelect = TrackerSelect
