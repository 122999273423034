import {
  Editable,
  EditableInput,
  EditablePreview,
  FormControl,
  HStack,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { FC, memo, useCallback } from 'react'
import isEqual from 'react-fast-compare'

import { EditableControls } from 'pages/ScoringPage/editor/components/EditableControls'
import { useEditorStore } from '../EditorStoreProvider'
import { EditorStoreState } from '../useQuestionnaireEditorStore'

const selectTitle = (state: EditorStoreState) => ({
  title: state.questionnaire.title,
  setTitle: state.setTitle,
  missingTitle: state.validationErrors.missingTitle,
  autoFocusOnQuestionnaireTitle: state.autoFocusOnQuestionnaireTitle,
})

export const QuestionnaireTitle: FC = memo(() => {
  const { title, setTitle, missingTitle, autoFocusOnQuestionnaireTitle } =
    useEditorStore(selectTitle, (a, b) => isEqual(a, b))

  const handleSubmit = useCallback(
    (newTitle: string) => {
      setTitle(newTitle.trim())
    },
    [setTitle],
  )

  return (
    <HStack gap={4}>
      <FormControl isInvalid={missingTitle}>
        <Editable
          fontSize="2xl"
          display="flex"
          alignItems="center"
          placeholder={t`Template name...`}
          value={title}
          onChange={setTitle}
          onSubmit={handleSubmit}
          startWithEditView={autoFocusOnQuestionnaireTitle}
          autoFocus={autoFocusOnQuestionnaireTitle}
        >
          <EditablePreview
            color={!title ? 'danger' : 'inherit'}
            opacity={!title ? 0.2 : 1}
            whiteSpace="nowrap"
            overflow="hidden"
            maxW="500px"
          />
          <EditableInput pl={2} size={40} />
          <EditableControls ml={4} />
        </Editable>
      </FormControl>
    </HStack>
  )
})
