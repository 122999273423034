import request, {
  ResponseError,
  apiRequest,
  downloadHeaderExtensionBlob,
} from '@capturi/request'
import {
  InfiniteData,
  UseInfiniteQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import { ArchivedConversation, ArchivedConversationFilter } from './types'

export type archivedConversationListResponseModel = {
  conversations: ArchivedConversation[]
  continuationToken: string
}

const FILTER_STALE_TIME = 60 * 60 * 1000 // 1 hour. Can be changed, I'm not sure how often some of the filter values are potentially updated

export const SelectArchivedConversations = (
  data: InfiniteData<archivedConversationListResponseModel>,
): InfiniteData<ArchivedConversation[]> => ({
  pages: data.pages.map((page) => {
    return page.conversations
  }),
  pageParams: [],
})

export const getNextPageParam = (lastPage: {
  continuationToken: string | null
}) => lastPage.continuationToken

export const useArchivedConversationList = (
  filter: ArchivedConversationFilter,
  period: { from: Date; to: Date },
): UseInfiniteQueryResult<
  InfiniteData<ArchivedConversation[], unknown>,
  ResponseError
> =>
  useInfiniteQuery({
    queryKey: ['archivedConversations', 'list', filter, period.from, period.to],
    queryFn: async ({ pageParam }) =>
      await request.post<archivedConversationListResponseModel>(
        'archive/v1/conversations/list',
        {
          json: pageParam
            ? { continuationToken: pageParam }
            : {
                ...filter,
                fromInclusive: period.from,
                toInclusive: period.to,
                limit: 100,
                sortDirection: 1,
              },
        },
      ),
    getNextPageParam,
    initialPageParam: null,
    select: SelectArchivedConversations,
  })

export const createFilterQuery = (filterName: string) => ({
  queryKey: ['archivedConversations', 'filters', filterName] as const,
  queryFn: () =>
    request.get<{ values: string[] }>(
      `archive/v1/conversations/filter-values/${filterName}`,
    ),
  staleTime: FILTER_STALE_TIME,
})

export const useFilterData = (filterName: string) =>
  useQuery(createFilterQuery(filterName))

export const useConversationCount = (
  filter: ArchivedConversationFilter,
  period: { from: Date; to: Date },
) => {
  return useQuery({
    queryKey: [
      'archivedConversations',
      'count',
      filter,
      period.from,
      period.to,
    ],
    queryFn: async () => {
      const response = await request.post<{ count: number }>(
        'archive/v1/conversations/count',
        {
          json: {
            ...filter,
            fromInclusive: period.from,
            toInclusive: period.to,
          },
        },
      )
      return response
    },
    staleTime: FILTER_STALE_TIME,
  })
}

export const useDownloadArchivedConversation = () => {
  return useMutation({
    mutationFn: async (conversationUid: string) => {
      await downloadHeaderExtensionBlob(
        `storage/archive/${conversationUid}/download`,
      )
    },
  })
}

export const usePreviewText = (
  conversationUid: string | undefined,
  type: string | undefined,
) => {
  return useQuery({
    queryKey: ['archivedConversations', 'preview', conversationUid],
    queryFn: async () => {
      const response = await apiRequest(
        `storage/archive/${conversationUid}/preview`,
      )
      return response.text()
    },
    enabled: !!conversationUid && type === 'Text',
  })
}

export const useArchivedConversationDeletionPolicy = () => {
  return useQuery({
    queryKey: ['archivedConversations', 'deletionPolicy'],
    queryFn: async () => {
      const response = await request.get<{
        organizationUid: string
        timeToLive: string
      }>('archive/v1/conversations/deletion-policy')
      return response
    },
  })
}

const getArchivedConversation = async (uid: string | undefined) => {
  return await request.get<ArchivedConversation>(
    `archive/v1/conversations/${uid}`,
  )
}

export const useConversation = (uid: string | undefined) => {
  return useQuery({
    queryKey: ['archivedConversation', uid],
    queryFn: () => getArchivedConversation(uid),
    enabled: !!uid,
    staleTime: 5 * 60 * 1000, //5 minutes. just for performance. could easily be longer though
  })
}
