import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Select,
  Text,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FC } from 'react'

import {
  ConditionForYesNo,
  Question,
  YesNoQuestion,
} from 'features/questionnaire'
import { useEditorStore } from '../../EditorStoreProvider'
import { EditorStoreState } from '../../useQuestionnaireEditorStore'

interface YesNoConditionEditorProps {
  sectionUid: string
  parentQuestion: YesNoQuestion
  selectedQuestionUid: string
  currentGroup?: { condition: ConditionForYesNo; question: Question }
}

const defaultYesNoCondition: ConditionForYesNo = {
  type: 'yesNoAnswer',
  answer: 'Yes',
}

export const YesNoConditionEditor: FC<YesNoConditionEditorProps> = ({
  sectionUid,
  parentQuestion,
  selectedQuestionUid,
  currentGroup,
}) => {
  // currentCondition is taken from props and may change when a new question is selected
  const currentCondition: ConditionForYesNo =
    currentGroup?.condition || defaultYesNoCondition

  // Calculate the current select value directly from currentCondition
  const selectValue =
    currentCondition.type === 'yesNoAnswer'
      ? currentCondition.answer
      : 'notRelevantYesNo'

  const updateCondition = useEditorStore(
    (state: EditorStoreState) => state.updateSubQuestionYesNoCondition,
  )

  const handleChange = (newVal: string) => {
    const newCondition: ConditionForYesNo =
      newVal === 'notRelevantYesNo'
        ? { type: 'notRelevantYesNo' }
        : { type: 'yesNoAnswer', answer: newVal as 'Yes' | 'No' }
    updateCondition(
      sectionUid,
      parentQuestion.uid,
      selectedQuestionUid,
      newCondition,
    )
  }

  return (
    <Box p={2} borderWidth={1} borderRadius="md" bg="gray.50">
      <FormControl>
        <FormLabel mb={0}>
          <Trans>SHOW IF</Trans>
        </FormLabel>
        <HStack>
          <Text fontSize="xs">
            <Trans>Above answer is</Trans>
          </Text>
          <Select
            value={selectValue}
            onChange={(e) => handleChange(e.currentTarget.value)}
            size="sm"
            w={20}
          >
            <option value="Yes">
              <Trans>Yes</Trans>
            </option>
            <option value="No">
              <Trans>No</Trans>
            </option>
            {parentQuestion.notRelevantOption && (
              <option value="notRelevantYesNo">
                <Trans>N/A</Trans>
              </option>
            )}
          </Select>
        </HStack>
      </FormControl>
    </Box>
  )
}
