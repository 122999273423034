import { useUsers } from '@capturi/stores'
import { Caption, PageHeading } from '@capturi/ui-components'
import { useToast } from '@capturi/ui-components'
import { useConfirm } from '@capturi/use-confirm'
import { useModal } from '@capturi/use-modal'
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Stack,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { FC, memo, useCallback } from 'react'
import { MdAdd, MdCopyAll, MdDelete, MdEdit } from 'react-icons/md'
import { useNavigate } from 'react-router'

import {
  LazyQuestionnairePreviewModal,
  QaQuestionnaireListItem,
  useAllQuestionnaires,
  useCloneQuestionnaire,
  useDeleteQuestionnaire,
} from '../../features/questionnaire'
import { routes } from './routes'

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
}

const QuestionnaireListItem: FC<{
  questionnaire: QaQuestionnaireListItem
  onEdit?: (uid: string) => void
  onDelete: (uid: string) => void
  onClone: (uid: string) => void
  isDeleting?: boolean
}> = memo(({ questionnaire, onEdit, onDelete, onClone, isDeleting }) => {
  const [openQuestionnaireModal] = useModal(LazyQuestionnairePreviewModal)
  const handlePreview = useCallback(() => {
    openQuestionnaireModal({
      questionnaireUid: questionnaire.uid,
      showSubmitButton: false,
      isDisabled: false,
    })
  }, [openQuestionnaireModal, questionnaire.uid])

  const { getUserByUid } = useUsers()
  const publishedByName = getUserByUid(questionnaire.publishedByUserUid).name

  return (
    <Box
      borderWidth={1}
      borderRadius="md"
      cursor="pointer"
      onClick={handlePreview}
      _hover={{ bg: 'gray.100' }}
    >
      <Flex p={4} alignItems="center" justifyContent="space-between">
        <Box flex={1}>
          <VStack align="flex-start" gap={2}>
            <Text fontSize="lg" fontWeight="medium">
              {questionnaire.title.length > 0 ? (
                questionnaire.title
              ) : (
                <Trans>Questionnaire title</Trans>
              )}
            </Text>

            {questionnaire.publishedAt && (
              <Text fontSize="xs" color="gray.600">
                <Trans>
                  Published by {publishedByName} at{' '}
                  {i18n.date(questionnaire.publishedAt, dateOptions)}
                </Trans>
              </Text>
            )}
          </VStack>
        </Box>

        <Flex gap={4}>
          <HStack gap={1}>
            <Tooltip label={t`Delete`} hasArrow placement="top">
              <IconButton
                aria-label={t`Delete template`}
                icon={<Icon as={MdDelete} />}
                variant="ghost"
                size="xs"
                isDisabled={isDeleting}
                onClick={(e) => {
                  e.stopPropagation()
                  onDelete(questionnaire.uid)
                }}
              />
            </Tooltip>
            <Tooltip label={t`Duplicate`} hasArrow placement="top">
              <IconButton
                aria-label={t`Clone questionnaire`}
                icon={<Icon as={MdCopyAll} />}
                variant="ghost"
                size="xs"
                isDisabled={isDeleting}
                onClick={(e) => {
                  e.stopPropagation()
                  onClone(questionnaire.uid)
                }}
              />
            </Tooltip>
            {onEdit && (
              <Tooltip label={t`Edit`} hasArrow placement="top">
                <IconButton
                  aria-label={t`Edit questionnaire`}
                  icon={<Icon as={MdEdit} />}
                  variant="ghost"
                  size="xs"
                  isDisabled={isDeleting}
                  onClick={(e) => {
                    e.stopPropagation()
                    onEdit?.(questionnaire.uid)
                  }}
                />
              </Tooltip>
            )}
          </HStack>
        </Flex>
      </Flex>
    </Box>
  )
})

export const QAQuestionnaireListPage: FC = () => {
  const navigate = useNavigate()

  const handleCreateNew = () => {
    navigate(routes.create)
  }

  const { questionnaires, isLoading } = useAllQuestionnaires()

  const handleEdit = useCallback(
    (uid: string) => {
      navigate(routes.edit(uid))
    },
    [navigate],
  )

  const { mutate: deleteQuestionnaire, isPending: isDeleting } =
    useDeleteQuestionnaire()
  const { mutate: cloneQuestionnaire } = useCloneQuestionnaire()

  const confirm = useConfirm()
  const toast = useToast()
  const handleDelete = useCallback(
    async (uid: string) => {
      try {
        await confirm({
          title: t`Delete template?`,
          description: t`It will not be possible to use the template for new reviews. Already performed reviews will be kept on the conversation.`,
          confirmText: t`Delete`,
          colorScheme: 'red',
        })

        deleteQuestionnaire(uid, {
          onSuccess: () => {
            toast({
              title: t`Template deleted successfully`,
              status: 'success',
              duration: 4000,
            })
          },
          onError: (error) => {
            toast({
              title: t`Failed to delete template`,
              description: error.message,
              status: 'error',
              duration: 4000,
              isClosable: true,
            })
          },
        })
      } catch {
        // User cancelled the confirmation, do nothing
      }
    },
    [deleteQuestionnaire, confirm, toast],
  )

  const handleClone = useCallback(
    (uid: string) => {
      cloneQuestionnaire(uid, {
        onSuccess: (cloned) => {
          toast({
            title: t`Template cloned successfully`,
            status: 'success',
            duration: 4000,
          })
          navigate(routes.edit(cloned.uid))
        },
        onError: (error) => {
          toast({
            title: t`Failed to clone template`,
            description: error.message,
            status: 'error',
            duration: 4000,
            isClosable: true,
          })
        },
      })
    },
    [navigate, cloneQuestionnaire, toast],
  )

  const { activeQuestionnaires = [], draftQuestionnaires = [] } =
    Object.groupBy(questionnaires ?? [], ({ published }) =>
      published ? 'activeQuestionnaires' : 'draftQuestionnaires',
    )

  return (
    <Box p={4}>
      <Flex justifyContent="space-between" alignItems="flex-start" mb={4}>
        <VStack align="flex-start" gap={0}>
          <PageHeading h={8} lineHeight="shorter">
            <Trans>QA templates</Trans>
          </PageHeading>
          <Caption color="textMuted">
            <Trans>
              Manage templates for manually quality assuring conversations
            </Trans>
          </Caption>
        </VStack>
        <Button
          colorScheme="primary"
          leftIcon={<Icon as={MdAdd} />}
          onClick={handleCreateNew}
        >
          <Trans>Create template</Trans>
        </Button>
      </Flex>

      {!isLoading && questionnaires && questionnaires.length > 0 ? (
        <>
          <Text fontSize="lg" fontWeight="medium" mb={2}>
            <Trans>Active</Trans>
          </Text>
          <VStack align="stretch" spacing={2}>
            {activeQuestionnaires.map((q) => (
              <QuestionnaireListItem
                key={q.uid}
                questionnaire={q}
                onDelete={handleDelete}
                onClone={handleClone}
                isDeleting={isDeleting}
              />
            ))}
          </VStack>
          {draftQuestionnaires.length > 0 && (
            <>
              <Box py={8}>
                <Divider />
              </Box>

              <Text fontSize="lg" fontWeight="medium" mb={2}>
                <Trans>Drafts</Trans>
              </Text>
              <VStack align="stretch" spacing={2}>
                {draftQuestionnaires.map((q) => (
                  <QuestionnaireListItem
                    key={q.uid}
                    questionnaire={q}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onClone={handleClone}
                    isDeleting={isDeleting}
                  />
                ))}
              </VStack>
            </>
          )}
        </>
      ) : (
        !isLoading && (
          <Stack spacing={4}>
            <Text>
              <Trans>
                No questionnaires available. Please create a new one.
              </Trans>
            </Text>
          </Stack>
        )
      )}
    </Box>
  )
}
