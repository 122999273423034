import { QuestionAnswer } from '@capturi/api-conversations'
import { ConditionForRange, ConditionForYesNo } from '../types'

export const isYesNoConditionSatisfied = (
  condition: ConditionForYesNo,
  answer?: QuestionAnswer,
): boolean => {
  if (!answer) return false

  if (condition.type === 'notRelevantYesNo' && answer.type === 'notRelevant') {
    return true
  }

  if (
    condition.type === 'yesNoAnswer' &&
    answer.type === 'yesNo' &&
    answer.answer === condition.answer
  ) {
    return true
  }

  return false
}

export const isRangeConditionSatisfied = (
  condition: ConditionForRange,
  answer?: QuestionAnswer,
): boolean => {
  if (!answer) return false

  if (condition.type === 'notRelevantRange' && answer.type === 'notRelevant') {
    return true
  }

  if (
    condition.type === 'rangeAnswer' &&
    answer.type === 'range' &&
    answer.answer >= condition.answer.min &&
    answer.answer <= condition.answer.max
  ) {
    return true
  }

  return false
}
