import { Box, Flex, Radio, RadioGroup, Text } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { FC, ReactNode, useMemo } from 'react'

import { RangeAnswer } from '@capturi/api-conversations'
import { Question, RangeQuestion } from '../../types'
import { isRangeConditionSatisfied } from '../../utils/conditions'

type RangePreviewProps = {
  question: RangeQuestion
  answer: RangeAnswer | { type: 'notRelevant' } | undefined
  onChange: (newAnswer: RangeAnswer | { type: 'notRelevant' }) => void
  renderSubquestion: (subQuestion: Question) => ReactNode
  isDisabled?: boolean
}

export const RangePreview: FC<RangePreviewProps> = ({
  question,
  answer,
  onChange,
  renderSubquestion,
  isDisabled = false,
}) => {
  const { min, max } = question.scale

  const questionsToShow = useMemo(
    () =>
      question.subQuestions.filter((group) =>
        isRangeConditionSatisfied(group.condition, answer),
      ),
    [question.subQuestions, answer],
  )

  const handleRangeClick = (val: number) => {
    if (!isDisabled) {
      onChange({ type: 'range', answer: val })
    }
  }

  const handleNotRelevantChange = (val: string) => {
    if (!isDisabled && val === 'notRelevant') {
      onChange({ type: 'notRelevant' })
    }
  }

  // Determine if a number value is selected
  const isNumberSelected = (val: number) =>
    answer?.type === 'range' && answer.answer === val

  return (
    <Box>
      <Text fontSize="sm">{question.title || t`Add your question`}</Text>
      {question.description && (
        <Text fontSize="sm" color="gray.600" mb={2} whiteSpace="pre-wrap">
          {question.description}
        </Text>
      )}
      {/* Render each possible number from min..max as a clickable button/label */}
      <Flex gap={2} alignItems="center" wrap="wrap" mb={1}>
        {question.labels?.min && (
          <Text fontSize="xs" color="gray.500" mr={2}>
            {question.labels.min}
          </Text>
        )}
        {Array.from({ length: max - min + 1 }, (_, i) => {
          const val = min + i
          const selected = isNumberSelected(val)
          return (
            <Box
              key={val}
              as="button"
              px={1}
              py={0.5}
              borderRadius="4px"
              minW="22px"
              boxShadow="base"
              textAlign="center"
              fontWeight="medium"
              borderWidth={1}
              borderColor={selected ? 'primary.500' : 'border.light'}
              bg={selected ? 'primary.50' : 'white'}
              onClick={() => handleRangeClick(val)}
              disabled={isDisabled}
              cursor={isDisabled ? 'not-allowed' : 'pointer'}
            >
              {val}
            </Box>
          )
        })}
        {question.labels?.max && (
          <Text fontSize="xs" color="gray.500" ml={2}>
            {question.labels.max}
          </Text>
        )}
      </Flex>
      {/* Possibly show "Not relevant" if allowed */}
      {question.notRelevantOption && (
        <RadioGroup
          value={answer?.type === 'notRelevant' ? 'notRelevant' : ''}
          onChange={handleNotRelevantChange}
          isDisabled={isDisabled}
          cursor={isDisabled ? 'not-allowed' : 'pointer'}
        >
          <Radio value="notRelevant" size="sm">
            <Trans>Not relevant</Trans>
          </Radio>
        </RadioGroup>
      )}

      {questionsToShow.map((group) => (
        <Box key={group.question.uid} mt={3} pl={5} borderLeft="1px solid #ddd">
          {renderSubquestion(group.question)}
        </Box>
      ))}
    </Box>
  )
}
