import { Box, Button, Flex } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FC } from 'react'
import { ArchivedConversation } from '../api/types'
import { ConversationListItem } from './ConversationListItem'

type Props = {
  conversations: ArchivedConversation[] | undefined
  selectedConversationUid: string | null
  onConversationSelect: (uid: string) => void
  canLoadMore: boolean
  isFetchingNextPage: boolean
  fetchNextPage: () => void
}

export const ConversationList: FC<Props> = ({
  conversations,
  selectedConversationUid,
  onConversationSelect,
  canLoadMore,
  isFetchingNextPage,
  fetchNextPage,
}) => {
  return (
    <Box
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      overflow="hidden"
    >
      <Box height="100%" overflowY="auto" pr="2">
        <Box border="1px solid" borderColor="gray.200">
          {conversations?.map((conversation) => (
            <ConversationListItem
              key={conversation.uid}
              conversation={conversation}
              isSelected={conversation.uid === selectedConversationUid}
              onClick={onConversationSelect}
            />
          ))}
        </Box>
        <Flex justifyContent="center" my={4}>
          <Button
            onClick={() => fetchNextPage()}
            isDisabled={!canLoadMore || isFetchingNextPage}
            isLoading={isFetchingNextPage}
          >
            {!canLoadMore ? (
              <Trans>No more conversations</Trans>
            ) : (
              <Trans>Load more</Trans>
            )}
          </Button>
        </Flex>
      </Box>
    </Box>
  )
}
