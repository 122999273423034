import { useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import { PageHeading, Spinner } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import SubscriptionModal from 'pages/HomePage/Subscription/SubscriptionModal'
import { Period } from 'pages/shared/Subscriptions/types'
import {
  useRequestEmailManually,
  useSubscriptions,
} from 'pages/shared/Subscriptions/useSubscriptions'
import { FC, useCallback } from 'react'
import { match } from 'ts-pattern'
import SubscriptionCard from './components/SubscriptionCard'

const translateLabels = (periodObj: Period): string => {
  return match(periodObj)
    .with({ type: 'Month', month: 'Current' }, () => t`Current month`)
    .with({ type: 'Month', month: 'Previous' }, () => t`Previous month`)
    .with({ type: 'Week', week: 'Current' }, () => t`Current week`)
    .with({ type: 'Week', week: 'Previous' }, () => t`Previous week`)
    .with({ type: 'Yesterday' }, () => t`Yesterday`)
    .with({ type: 'Days' }, () => t`Previous day(s)`)
    .otherwise(() => t`Previous week`)
}

const SubscriptionsPage: FC = () => {
  const { isArchiveOrg, aiFeatures } = useFeatureFlags()

  const [openSubscriptionModal] = useModal(SubscriptionModal)
  const { isOwner } = useCurrentUser()
  const { data, isPending } = useSubscriptions()
  const { mutate: requestEmailManually } = useRequestEmailManually()
  const toast = useToast()
  const handleRequestEmailManually = useCallback(
    (subscriptionUid: string) => {
      requestEmailManually(subscriptionUid, {
        onSuccess: () => {
          toast({
            title: t`Email requested successfully`,
            status: 'success',
          })
        },
        onError: (error) => {
          toast({
            title: t`Failed to request email. Subscription is already processing`,
            status: 'error',
            description:
              error.statusCode === 409
                ? t`An error occurred while requesting the email. The email request is already processing. Please wait and try again later.`
                : error.message,
          })
        },
      })
    },
    [requestEmailManually, toast],
  )

  if (isArchiveOrg || !aiFeatures) return null

  if (isPending) {
    return <Spinner />
  }

  return (
    <Flex flexDir="column" mb={4}>
      <HStack justify="space-between">
        <VStack align="flex-start" gap={0}>
          <PageHeading>
            <Trans>Subscriptions</Trans>
          </PageHeading>
          <Text color="gray.600">
            <Trans>Manage your periodic updates</Trans>
          </Text>
        </VStack>
        <Button onClick={() => openSubscriptionModal()}>
          <Trans>New subscription</Trans>
        </Button>
      </HStack>
      <Divider my={4} />
      <VStack flex={1} align="stretch">
        <Heading fontWeight="medium">
          <Trans>Subscriptions created by me</Trans>
        </Heading>
        {data?.own.map((subscription) => {
          return (
            <SubscriptionCard
              key={subscription.uid}
              subscription={subscription}
              label={translateLabels(subscription.period)}
              onRequestEmailManually={() =>
                handleRequestEmailManually(subscription.uid)
              }
            />
          )
        })}
      </VStack>
      {isOwner ? (
        <>
          <Divider my={8} />
          <VStack flex={1} align="stretch">
            <Heading fontWeight="medium">
              <Trans>Other subscriptions</Trans>
            </Heading>
            {data?.others?.map((subscription) => {
              return (
                <SubscriptionCard
                  key={subscription.uid}
                  subscription={subscription}
                  label={translateLabels(subscription.period)}
                  onRequestEmailManually={() =>
                    handleRequestEmailManually(subscription.uid)
                  }
                />
              )
            })}
          </VStack>
        </>
      ) : (
        <Box
          fontSize="xs"
          mt={4}
          textAlign="center"
          fontStyle="italic"
          color="gray.600"
        >
          <Text>
            <Trans>
              The list consists only of subscriptions created by you.
            </Trans>
          </Text>
          <Text>
            <Trans>
              You may receive reports from other subscriptions. If you wish to
              unsubscribe click the unsubscribe link in the email.
            </Trans>
          </Text>
        </Box>
      )}
    </Flex>
  )
}

export default SubscriptionsPage
