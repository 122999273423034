import { t } from '@lingui/macro'
import React from 'react'

import { MultiValueFilterItem } from '../useTextFilter'
import { BaseMultiTextFilter } from './BaseMultiTextFilter'
import { TextFilterPopoverComponentProps } from './types'

const EMAIL_SPLIT_CHARACTERS = new Set(['Enter', ',', 'Tab'])
const SPLIT_REGEX = /,|\t|\r|\n/

export const EmailAddressesFilterComponent: React.FC<
  TextFilterPopoverComponentProps<MultiValueFilterItem>
> = (props) => {
  return (
    <BaseMultiTextFilter
      {...props}
      entityName={''}
      placeholder={t`E-mail, phone number, etc.`}
      hint={t`You can also paste in a list`}
      pasteSplitRegex={SPLIT_REGEX}
      splitCharactersSet={EMAIL_SPLIT_CHARACTERS}
      onChangeValue={(item) => {
        props.onChangeValue({
          ...item,
          filterType: 'emailsFilters',
        })
      }}
    />
  )
}
