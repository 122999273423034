import { useUpdateIsReviewed } from '@capturi/api-conversations'
import { useToast } from '@capturi/ui-components'
import { useConfirmCallback } from '@capturi/use-confirm'
import { Box, Collapse, IconButton } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { useStore } from '@tanstack/react-store'
import { FC, useCallback, useEffect, useRef } from 'react'
import { MdClose } from 'react-icons/md'

import {
  LazyQuestionnairePreview,
  QuestionnaireAnswersInput,
  useCreateAnswer,
} from 'features/questionnaire'
import { QuestionnaireBlocker } from './QuestionnaireBlocker'
import {
  closeQuestionnairePreview,
  questionnaireStore,
} from './questionnaireStore'

type InlineQuestionnairePreviewProps = {
  conversationUid: string
}

export const InlineQuestionnairePreview: FC<
  InlineQuestionnairePreviewProps
> = ({ conversationUid }) => {
  const { selectedQuestionnaireUid, isInlinePreviewOpen } =
    useStore(questionnaireStore)

  const showConfirm = useConfirmCallback()
  const toast = useToast()

  const { mutate: updateIsReviewed } = useUpdateIsReviewed(conversationUid)
  const { mutate: createAnswer } = useCreateAnswer({
    conversationUid,
  })

  const handleSubmit = useCallback(
    (answers: QuestionnaireAnswersInput) => {
      if (!selectedQuestionnaireUid) return

      createAnswer(
        {
          questionnaireUid: selectedQuestionnaireUid,
          answers,
        },
        {
          onSuccess: () => {
            updateIsReviewed({ isReviewed: true })

            toast({
              title: t`Your questionnaire has been submitted`,
              status: 'success',
            })
          },
        },
      )

      closeQuestionnairePreview()
    },
    [selectedQuestionnaireUid, createAnswer, updateIsReviewed, toast],
  )

  const prevConversationUidRef = useRef(conversationUid)
  useEffect(() => {
    if (prevConversationUidRef.current !== conversationUid) {
      closeQuestionnairePreview()
    }
    prevConversationUidRef.current = conversationUid
  }, [conversationUid])

  if (!selectedQuestionnaireUid) {
    return null
  }

  return (
    <Collapse in={isInlinePreviewOpen} animateOpacity>
      <QuestionnaireBlocker />
      <Box
        py={2}
        px={4}
        mb={4}
        border="1px solid"
        borderColor="gray.200"
        borderRadius="md"
        bgColor="gray.50"
        maxH="40rem"
        overflowY="scroll"
        position="relative"
      >
        <IconButton
          aria-label={t`Close questionnaire`}
          icon={<MdClose />}
          size="2xs"
          variant="ghost"
          onClick={() => {
            showConfirm({
              options: {
                title: t`Discard questionnaire?`,
                description: t`Your data will be lost if you close this questionnaire without submitting.`,
                confirmText: t`Discard`,
                cancelText: t({
                  message: 'Cancel',
                  id: 'Cancel (questionnaire answer form)',
                }),
              },
              onConfirm: closeQuestionnairePreview,
            })
          }}
          position="absolute"
          top={3}
          right={4}
          zIndex={1}
        />
        <LazyQuestionnairePreview
          questionnaireUid={selectedQuestionnaireUid}
          showSubmitButton={true}
          onSubmit={handleSubmit}
        />
      </Box>
    </Collapse>
  )
}
